import moment from 'moment';
import {
  getCategoryParam, toTitleCase,
} from '../../../utils/formatter';
import { isUndefinedOrNull, prefixString } from '../../../utils/stringUtils';
import {
  translateConnectionStatusBase, translateCurrentWorkModeBase, translateDeviceModel,
} from './sharedCalculateCellValue';
import {
  insertSettingsToString, isUndefined, parseSettingsFromString,
} from './utils';

/* const resetSeconds = (date) => {
  date.setSeconds(30, 0);
  return date;
}; */

// const getShortDateTime = (item) => resetSeconds(new Date(item.lastUpdate));

const getDateFormat = (value) => {
  if (isUndefinedOrNull(value)) return value;
  return moment(value).format('DD.MM.YYYY, HH:mm:ss');
};

const getShortDateTime = (item) => getDateFormat(item.lastUpdate);

const getDateTime = (value) => getDateFormat(value);

const getGroupName = (item, groups) => groups[item.groupId]?.name;

const getCategoryName = (item, t) => {
  const defaultName = t('deviceCategoryDevice');

  if (!item.category) {
    return defaultName;
  }

  const name = t(`device${toTitleCase(item.category)}`);

  if (!name) {
    return defaultName;
  }

  return name;
};

const getSourceNameFromItem = (item, sources, objects) => {
  const foundSourceId = getCategoryParam(item, 'sourceId');
  if (!foundSourceId) return '';
  const foundSource = sources[foundSourceId];
  return foundSource ? (objects[foundSource]?.name ?? '') : foundSourceId;
};

/**
 * Получает имя источника обнаружения из devicesObject по deviceId, затем
 * в sources идет поиск по sourceId полученный из devicesObject, затем
 * в devices ищется по полученному id для получения имени.
 * @param {*} item Объект
 * @param {*} sources Источники
 * @param {*} devicesObject Позиции объектов
 * @param {*} devices Список устройств, где брать имя устройства
 * @returns Имя источника обнаружения объекта
 */
const getSourceNameFromDevicesObject = (item, sources, devicesObject, devices) => {
  const foundSourceId = item.deviceId;
  if (!foundSourceId) return '';
  const foundSource = devicesObject[foundSourceId];
  if (!foundSource) return '';
  return devices[sources[foundSource?.sourceId]]?.name ?? '';
};

const getSourceName = (item, sources, devices) => {
  const sourceId = item.dattributes.categoryParams?.sourceId;
  if (!sourceId) return '';
  return devices[sources[sourceId]]?.name ?? '';
};

const getProperty = (item, positions, name) => positions[item.id]?.attributes?.categoryParams?.[name] ?? '';

const getSpeed = (item, positions) => positions[item.id]?.speed ?? '';

const getCourseFromPositions = (item, positions) => positions[item.id]?.course ?? '';

const toFixedNumber = (value, toNumber = 5) => value?.toFixed(toNumber) ?? '';

const tryConvertAndFixedNumber = (value, toNumber = 2) => {
  if (isUndefinedOrNull(value) || value.length === 0) {
    return '';
  }

  if (typeof value === 'number') {
    return toFixedNumber(value, toNumber);
  }
  return toFixedNumber(Number(value), toNumber);
};

const toFixedWithConvertToNumber = (value) => {
  if (value) {
    return toFixedNumber(Number(value));
  }
  return '';
};

const getLatitudeAndLongitude = (item, positions) => {
  const foundPosition = positions[item.id];

  if (!foundPosition) return '';

  const longitude = toFixedNumber(foundPosition.longitude);
  const latitude = toFixedNumber(foundPosition.latitude);

  return `${longitude} ${latitude}`;
};

const getAltitude = (item, positions) => {
  const foundPosition = positions[item.id];

  if (isUndefinedOrNull(foundPosition)) {
    return '';
  }

  const altitude = foundPosition?.altitude;

  if (isUndefinedOrNull(altitude)) {
    return foundPosition.attributes?.altitude ?? '';
  }
  return altitude;
};

const getLatitude = (item, positions) => {
  const foundPosition = positions[item.id];

  if (isUndefinedOrNull(foundPosition)) {
    return '';
  }

  const latitude = foundPosition?.latitude;

  if (isUndefinedOrNull(latitude)) {
    return toFixedNumber(foundPosition.attributes?.latitude ?? '');
  }
  return toFixedNumber(latitude);
};

const getLongitude = (item, positions) => {
  const foundPosition = positions[item.id];

  if (isUndefinedOrNull(foundPosition)) {
    return '';
  }

  const longitude = foundPosition?.longitude;

  if (isUndefinedOrNull(longitude)) {
    return toFixedNumber(foundPosition.attributes?.longitude ?? '');
  }
  return toFixedNumber(longitude);
};

const getColorConnectionStatus = (status, theme) => {
  switch (status) {
    case 'connected':
      return theme.palette.common.green;
    case 'disconnected':
    case 'error':
    default:
      return theme.palette.common.red;
  }
};

const getButtonControlModeColor = (workMode) => {
  switch (workMode) {
    case 'detection':
      return '#3397f5';
    case 'suppression':
      return '#fa675a';
    case 'idle':
    case 'unknown':
    case 'manual':
      return '#deefd8';
    case 'auto':
      return '#deefd8';
    case 'auto_with_delay':
      return '#deefd8';
    default:
      return '#ababab';
  }
};

const getWorkModeButtonColor = (item, mode) => {
  if (item.attributes?.categoryParams?.workMode === mode) {
    return getButtonControlModeColor(mode);
  }
  return '#ededed';
};

const concateStringsFromNotificator = (item, t) => {
  if (item.notificators) {
    return item.notificators
      .split(/[, ]+/)
      .filter(Boolean)
      .map((it) => t(prefixString('notificator', it)))
      .join(', ');
  }
  return '';
};

const getTextFromIconWithSeparator = (item, t) => {
  let icon = item?.icon;

  // let translatedIconText = t(`icon${icon?.replace(/^\w/, (c) => c?.toUpperCase())}`);

  if (isUndefined(icon)) {
    icon = t('iconDefault');
  }

  return insertSettingsToString(icon, icon);
};

const getTextFromIcon = (item, t) => {
  const icon = item?.icon;

  // const translatedIconName = t(`icon${icon?.replace(/^\w/, (c) => c.toUpperCase())}`);

  if (isUndefined(icon)) {
    return t('iconDefault');
  }

  return icon || t('iconDefault');
};

const translateTextFromIcon = (item, t) => {
  const [translatedName] = parseSettingsFromString(item.value);
  return isUndefined(translatedName) ? t('iconDefault') : translatedName;
};

const translateWorkModeFromItem = (item, t) => t(item.attributes?.categoryParams?.workMode);

const translateControlModeOrWorkMode = (item, t) => {
  if (item.attributes.categoryParams.controlMode !== 'manual') {
    return t(item.attributes?.categoryParams?.controlMode);
  }
  return t(item.attributes?.categoryParams?.workMode);
};

const translateWorkModeFromItemDescriptionOrCurrentWorkModeFromItem = (item, t) => {
  const description = item?.attributes?.categoryParams?.workModeDescription;

  if (description) return description;

  const statusText = t(item?.attributes?.categoryParams?.workMode);

  if (!statusText) {
    return '';
  }

  return statusText;
};

const translateCurrentWorkMode = (item, t, positions = []) => {
  const positionData = positions[item.id];
  const description = positionData?.attributes?.categoryParams?.workModeDescription ?? '';
  const workMode = positionData?.attributes?.categoryParams?.workMode ?? '';

  return translateCurrentWorkModeBase(item, t, description, workMode);
};

const translateControlMode = (item, t) => t(item.attributes?.categoryParams?.controlMode);

const translateConnectionStatus = (item, t, positions = []) => {
  const connectionStatus = positions[item.id]?.attributes?.categoryParams?.connectionStatus ?? '';
  return translateConnectionStatusBase(t, connectionStatus);
};

const translateConnectionStatusFromItem = (item, t) => {
  const connectionStatus = item?.attributes?.categoryParams?.connectionStatus;
  return translateConnectionStatusBase(t, connectionStatus);
};

const translateDeviceModelFromItem = (item, t, devices) => translateDeviceModel(t, devices[item.deviceId]?.model);

const translateDeviceStatus = (item, t) => insertSettingsToString(t(`global${toTitleCase(item.status)}`), item.status);
const translateDeviceStatusFromItem = (item, t) => t(`global${toTitleCase(item.status)}`);

const getTextForIconOrDefaultValue = (item, theme) => (item?.color || item?.dattributes?.color || item?.attributes?.color) ?? theme.palette.tracks.replay0;

export {
  getSourceNameFromDevicesObject,
  translateWorkModeFromItemDescriptionOrCurrentWorkModeFromItem,
  translateDeviceModelFromItem,
  toFixedNumber,
  getCourseFromPositions,
  getShortDateTime,
  translateDeviceStatusFromItem,
  getDateTime,
  getGroupName,
  getSourceNameFromItem,
  getSourceName,
  getProperty,
  getSpeed,
  translateTextFromIcon,
  getLatitudeAndLongitude,
  getLatitude,
  getLongitude,
  getColorConnectionStatus,
  getWorkModeButtonColor,
  getButtonControlModeColor,
  getCategoryName,
  concateStringsFromNotificator,
  toFixedWithConvertToNumber,
  getTextFromIconWithSeparator,
  getTextFromIcon,
  translateWorkModeFromItem,
  translateControlModeOrWorkMode,
  translateControlMode,
  translateCurrentWorkMode,
  translateConnectionStatus,
  translateConnectionStatusFromItem,
  translateDeviceStatus,
  getTextForIconOrDefaultValue,
  getAltitude,
  tryConvertAndFixedNumber,
};
