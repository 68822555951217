import React from 'react';
import {
  TextField, makeStyles,
} from '@material-ui/core';
import 'react-calendar-timeline/lib/Timeline.css';
import moment from 'moment';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import DatetimeCombobox, { periodRangeDisplayedEnum, periodsEnum } from '../../../common/components/DatetimeCombobox';

const useStyles = makeStyles(() => ({
  container: {
    marginBottom: 10,
    display: 'flex',
    gap: 10,
  },
  item: {
    flex: 1,
  },
  searchItem: {
    flex: 6,
  },
  datetimeItem: {
    flex: 2,
  },
}));

const periodRangeDisplayed = [
  periodRangeDisplayedEnum.Hours,
  periodRangeDisplayedEnum.Weeks,
  periodRangeDisplayedEnum.Days,
  periodRangeDisplayedEnum.Months,
];

const FiltersPanel = ({
  searchFilter, setSearchFilter, setDatetimeFilter,
}) => {
  const classes = useStyles();
  const t = useTranslation();

  const handleChangeDatetime = (from, to) => {
    setDatetimeFilter({ from: moment(from).unix(), to: moment(to).unix() });
  };

  const handleDatetimeUpdate = (from, to) => {
    setDatetimeFilter({ from: moment(from).unix(), to: moment(to).unix() });
  };

  const handleChangeSearchFilter = (e) => {
    setSearchFilter(e.target.value.toLowerCase());
  };

  return (
    <div className={classes.container}>
      <div className={classes.searchItem}>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          label={t('sharedSearch')}
          value={searchFilter}
          onChange={handleChangeSearchFilter}
        />
      </div>

      <div className={classes.datetimeItem}>
        <DatetimeCombobox
          inLine
          defaultPeriod={periodsEnum.Today}
          periodRangeDisplayed={periodRangeDisplayed}
          size="small"
          variant="outlined"
          datetimeTimer={handleDatetimeUpdate}
          onChangeDatetime={handleChangeDatetime}
        />
      </div>
    </div>
  );
};

export default FiltersPanel;
