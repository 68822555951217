import React from 'react';
import BatteryFullIcon from '@material-ui/icons/BatteryFull';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useSelector } from 'react-redux';
import {
  Avatar, Checkbox, Grid, IconButton, ListItem, ListItemAvatar, ListItemText, Typography,
} from '@material-ui/core';
import { useStylesDeviceList } from '../../MainPage/MainPage.styles';
import { useTranslation } from '../../../../common/components/LocalizationProvider';
import { getPosition, getIconSrc } from '../../../../common/utils/selectors';
import { formatMsToTime, formatPosition } from '../../../../common/utils/formatter';
import getIcon from '../../../../map/funcs/getIcon';

const getStatusColor = (status) => {
  switch (status) {
    case 'online':
      return 'green';
    case 'offline':
      return 'red';
    case 'unknown':
    default:
      return 'gray';
  }
};

const getIconTextColor = (status) => (status ? 'blue' : 'gray');

const getBatteryStatus = (batteryLevel) => {
  if (batteryLevel >= 70) {
    return 'green';
  }
  if (batteryLevel > 30) {
    return 'orange';
  }
  return 'red';
};

const VirtualDeviceRow = ({
  data,
  item,
  style,
}) => {
  const classes = useStylesDeviceList();
  const t = useTranslation();

  const {
    progress,
    onMenuClick,
    groups,
    closedDevices,
    chosenDevice,
    deviceToolOff,
    now,
    changeVisibleDevice,
    clickItem,
    iconText,
    tailOn,
  } = data;
  const position = useSelector(getPosition(item.id));
  const onTail = useSelector((state) => state.tail.tail);

  const handleChangeValue = (value, item) => {
    changeVisibleDevice(value, item.id);
  };

  const iconInfo = getIcon(item.icon, '#074eb8');
  return (
    <ListItem
      style={style}
      button
      key={item.id}
      className={`${classes.listItem} ${item.id === chosenDevice && classes.listItemChosen}`}
    >
      <Typography
        variant="h5"
        className={
          `${classes.statusLine}
            ${!(position && !closedDevices[position.deviceId]) && classes.statusLineHide}`
        }
        style={{ color: onTail ? (item.attributes.color || iconInfo.color) : '#82b74b' }}
      >
        |
      </Typography>

      {!deviceToolOff.includes('icon') && (
        <ListItemAvatar onClick={() => clickItem(item)} style={{ cursor: 'pointer' }}>
          <Avatar>
            <img className={classes.icon} src={getIconSrc(iconInfo)} alt="" />
            {!!(onTail && !closedDevices[item.id]) && (
              <Avatar className={classes.iconTrackColor}>
                <FiberManualRecordIcon style={{ color: item.attributes.color || iconInfo.color }} />
              </Avatar>
            )}
          </Avatar>
        </ListItemAvatar>
      )}

      <ListItemText
        className={classes.itemTextContainer}
        onClick={() => clickItem(item)}
        primary={(
          <span className={classes.itemTextMain}>
            {!deviceToolOff.includes('name') && (
              <span className={`${classes.itemText} `} style={{ color: (position && !item.disabled) ? '#000' : '#A0A0A0' }} title={item.name}>{item.name}</span>
            )}
            {![undefined, null].includes(tailOn) && (
              <img
                src={`/images/icon/switch/${tailOn ? 'tail-1' : 'tail-off'}.svg`}
                alt=""
                className={classes.tailIcon}
              />
            )}
            {item.disabled && (
              <PowerSettingsNewIcon className={`${classes.gray} ${classes.textIcon}`} />
            )}
            {![undefined, null].includes(iconText) && (
              <TextFieldsIcon className={`${classes[getIconTextColor(iconText)]} ${classes.textIcon}`} />
            )}
            {!deviceToolOff.includes('status') && (
              <Brightness1Icon className={`${classes[getStatusColor(item.status)]} ${classes.brightness}`} />
            )}
            {!!(!deviceToolOff.includes('lastUpdate') && item.lastUpdate) && (
              <span
                style={{
                  fontSize: 12,
                  whiteSpace: 'nowrap',
                  paddingRight: '5px',
                }}
              >
                {formatMsToTime(now.valueOf() - new Date(item.lastUpdate).valueOf(), t)}
              </span>
            )}
          </span>
        )}
        secondary={(
          !!(!deviceToolOff.includes('group') && groups[item.groupId]) && (
            <Typography className={classes.itemText} component="span" variant="body2" style={{ color: '#737373' }}>
              {groups[item.groupId].name || ''}
            </Typography>
          )
        )}
      />

      <li className={classes.indicators}>
        {position?.attributes.hasOwnProperty('batteryLevel') && (
          <Grid container direction="row" alignItems="center" alignContent="center" spacing={0}>
            <Grid style={{ width: 'max-content' }} item container xs alignItems="center" alignContent="center">
              {!deviceToolOff.includes('batteryPercent') && (
                <Grid item>
                  <span className={classes.batteryText}>
                    {formatPosition(position.attributes.batteryLevel, 'batteryLevel', t)}
                  </span>
                </Grid>
              )}
              {!deviceToolOff.includes('batteryPic') && (
                <Grid item>
                  <BatteryFullIcon className={classes[getBatteryStatus(position.attributes.batteryLevel)]} />
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
        {(position && (!deviceToolOff.includes('switch')) && (
          <Checkbox
            checked={!closedDevices[item.id]}
            onChange={(event) => handleChangeValue(event.target.checked, item)}
            disabled={!!progress}
            className={classes.menuButton}
          />
        ))}
        {!deviceToolOff.includes('editButton') && (
          <IconButton
            id={item.id}
            onClick={(event) => onMenuClick(event.currentTarget, item.id, item)}
            className={classes.menuButton}
          >
            <MoreVertIcon />
          </IconButton>
        )}
      </li>

    </ListItem>
  );
};

export default VirtualDeviceRow;
