import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { makeStyles } from '@material-ui/core/styles';

import { geofencesActions } from '../../../store';
import EditCollectionView from '../../../EditCollectionView';
import scrollStyles from '../../../common/theme/scrollStyles';

const useStyles = makeStyles(() => ({
  list: {
    maxHeight: '100%',
    overflow: 'auto',
    ...scrollStyles(),
  },
  icon: {
    width: '25px',
    height: '25px',
    filter: 'brightness(0) invert(1)',
  },
}));

const sortGeofences = (a, b) => {
  if (a.name > b.name) {
    return 1;
  }
  if (a.name < b.name) {
    return -1;
  }
  return 0;
};

const GeofenceView = ({ onMenuClick, searchName }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const items = useSelector((state) => Object.values(state.geofences.items)
    .filter((item) => item.name.toLowerCase().includes(searchName.toLowerCase()))
    .sort(sortGeofences));

  return (
    <List className={classes.list}>
      {items.map((item, index, list) => (
        <Fragment key={item.id}>
          <ListItem button key={item.id} onClick={() => dispatch(geofencesActions.select(item))}>
            <ListItemText primary={item.name} />
            <ListItemSecondaryAction>
              <IconButton onClick={(event) => onMenuClick(event.currentTarget, item.id)}>
                <MoreVertIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          {index < list.length - 1 ? <Divider /> : null}
        </Fragment>
      ))}
    </List>
  );
};

const GeofencesList = ({ setSaveMode, searchName }) => (
  <EditCollectionView
    content={GeofenceView}
    editPath="/geofence"
    endpoint="geofences"
    disableAdd
    setSaveMode={setSaveMode}
    searchName={searchName}
  />
);

export default GeofencesList;
