import React from 'react';
import {
  Button,
  Typography,
  Backdrop, Fade, Modal, Paper, CircularProgress, makeStyles,
} from '@material-ui/core';
import { useTranslation } from './LocalizationProvider';
import scrollStyles from '../theme/scrollStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '10px',
  },
  data: {
    maxHeight: '60vh',
    padding: '10px',
    overflow: 'auto',
    ...scrollStyles(5),
  },
  adornmentButton: {
    padding: 0,
  },
  iconValue: {
    display: 'flex',
    gap: theme.spacing(2),
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '85%',
    backgroundColor: 'white',
    boxShadow: 24,
    p: 4,
    [theme.breakpoints.up('sm')]: {
      width: '450px',
    },
  },
  text: {
    marginTop: theme.spacing(1.5),
  },
  buttons: {
    display: 'flex',
    marginBottom: 10,
    justifyContent: 'space-evenly',
    '& > *': {
      flexBasis: '33%',
    },
  },
}));

const ModalEdit = ({
  children, open, onClose, onAccept, title, styles, disabledSave, progress,
}) => {
  const classes = useStyles();
  const t = useTranslation();

  return (
    <>
      <Modal
        open={open}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
        onClose={onClose}
      >
        <Fade in={open}>
          <Paper style={styles?.modal} className={classes.modal}>
            <Typography style={styles?.text} className={classes.text} id="modal-modal-title" variant="h6" component="h2" align="center">
              {title}
            </Typography>

            <div style={styles?.root} className={classes.root}>
              <div style={styles?.data} className={classes.data}>
                {children}
              </div>
            </div>

            <div style={styles?.buttons} className={classes.buttons}>
              <Button type="button" color="primary" variant="outlined" onClick={onClose}>
                {t('sharedCancel')}
              </Button>
              <Button
                color="primary"
                variant={progress ? 'outlined' : 'contained'}
                onClick={onAccept}
                disabled={!!(disabledSave || progress)}
              >
                {progress ? <CircularProgress /> : t('sharedSave')}
              </Button>
            </div>
          </Paper>
        </Fade>
      </Modal>
    </>
  );
};

export default ModalEdit;
