/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable default-case */
/* eslint-disable no-var */
/* eslint-disable vars-on-top */
/* eslint-disable consistent-return */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
export default function M3U8() {
  const _this = this; // access root scope

  this.ie = navigator.appVersion.toString().indexOf('.NET') > 0;
  this.ios = navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

  this.start = function (m3u8, options) {
    if (!options) { options = {}; }

    const callbacks = {
      progress: null,
      finished: null,
      error: null,
      aborted: null,
    };

    let recur; // the recursive download instance to later be initialized. Scoped here so callbakcs can access and manage it.

    function handleCb(key, payload) {
      if (key && callbacks[key]) { callbacks[key](payload); }
    }

    if (_this.ios) { return handleCb('error', 'Downloading on IOS is not supported.'); }

    var startObj = {
      on(str, cb) {
        switch (str) {
          case 'progress': {
            callbacks.progress = cb;
            break;
          }
          case 'finished': {
            callbacks.finished = cb;
            break;
          }
          case 'error': {
            callbacks.error = cb;
            break;
          }
          case 'aborted': {
            callbacks.aborted = cb;
            break;
          }
        }

        return startObj;
      },
      abort() {
        recur && (recur.aborted = function () {
          handleCb('aborted');
        });
      },
    };

    const download = new Promise((resolve, reject) => {
      const url = new URL(m3u8);

      const req = fetch(m3u8)
        .then((d) => d.text())
        .then((d) => {
          const segmentReg = /^(?!#)(.+)\.(.+)$/gm;
          const segments = d.match(segmentReg);

          const mapped = map(segments, (v, i) => {
            const temp_url = new URL(v, url); // absolute url
            return temp_url.href;
          });

          if (!mapped.length) {
            reject('Invalid m3u8 playlist');
            return handleCb('error', 'Invalid m3u8 playlist');
          }

          recur = new RecurseDownload(mapped, ((data) => {
            const blob = new Blob(data, {
              type: 'octet/stream',
            });

            handleCb('progress', {
              status: 'Processing...',
            });

            if (!options.returnBlob) {
              if (_this.ios) {
                // handle ios?
              } else if (_this.ie) {
                handleCb('progress', {
                  status: "Sending video to Internet Explorer... this may take a while depending on your device's performance.",
                });
                window.navigator.msSaveBlob(blob, (options && options.filename) || 'video.mp4');
              } else {
                handleCb('progress', {
                  status: 'Sending video to browser...',
                });
                const a = document.createElementNS('http://www.w3.org/1999/xhtml', 'a');
                a.href = URL.createObjectURL(blob);
                a.download = (options && options.filename) || 'video.mp4';
                a.style.display = 'none';
                document.body.appendChild(a); // Firefox fix
                a.click();
                handleCb('finished', {
                  status: 'Successfully downloaded video',
                  data: blob,
                });
                resolve(blob);
              }
            } else {
              handleCb('finished', {
                status: 'Successfully downloaded video',
                data: blob,
              });
              resolve(blob);
            }
          }), 0, []);

          recur.onprogress = function (obj) {
            handleCb('progress', obj);
          };
        })
        .catch((err) => {
          handleCb('error', `Something went wrong when downloading m3u8 playlist: ${err}`);
        });
    });

    return startObj;
  };

  function RecurseDownload(arr, cb, i, data) { // recursively download asynchronously 2 at the time
    const _this = this;

    this.aborted = false;

    recurseDownload(arr, cb, i, data);

    function recurseDownload(arr, cb, i, data) {
      const req = Promise.all([fetch(arr[i]), arr[i + 1] ? fetch(arr[i + 1]) : Promise.resolve()]) // HTTP protocol dictates only TWO requests can be simultaneously performed
        .then((d) => map(filter(d, (v) => v && v.blob), (v) => v.blob()))
        .then((d) => Promise.all(d))
        .then((d) => {
          const blobs = map(d, (v, j) => new Promise((resolve, reject) => {
            const reader = new FileReader();

            const read = reader.readAsArrayBuffer(new Blob([v], {
              type: 'octet/stream',
            })); // IE can't read Blob.arrayBuffer :(

            reader.addEventListener('loadend', (event) => { // event listener, my old friend we meet again... I cenrtainly haven't missed you in place of promise
              resolve(reader.result);
              (_this.onprogress && _this.onprogress({
                segment: i + j + 1,
                total: arr.length,
                percentage: Math.floor((i + j + 1) / arr.length * 100),
                downloaded: formatNumber(+reduce(map(data, (v) => v.byteLength), (t, c) => t + c, 0)),
                status: 'Downloading...',
              }));
            });
          }));

          Promise.all(blobs).then((d) => {
            for (let n = 0; n < d.length; n++) { // polymorphism
              data.push(d[n]);
            }

            const increment = arr[i + 2] ? 2 : 1; // look ahead to see if we can perform 2 requests at the same time again

            if (_this.aborted) {
              data = null; // purge data... client side calling of garbage collector isn't possible. I know about opera and ie's garbage collectors but they're not ideal.
              _this.aborted();
              // exit promise
            } else if (arr[i + increment]) {
              setTimeout(() => {
                recurseDownload(arr, cb, i + increment, data);
              }, _this.ie ? 500 : 0);
            } else {
              cb(data);
            }
          });
        })
        .catch((err) => {
          _this.onerror && _this.onerror(`Something went wrong when downloading ts file, nr. ${i}: ${err}`);
        });
    }
  }

  function filter(arr, condition) {
    const result = [];
    for (let i = 0; i < arr.length; i++) {
      if (condition(arr[i], i)) {
        result.push(arr[i]);
      }
    }
    return result;
  }

  function map(arr, condition) {
    const result = arr.slice(0);
    for (let i = 0; i < arr.length; i++) {
      result[i] = condition(arr[i], i);
    }
    return result;
  }

  function reduce(arr, condition, start) {
    let result = start;
    arr.forEach((v, i) => {
      const res = +condition(result, v, i);
      result = res;
    });
    return result;
  }

  function formatNumber(n) {
    const ranges = [{
      divider: 1e18,
      suffix: 'EB',
    },
    {
      divider: 1e15,
      suffix: 'PB',
    },
    {
      divider: 1e12,
      suffix: 'TB',
    },
    {
      divider: 1e9,
      suffix: 'GB',
    },
    {
      divider: 1e6,
      suffix: 'MB',
    },
    {
      divider: 1e3,
      suffix: 'kB',
    },
    ];
    for (let i = 0; i < ranges.length; i++) {
      if (n >= ranges[i].divider) {
        const res = (n / ranges[i].divider).toString();

        return res.toString().split('.')[0] + ranges[i].suffix;
      }
    }
    return n.toString();
  }
}
