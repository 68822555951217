import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'positions',
  initialState: {
    items: {},
    stateSyncCurrentPositions: new Date().getTime(),
    popupData: JSON.parse(window.localStorage.getItem('popupData')),
    selectedPositionData: {},
    needToShowDataPanel: false,
    trackingMode: false,
  },
  reducers: {
    update(state, action) {
      action.payload.forEach((item) => state.items[item.deviceId] = item);
      state.stateSyncCurrentPositions = new Date().getTime();
    },
    remove(state, action) {
      for (const item of action.payload) {
        delete state.items[item];
      }
      // if (action.payload.length) {
      //   state.stateSyncCurrentPositions = new Date().getTime();
      // }
    },
    updatePopupData(state, action) {
      state.popupData = action.payload;
      window.localStorage.setItem('popupData', JSON.stringify(action.payload));
    },
    clear(state) {
      state.items = {};
      state.stateSyncCurrentPositions = new Date().getTime();
    },
    selectPosition(state, action) {
      state.selectedPositionData = action.payload;
    },
    unselectPosition(state) {
      state.selectedPositionData = {};
    },
    needToShowDataPanel(state, action) {
      state.needToShowDataPanel = action.payload;
    },
    changeTrackingMode(state, action) {
      state.trackingMode = action.payload;
    },
  },
});

export { actions as positionsActions };
export { reducer as positionsReducer };
