import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@material-ui/core';
import sector from '@turf/sector';
import moment from 'moment';
import { map } from '../Map';
import { markersActions, positionsActions } from '../../store';
import { createLayers, createRadarLayers } from '../funcs/markerMapFuncs';
import { useEffectAsync } from '../../common/utils/reactHelper';
import MarkerInfo from './MarkerInfo';
import markerStatus from '../../common/constants/markerStatus';
import { convertToConfig } from '../MapControllerDynamic';
import getIcon from '../funcs/getIcon';

const MarkersMap = ({
  noClick, setNewMarkerCoordinates, moveableMarker, setMoveableMarker, onClusters, text,
  setPositionDataVisible, setOpenChoice, openChoice, setNextPosition, setPositionsBS, setSelectedMarkerComponents,
  markerState, clearMarkerState,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const markersStore = useSelector((state) => Object.values(state.markers.items));
  const userStore = useSelector((state) => state.session.user);
  const needToShowOpacity = useSelector((state) => state.session?.user?.attributes?.deviceDisplaySettings?.markers?.opacityByLifetime);

  const getOpacity = (marker) => {
    try {
      const { lifetime } = marker.attributes;
      let ratio = 1;
      if (lifetime && needToShowOpacity) {
        const lastUpdateMoment = moment(marker.markerTime);
        const now = moment();
        const elapsedMilliseconds = now.diff(lastUpdateMoment);
        const lifetimeMilliseconds = moment.duration(lifetime.value, lifetime.time).asMilliseconds();
        ratio = 1 - (elapsedMilliseconds / lifetimeMilliseconds);
      }
      return ratio;
    } catch (error) {
      return 1;
    }
  };

  const [marker, setMarker] = useState();
  const [chosenMarker, setChosenMarker] = useState();

  const noClickRef = useRef(noClick);

  const id = 'mark';
  const idRadar = 'markradar';
  const clusters = `${id}-clusters`;

  const onClusterClick = useCallback((event) => {
    event.preventDefault();

    const features = map.queryRenderedFeatures(event.point, {
      layers: [clusters],
    });
    const clusterId = features[0].properties.cluster_id;

    map.getSource(id).getClusterExpansionZoom(clusterId, (error, zoom) => {
      if (!error) {
        map.easeTo({
          center: features[0].geometry.coordinates,
          zoom,
        });
      }
    });
  }, []);

  const openMarkerInfo = useCallback(async (e) => {
    if (!noClickRef.current) {
      setChosenMarker(e.features[0]);
    }
  });

  const changeMarkerPlace = async (e) => {
    setMoveableMarker(null);
    const response = await fetch(`/api/markers/${moveableMarker.id}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ...moveableMarker, latitude: e.lngLat.lat, longitude: e.lngLat.lng, altitude: 0, userId: userStore.id,
      }),
    });

    if (response.ok) {
      // Очистка обводки после перемещения.
      dispatch(positionsActions.unselectPosition());
      dispatch(markersActions.update([await response.json()]));
    }
  };

  // Обработка перемещения маркера от компонента TextMarkerCoordinates.
  useEffect(() => {
    if (!markerState.current || !markerState.current.lngLat) return;
    if (markerState.current.actionType === markerStatus.Move) {
      changeMarkerPlace(markerState.current);
      clearMarkerState();
    }
  }, [markerState.current]);

  const updateCoordinatesText = (e) => {
    setNewMarkerCoordinates(e.lngLat);
  };

  const createFeature = (marker) => {
    const iconInfo = getIcon(marker.icon?.replace('.svg', ''), '#000000');
    const iconName = convertToConfig({
      icon: iconInfo.icon,
      primary: marker.color || iconInfo.color,
      category: 'marker',
    });
    return (
      {
        id: marker.id,
        markerSymbol: iconName,
        markerColor: marker.color || iconInfo.color,
        title: text ? marker.name : '',
        markerTime: marker.markertime,
        course: marker.course,
        canRotate: iconInfo.canRotate,
        icon: marker.course ? 'course' : null,
        iconOpacity: getOpacity(marker),
        textOpacity: getOpacity(marker),
      }
    );
  };

  useEffect(() => noClickRef.current = noClick, [noClick]);

  useEffect(() => createLayers(id, onClusters, openMarkerInfo, onClusterClick), [onClusterClick, onClusters]);

  useEffect(() => createRadarLayers(idRadar), []);

  useEffectAsync(async () => {
    if (chosenMarker) {
      const response = await fetch(`/api/markers/${chosenMarker.properties.id}`);
      if (response.ok) {
        setMarker(await response.json());
      }
    }
  }, [chosenMarker]);

  useEffect(() => {
    if (moveableMarker) {
      map.on('click', changeMarkerPlace);
    }
    if (moveableMarker) {
      map.on('mousemove', updateCoordinatesText);
    }
    return () => {
      if (moveableMarker) {
        map.off('click', changeMarkerPlace);
      }
      if (moveableMarker) {
        map.off('mousemove', updateCoordinatesText);
      }
    };
  }, [moveableMarker]);

  useEffect(() => {
    const features = [];
    const featuresWithRadar = [];

    markersStore.forEach((marker) => {
      const feature = {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [marker.longitude, marker.latitude],
        },
        properties: createFeature(marker),
      };

      features.push(feature);

      const { categoryParams } = marker.attributes;

      if (marker.category === 'radar' && categoryParams?.category === 'radar') {
        const polygonFeature = sector(
          [marker.longitude, marker.latitude],
          (Number(categoryParams.radius) || 1) / 1000,
          Number(categoryParams.azimuth) - Number(categoryParams.diagram) / 2,
          Number(categoryParams.azimuth) + Number(categoryParams.diagram) / 2,
          { steps: 35 },
        );

        polygonFeature.properties = { color: categoryParams.color || theme.palette.markers.black };

        featuresWithRadar.push(polygonFeature);
      }
    });
    map.getSource(id).setData({
      type: 'FeatureCollection',
      features,
    });
    map.getSource(idRadar).setData({
      type: 'FeatureCollection',
      features: featuresWithRadar,
    });
  }, [markersStore]);

  return (
    <>
      {marker && (
        <MarkerInfo
          marker={marker}
          openChoice={openChoice}
          setOpenChoice={setOpenChoice}
          setPositionDataVisible={setPositionDataVisible}
          setPositionsBS={setPositionsBS}
          setNextPosition={setNextPosition}
          moveableMarker={moveableMarker}
          setMoveableMarker={setMoveableMarker}
          setMarker={setMarker}
          setSelectedMarkerComponents={setSelectedMarkerComponents}
          markerState={markerState}
        />
      )}
    </>
  );
};

export default MarkersMap;
