import React, { useState } from 'react';
import {
  Button, LinearProgress, makeStyles,
} from '@material-ui/core';
import PageLayout from '../../../common/components/PageLayout';
import ReportsMenu from '../components/ReportsMenu';
import 'react-calendar-timeline/lib/Timeline.css';
import { useEffectAsync } from '../../../common/utils/reactHelper';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import { downloadFileByUrl } from '../../../common/utils/fileHelper';
import FiltersPanel from './FiltersPanel';
import { minutesToMilliseconds } from '../../../common/utils/datetimeHelper';
import CameraBlocksContainer from '../components/CameraBlocksContainer';
import CameraBlock from '../components/CameraBlock';
import ExportLayer from '../components/ExportLayer';
import CameraActionsWindows from '../components/CameraActionsWindows';

const useStyles = makeStyles(() => ({
  noData: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const breadcrumbs = ['reportTitle', 'reportCameraRecording'];

const CameraRecordSavedReportPage = () => {
  const classes = useStyles();
  const t = useTranslation();

  const [videos, setVideos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [searchFilter, setSearchFilter] = useState('');

  const exportLayer = ExportLayer({});

  const acceptDeleteConfirmWindow = async (videoItem) => {
    try {
      const { videoRecordService, id } = videoItem;
      const url = `${videoRecordService}/api/export/${id}`;
      const response = await fetch(url, { method: 'DELETE' });

      if (response.ok) {
        setVideos((prev) => {
          const newVideos = prev.filter((v) => v.id !== id);
          return newVideos;
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const cameraActionsWindows = CameraActionsWindows({
    onDeleteConfirm: acceptDeleteConfirmWindow,
  });

  const loadExports = async () => {
    try {
      await exportLayer.loadExports();
      setVideos(exportLayer.getExportedVideos());
      if (isError) {
        setIsError(false);
      }
    } catch (error) {
      console.error(error);
      setIsError(true);
    }
  };

  const loadPageData = async () => {
    setIsLoading(true);
    await loadExports();
    setIsLoading(false);
  };

  useEffectAsync(async () => {
    await loadPageData();

    const id = setInterval(loadExports, minutesToMilliseconds(2));
    return () => clearInterval(id);
  }, []);

  const handleSaveVideo = async (videoItem, startDownload, endDownload) => {
    startDownload();

    try {
      await downloadFileByUrl(
        `${videoItem.videoRecordService}/exports/${videoItem.id}.mp4`,
        `${videoItem.name}.mp4`,
      );
    } catch (error) {
      console.error(error);
    }

    endDownload();
  };

  const handleRemoveVideo = (videoItem) => {
    cameraActionsWindows.setDataForWindows({
      ...videoItem,
      text: videoItem.mainText,
    });
    cameraActionsWindows.openDeleteConfirmWindow();
  };

  const handlePlayVideo = (videoItem) => {
    cameraActionsWindows.setDataForWindows({
      ...videoItem,
      srcUrl: videoItem.videoUrl,
      text: videoItem.mainText,
    });
    cameraActionsWindows.openVideoPreviewWindow();
  };

  if (isError) {
    return (
      <PageLayout menu={<ReportsMenu />} breadcrumbs={breadcrumbs}>
        <div style={{ flexDirection: 'column' }} className={classes.noData}>
          <div>{t('globalErrorOccurredReceiving')}</div>
          <Button disabled={isLoading} variant="outlined" color="primary" onClick={loadPageData}>
            {t('globalAgain')}
          </Button>
        </div>
      </PageLayout>
    );
  }

  if (isLoading) {
    return (
      <PageLayout menu={<ReportsMenu />} breadcrumbs={breadcrumbs}>
        <LinearProgress />
      </PageLayout>
    );
  }

  return (
    <PageLayout menu={<ReportsMenu />} breadcrumbs={breadcrumbs}>
      {cameraActionsWindows.ActionsWindows}

      <CameraBlocksContainer
        isNoData={videos.length === 0}
        filtersComponent={(
          <FiltersPanel
            searchFilter={searchFilter}
            setSearchFilter={setSearchFilter}
          />
        )}
      >
        {videos
          .filter((cameraItem) => (cameraItem.name.toLowerCase().includes(searchFilter)))
          .map((cameraItem) => (
            <CameraBlock
              key={cameraItem.id}
              cameraItem={{
                ...cameraItem,
                id: cameraItem.id,
                srcUrl: cameraItem.imgUrl,
                mainText: cameraItem.name,
                inProcess: cameraItem.in_progress,
              }}
              onPlayVideo={handlePlayVideo}
              onRemoveVideo={handleRemoveVideo}
              onSaveVideo={handleSaveVideo}
            />
          ))}
      </CameraBlocksContainer>
    </PageLayout>
  );
};

export default CameraRecordSavedReportPage;
