import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Accordion, AccordionSummary, AccordionDetails, Typography, makeStyles,
} from '@material-ui/core';
import EditItemView from './components/EditItemView';
import { useTranslation } from '../../common/components/LocalizationProvider';

const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
}));

const CommandPage = () => {
  const classes = useStyles();
  const t = useTranslation();
  const [item, setItem] = useState();

  useEffect(() => {
    if (!item?.type) {
      setItem({ ...item, type: 'custom', textChannel: true });
    }
  }, [item]);

  return (
    <EditItemView endpoint="commands" item={item} setItem={setItem}>
      {item
        && (
          <>
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">
                  {t('sharedRequired')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <TextField
                  margin="normal"
                  value={item.description || ''}
                  onChange={(event) => setItem({ ...item, description: event.target.value })}
                  label={t('sharedDescription')}
                  variant="filled"
                />
                <TextField
                  margin="normal"
                  value={item.attributes?.data || ''}
                  onChange={(event) => setItem({ ...item, attributes: { data: event.target.value } })}
                  label={t('commandData')}
                  variant="filled"
                />
              </AccordionDetails>
            </Accordion>
          </>
        )}
    </EditItemView>
  );
};

export default CommandPage;
