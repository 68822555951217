/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { memo, useEffect, useState } from 'react';
import {
  List,
  ListItem,
  TextField,
  Container,
  Button,
  Divider,
  IconButton,
  Typography,
  makeStyles,
  useTheme,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogActions,
  CircularProgress,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { useDispatch } from 'react-redux';
import { useTranslation } from '../../common/components/LocalizationProvider';
import OptionsLayout from './OptionsLayout';
import CustomColorPicker from '../../common/components/CustomColorPicker';
import AddIconDialog from './components/AddIconDialog';
import { useEffectAsync } from '../../common/utils/reactHelper';
import getIcon from '../../map/funcs/getIcon';
import { iconsCoverter, iconsSortArr } from '../../common/utils/converter';
import { errorsActions } from '../../store';
import ChangeIconDialog from './components/ChangeIconDialog';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: '#fff',
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[4],
  },
  listItem: {
    backgroundColor: '#f9f9f9',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
  },
  icon: {
    width: 40,
    height: 40,
    marginRight: theme.spacing(2),
    cursor: 'pointer',
  },
  textField: {
    flexGrow: 1,
    marginRight: theme.spacing(2),
  },
  colorPicker: {
    width: '250px',
    marginRight: theme.spacing(2),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    '& > *': {
      flexBasis: '33%',
    },
    marginTop: theme.spacing(2),
  },
  emptyMessage: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
}));

const IndividualTextField = memo(({
  keyId,
  label,
  initialValue,
  handleChange,
  handleBlur,
  error,
  helperText,
  className,
  icon,
}) => {
  const [localValue, setLocalValue] = useState(initialValue);
  const [debouncedValue, setDebouncedValue] = useState(initialValue);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(localValue);
    }, 250);

    return () => {
      clearTimeout(handler);
    };
  }, [localValue]);

  useEffect(() => {
    if (debouncedValue !== initialValue) {
      handleChange(keyId, icon, debouncedValue);
    }
  }, [debouncedValue, initialValue]);

  const onLocalChange = (e) => {
    setLocalValue(e.target.value);
  };

  const onLocalBlur = async (e) => {
    await handleBlur(keyId, icon, e.target.value.trim());
  };

  return (
    <TextField
      label={label}
      value={localValue}
      onChange={onLocalChange}
      onBlur={onLocalBlur}
      error={error}
      helperText={helperText}
      className={className}
    />
  );
});

export default function IconsPage() {
  const classes = useStyles();
  const history = useHistory();
  const t = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();

  const [isLoaded, setIsLoaded] = useState(false);
  const [icons, setIcons] = useState({});
  const [errors, setErrors] = useState({});
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [iconToDelete, setIconToDelete] = useState(null);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openChangeDialog, setOpenChangeDialog] = useState(false);
  const [sortedIconsArr, setSortedIconsArr] = useState([]);
  const [bufferPut, setBufferPut] = useState([]);
  const [bufferPost, setBufferPost] = useState([]);
  const [bufferDelete, setBufferDelete] = useState([]);

  const [progress, setProgress] = useState(0);

  useEffectAsync(async () => {
    const response = await fetch('/api/icons');
    if (response.ok) {
      const data = await response.json();
      const iconsList = iconsCoverter(data);

      const sortedIcons = iconsSortArr(iconsList);
      setSortedIconsArr(sortedIcons);
      setIcons(iconsList || {});
      setIsLoaded(true);
    }
  }, []);

  const handleNameCheck = (key, icon, newName) => {
    if (newName.trim() === '') {
      setErrors((prevState) => ({
        ...prevState,
        [key]: t('emptyNameIconErrorMsg'),
      }));
      return false;
    }

    const regex = /^[а-яА-Я]+_[а-яА-Я]+_\d+$/;
    if (regex.test(newName) && newName !== icon) {
      setErrors((prevState) => ({
        ...prevState,
        [key]: t('reservedIconErrorMsg'),
      }));
      return false;
    }

    if (newName !== key && icons[newName]) {
      setErrors((prevState) => ({
        ...prevState,
        [key]: t('duplicateIconErrorMsg'),
      }));
      return false;
    }

    if (newName.length >= 25) {
      setErrors((prevState) => ({
        ...prevState,
        [key]: t('tooLongIconErrorMsg'),
      }));
      return false;
    }

    setErrors((prevState) => ({
      ...prevState,
      [key]: '',
    }));
    return true;
  };

  const handleNameBlur = async (oldKey, icon, newKey) => {
    const regex = /^[а-яА-Я]+_[а-яА-Я]+_\d+$/;
    if (!(!newKey || newKey === oldKey || icons[newKey] || (regex.test(newKey) && newKey !== icon) || newKey.length >= 25)) {
      const updatedItem = {
        ...icons[oldKey],
        name: newKey,
      };

      setIcons((prevState) => {
        const newState = { ...prevState };
        newState[newKey] = {
          ...newState[oldKey],
        };
        delete newState[oldKey];
        return newState;
      });

      if (updatedItem.id) {
        setBufferPut((prevState) => {
          const newState = [...prevState];
          newState.push(updatedItem);
          return newState;
        });
      } else {
        const updatedBufferPost = bufferPost.map((item) => {
          if (item.name === oldKey) {
            return { ...item, name: newKey };
          }
          return item;
        });
        setBufferPost(updatedBufferPost);
      }
      setSortedIconsArr(sortedIconsArr.map((name) => (name === oldKey ? newKey : name)));
    }
  };

  const handleIconUpdate = (key, icon) => {
    const isNameValid = handleNameCheck(key, icon, key);
    if (isNameValid) {
      const updatedItem = {
        ...icons[key],
        name: key,
        icon,
      };

      if (updatedItem.id) {
        setBufferPut((prevState) => {
          const newState = [...prevState];
          newState.push(updatedItem);
          return newState;
        });
      } else {
        const updatedBufferPost = bufferPost.map((item) => {
          if (item.name === key) {
            return { ...item, icon };
          }
          return item;
        });
        setBufferPost(updatedBufferPost);
      }
    }
  };

  const handleColorChange = async (key, color) => {
    const updatedItem = {
      ...icons[key],
      name: key,
      color,
    };

    setIcons((prevState) => ({
      ...prevState,
      [key]: {
        ...prevState[key],
        color,
      },
    }));

    if (updatedItem.id) {
      setBufferPut((prevState) => {
        const newState = [...prevState];
        newState.push(updatedItem);
        return newState;
      });
    } else {
      const updatedBufferPost = bufferPost.map((item) => {
        if (item.name === key) {
          return { ...item, color };
        }
        return item;
      });
      setBufferPost(updatedBufferPost);
    }
  };

  function insertAfter(array, target, newElement) {
    const result = [];
    let inserted = false;

    for (const item of array) {
      result.push(item);

      if (item === target && !inserted) {
        result.push(newElement);
        inserted = true;
      }
    }

    if (!inserted) {
      result.push(newElement);
    }

    return result;
  }

  const handleDuplicate = async (key) => {
    let i = 2;
    let newKey = `${key} - ${t('iconsCopy')}`;

    if (icons[`${key} - ${t('iconsCopy')}`]) {
      newKey = `${key} - ${t('iconsCopy')} (${i})`;
    }

    while (icons[`${key} - ${t('iconsCopy')} (${i})`]) {
      i++;
      newKey = `${key} - ${t('iconsCopy')} (${i})`;
    }

    const newItem = {
      ...icons[key],
      name: newKey,
    };
    delete newItem.id;

    if (newKey.length >= 25) {
      setErrors((prevState) => ({
        ...prevState,
        [newKey]: t('tooLongIconErrorMsg'),
      }));
    }

    setBufferPost((prevState) => {
      const newState = [...prevState];
      newState.push(newItem);
      return newState;
    });

    setIcons((prevState) => ({
      ...prevState,
      ...iconsCoverter([newItem]),
    }));
    setSortedIconsArr(insertAfter(sortedIconsArr, key, newKey));
  };

  const handleDeleteOpen = (key) => {
    setIconToDelete(key);
    setOpenDeleteDialog(true);
  };

  const handleDeleteClose = () => {
    setOpenDeleteDialog(false);
    setIconToDelete(null);
  };

  const handleDeleteConfirm = async () => {
    if (icons[iconToDelete].id) {
      setBufferDelete((prevState) => {
        const newState = [...prevState];
        newState.push(icons[iconToDelete].id);
        return newState;
      });
    } else {
      setBufferPost((prevBufferPost) => prevBufferPost.filter((item) => item.name !== iconToDelete));
    }

    setIcons((prevState) => {
      const newState = { ...prevState };
      delete newState[iconToDelete];
      return newState;
    });

    setErrors((prevState) => ({
      ...prevState,
      [iconToDelete]: '',
    }));

    setSortedIconsArr(sortedIconsArr.filter((element) => element !== iconToDelete));
    handleDeleteClose();
  };

  function filterLatestById(buffer) {
    const uniqueItems = {};

    buffer.forEach((item) => {
      uniqueItems[item.id] = item;
    });

    return Object.values(uniqueItems);
  }

  const returnBackOrCloseWindow = () => {
    try {
      if (new URLSearchParams(window.location.search)?.has('isNewTab')) {
        window.close();
      }
    } catch (error) {
      console.warn('Ошибка при проверки строки поиска.', error);
    }
    history.push('/settings');
  };

  const handleSave = async () => {
    setProgress(1);
    if (Object.values(errors).some((error) => error)) {
      return;
    }

    const postRequests = bufferPost.map(async (item) => {
      const response = await fetch('/api/icons', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(item),
      });
      if (!response.ok) {
        dispatch(errorsActions.push(`${t('errorSaveIcons')} - ${response.status}`));
      }
    });

    const putRequests = filterLatestById(bufferPut).map(async (item) => {
      const response = await fetch(`/api/icons/${item.id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(item),
      });
      if (!response.ok) {
        dispatch(errorsActions.push(`${t('errorSaveIcons')} - ${response.status}`));
      }
    });

    const deleteRequests = bufferDelete.map(async (item) => {
      const response = await fetch(`/api/icons/${item}`, {
        method: 'DELETE',
      });
      if (!response.ok) {
        dispatch(errorsActions.push(`${t('errorSaveIcons')} - ${response.status}`));
      }
    });

    try {
      await Promise.all([...postRequests, ...putRequests, ...deleteRequests]);
    } catch (error) {
      dispatch(errorsActions.push(`${t('errorSaveIcons')} - ${error}`));
    } finally {
      setProgress(0);
      returnBackOrCloseWindow();
    }
  };

  const handleAddIcon = async (newIcon, newName, newColor, onClose) => {
    if (!newIcon) return;

    const newItem = {
      attributes: { source: 'SmartTrack' },
      color: newColor,
      icon: newIcon,
      name: newName,
    };

    setBufferPost((prevState) => {
      const newState = [...prevState];
      newState.push(newItem);
      return newState;
    });

    setIcons((prevState) => ({
      ...prevState,
      ...iconsCoverter([newItem]),
    }));
    setSortedIconsArr([...sortedIconsArr, newName]);
    onClose();
  };

  const handleIconClick = (key) => {
    setOpenChangeDialog(key);
  };

  const isSaveDisabled = Object.values(errors).some((error) => error);
  return (
    <OptionsLayout>
      <Container maxWidth="sm" className={classes.container}>
        <div className={classes.header}>
          <Typography>{t('settingsIcons')}</Typography>
          <Button color="primary" variant="outlined" onClick={() => { history.push('/settings/icons/catalog'); }}>
            {t('settingsGoToСatalogIcons')}
          </Button>
        </div>

        {!isLoaded
          ? (
            <div style={{ display: 'flex', justifyContent: 'center', padding: theme.spacing(2) }}>
              <CircularProgress />
            </div>
          )
          : (
            <>
              {Object.keys(icons).length === 0 ? (
                <Typography className={classes.emptyMessage}>
                  {t('noIconsSelectedMsg')}
                </Typography>
              ) : (
                <List>
                  {sortedIconsArr.map((key, index) => (
                    <React.Fragment key={key}>
                      <ListItem className={classes.listItem}>
                        <img
                          src={`/images/icon/device/${getIcon(icons[key]?.icon, '#000000').icon}.svg`}
                          alt={key}
                          className={classes.icon}
                          onClick={() => { handleIconClick(key); }}
                        />
                        <IndividualTextField
                          key={key}
                          keyId={key}
                          label={t('sharedName')}
                          initialValue={key}
                          handleChange={handleNameCheck}
                          handleBlur={handleNameBlur}
                          error={!!errors[key]}
                          helperText={errors[key] || ''}
                          className={classes.textField}
                          icon={icons[key]?.icon}
                        />
                        <div className={classes.colorPicker}>
                          <CustomColorPicker
                            color={icons[key]?.color}
                            setColor={(color) => handleColorChange(key, color)}
                            label={t('markerColor')}
                            presetColors={theme.palette.markers}
                          />
                        </div>

                        <Tooltip title={t('sharedDuplicate')}>
                          <IconButton onClick={() => handleDuplicate(key)}>
                            <FileCopyIcon />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title={t('sharedRemove')}>
                          <IconButton onClick={() => handleDeleteOpen(key)}>
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>

                      </ListItem>
                      {index < Object.keys(icons).length - 1 && <Divider />}
                    </React.Fragment>
                  ))}
                </List>
              )}
            </>
          )}

        <Button
          fullWidth
          disabled={isSaveDisabled}
          color="primary"
          variant="contained"
          onClick={() => setOpenAddDialog(true)}
          style={{ marginTop: theme.spacing(2) }}
        >
          {t('sharedAdd')}
        </Button>

        <div className={classes.buttons}>
          <Button color="primary" variant="outlined" onClick={returnBackOrCloseWindow} disabled={!!progress}>
            {t('sharedCancel')}
          </Button>
          <Button
            color="primary"
            variant={progress ? 'outlined' : 'contained'}
            onClick={handleSave}
            disabled={isSaveDisabled}
          >
            {progress ? <CircularProgress /> : t('sharedSave')}
          </Button>
        </div>
      </Container>

      <Dialog open={openDeleteDialog} onClose={handleDeleteClose}>
        <DialogTitle style={{ paddingBottom: '0' }}>{t('sharedRemoveConfirm')}</DialogTitle>
        <DialogActions className={classes.buttons}>
          <Button onClick={handleDeleteClose} color="primary" variant="outlined">
            {t('sharedNo')}
          </Button>
          <Button onClick={handleDeleteConfirm} color="primary" variant="contained">
            {t('sharedYes')}
          </Button>
        </DialogActions>
      </Dialog>

      <AddIconDialog
        openAddDialog={openAddDialog}
        setOpenAddDialog={setOpenAddDialog}
        handleNameChange={handleNameCheck}
        handleAddIcon={handleAddIcon}
        errors={errors}
        setErrors={setErrors}
        icons={icons}
      />

      <ChangeIconDialog
        openChangeDialog={openChangeDialog}
        setOpenChangeDialog={setOpenChangeDialog}
        setIcons={setIcons}
        handleIconUpdate={handleIconUpdate}
      />

    </OptionsLayout>
  );
}
