import React from 'react';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from './LocalizationProvider';
import EventSidePanel from './EventSidePanel';
import CustomizableBadge from './CustomizableBadge';
import { devicesActions, positionsActions } from '../../store';

const useStyles = makeStyles(() => ({
  trackingMode: {
    borderRadius: '10px',
    minWidth: 0,
    backgroundColor: 'rgba(255,255,255,0.8)',
    cursor: 'pointer',
  },
}));

const EventNotifications = ({ events, notificationsDataVisible, setNotificationsDataVisible }) => {
  const classes = useStyles();
  const t = useTranslation();
  const dispatch = useDispatch();

  const handleClick = () => {
    setNotificationsDataVisible(true);
    dispatch(positionsActions.changeTrackingMode(false));
    dispatch(positionsActions.needToShowDataPanel(false));
    dispatch(positionsActions.unselectPosition());
    dispatch(devicesActions.deselect(null));
  };

  return (
    <>
      <div className={classes.trackingMode}>
        <CustomizableBadge
          isActive={events.length > 0 || notificationsDataVisible}
          title={t('reportEvents')}
          icon={(<NotificationsNoneIcon />)}
          badgeContent={events.length}
          onClick={handleClick}
        />
      </div>
      {notificationsDataVisible && (
        <EventSidePanel setOpen={setNotificationsDataVisible} />
      )}
    </>
  );
};

export default EventNotifications;
