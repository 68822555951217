import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography,
  IconButton,
  useTheme,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CustomColorPicker from '../../../common/components/CustomColorPicker';
import { useTranslation } from '../../../common/components/LocalizationProvider';

export default function AddIconDialog({
  openAddDialog,
  setOpenAddDialog,
  handleNameChange,
  handleAddIcon,
  errors,
  setErrors,
  icons,
}) {
  const t = useTranslation();
  const theme = useTheme();
  const [newIcon, setNewIcon] = useState('');
  const [newName, setNewName] = useState(undefined);
  const [newColor, setNewColor] = useState('#000000');
  const [debouncedName, setDebouncedName] = useState(undefined);

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
    setNewIcon('');
    setNewName(undefined);
    setNewColor('#000000');
    setDebouncedName(undefined)

    setErrors((prevState) => ({
      ...prevState,
      newIcon: '',
    }));
  };

  function getUniqueIcons(data) {
    const icons = new Set();
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        icons.add(data[key].icon);
      }
    }

    return Array.from(icons).sort((iconA, iconB) => {
      const isMilitaryA = iconA.startsWith('военные');
      const isMilitaryB = iconB.startsWith('военные');

      if (isMilitaryA && !isMilitaryB) return 1;
      if (!isMilitaryA && isMilitaryB) return -1;

      if (iconA < iconB) return -1;
      if (iconA > iconB) return 1;

      return iconA.localeCompare(iconB);
    });
  }

  const handleIconSelect = (e) => {
    setErrors((prevState) => ({
      ...prevState,
      newIcon: '',
    }));

    const selectedIcon = e.target.value;
    setNewIcon(selectedIcon);

    let newIconName = `${selectedIcon}`;
    if (icons[newIconName]) {
      newIconName = `${selectedIcon} - ${t('iconsNew')}`;
    }

    let i = 2;
    while (icons[newIconName]) {
      newIconName = `${selectedIcon} - ${t('iconsNew')} (${i})`;
      i++;
    }
    setNewName(newIconName);
    handleNameChange('newIcon', newIcon, newIconName);
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedName(newName);
    }, 250);

    return () => {
      clearTimeout(handler);
    };
  }, [newName]);

  useEffect(() => {
    if (debouncedName !== undefined && newIcon !== undefined) {
      handleNameChange('newIcon', newIcon, debouncedName);
    }
  }, [debouncedName]);

  const onLocalChange = (e) => {
    setNewName(e.target.value);
  };

  return (
    <Dialog open={openAddDialog} onClose={handleCloseAddDialog} fullWidth maxWidth="xs">
      <DialogTitle>
        {t('sharedAdd')}
        <IconButton
          aria-label="close"
          onClick={handleCloseAddDialog}
          style={{ position: 'absolute', top: '10px', right: '10px' }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <FormControl fullWidth variant="filled" margin="none" required>
          <InputLabel>{t('settingsIcon')}</InputLabel>
          <Select
            fullWidth
            value={newIcon}
            onChange={handleIconSelect}
            renderValue={() => (
              <div style={{ display: 'flex', gap: theme.spacing(2) }}>
                <img src={`/images/icon/device/${newIcon}.svg`} alt="" />
                <Typography>{newIcon}</Typography>
              </div>
            )}
          >
            {getUniqueIcons(icons).map((icon) => (
              <MenuItem value={icon} key={icon}>
                <ListItemIcon>
                  <img src={`/images/icon/device/${icon}.svg`} alt="" />
                </ListItemIcon>
                <ListItemText>{icon}</ListItemText>
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          label={t('sharedName')}
          value={newName}
          onChange={onLocalChange}
          error={!!errors.newIcon}
          helperText={errors.newIcon || ''}
          fullWidth
          disabled={newIcon === ''}
          style={{ marginTop: 16 }}
        />

        <CustomColorPicker
          color={newColor}
          setColor={setNewColor}
          label={t('markerColor')}
          presetColors={theme.palette.markers}
          style={{ marginTop: 16 }}
        />
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => { handleAddIcon(newIcon, newName, newColor, handleCloseAddDialog); }}
          color="primary"
          variant="contained"
          fullWidth
          style={{ marginTop: 16 }}
          disabled={newIcon === '' || !!errors.newIcon}
        >
          {t('sharedSave')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
