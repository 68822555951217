import React, { useState } from 'react';
import { CircularProgress, Typography, makeStyles } from '@material-ui/core';
import 'react-calendar-timeline/lib/Timeline.css';
import { Button as DxButton } from 'devextreme-react/button';
import { Skeleton } from '@material-ui/lab';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import { isUndefinedOrNull } from '../../../common/utils/stringUtils';

const useStyles = makeStyles(() => ({
  noBorder: {
    borderRadius: 0,
  },
  border: {
    borderRadius: 8,
  },
  cameraBlockContainer: {
    position: 'relative',
    aspectRatio: '16 / 9',
  },
  video: {
    position: 'absolute',
    objectFit: 'contain',
    left: 0,
    bottom: 0,
    top: 0,
    right: 0,
    aspectRatio: '16 / 9',
    maxWidth: '100%',
    height: 'auto',
    width: '100%',
  },
  textContainer: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 2,
    height: '20%',
  },
  text: {
    margin: '0px 8px',
    display: 'flex',
    alignItems: 'flex-end',
    height: '100%',
    justifyContent: 'space-between',
    textShadow: 'rgb(0, 0, 0) 2px 2px 4px',
    color: 'white',
  },
  blackout: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    background: 'rgba(0, 0, 0, .35)',
    zIndex: 1,
  },
  blackArea: {
    backgroundColor: 'rgba(0, 0, 0, .80)',
  },
  preloader: {
    height: '100%',
    display: 'flex',
    gap: 10,
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    background: 'rgba(0, 0, 0, .50)',
    zIndex: 1,
  },
  videoPanelContainer: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 2,
  },
  videoPanel: {
    position: 'absolute',
    display: 'flex',
    padding: 5,
    gap: 10,
    zIndex: 3,
    right: 0,
    top: 0,
  },
  downloadContainer: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 1,
    height: '100%',
    gap: 10,
    color: 'white',
  },
  downloadIndicator: {
    color: 'white',
    width: 20,
    height: 20,
  },
  smallButton: {
    '& .dx-button-content': {
      padding: 6,
    },
  },
}));

const CameraBlock = ({
  cameraItem,
  inLineButtons = false, disableBorder = false, smallButtons = false,
  cameraBlockContainerStyle,
  onRemoveVideo, onPlayVideo, onSaveVideo,
}) => {
  const classes = useStyles();
  const t = useTranslation();

  const [isError, setIsError] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const [isMouseEnter, setIsMouseEnter] = useState(false);

  const isPropsUndefined = {
    MainText: isUndefinedOrNull(cameraItem.mainText),
    OnRemoveVideo: isUndefinedOrNull(onRemoveVideo),
    OnPlayVideo: isUndefinedOrNull(onPlayVideo),
    OnSaveVideo: isUndefinedOrNull(onSaveVideo),
  };

  const getBorderStyle = (mainClass) => `${mainClass} ${disableBorder ? classes.noBorder : classes.border}`;

  const handleMouseEnter = () => setIsMouseEnter(true);
  const handleMouseLeave = () => setIsMouseEnter(false);

  const handleRemoveVideo = (item) => {
    if (isPropsUndefined.OnRemoveVideo) {
      return;
    }
    onRemoveVideo(item);
    handleMouseLeave();
  };

  const handlePlayVideo = (item) => {
    if (isPropsUndefined.OnPlayVideo) {
      return;
    }
    onPlayVideo(item);
    handleMouseLeave();
  };

  const startDownload = () => setIsDownload(true);
  const endDownload = () => setIsDownload(false);

  const handleSaveVideo = (item) => {
    if (isPropsUndefined.OnSaveVideo) {
      return;
    }
    onSaveVideo(item, startDownload, endDownload);
    handleMouseLeave();
  };

  const handleError = () => setIsError(true);

  const SaveVideoButton = () => (
    <>
      {!isPropsUndefined.OnSaveVideo && (
        <DxButton
          className={smallButtons && classes.smallButton}
          disabled={isDownload}
          stylingMode="contained"
          icon="save"
          hint={t('sharedSave')}
          onClick={() => handleSaveVideo(cameraItem)}
        />
      )}
    </>
  );

  const PlayVideoButton = () => (
    <>
      {!isPropsUndefined.OnPlayVideo && (
        <DxButton
          className={smallButtons && classes.smallButton}
          stylingMode="contained"
          icon="video"
          hint={t('globalPlayBack')}
          width={inLineButtons ? undefined : 55}
          height={inLineButtons ? undefined : 45}
          onClick={() => handlePlayVideo(cameraItem)}
        />
      )}
    </>
  );

  const RemoveVideoButton = () => (
    <>
      {!isPropsUndefined.OnRemoveVideo && (
        <DxButton
          className={smallButtons && classes.smallButton}
          stylingMode="contained"
          icon="trash"
          hint={t('sharedRemove')}
          onClick={() => handleRemoveVideo(cameraItem)}
        />
      )}
    </>
  );

  const Text = () => (
    <>
      {!isPropsUndefined.MainText ? (
        <span>{cameraItem.mainText}</span>
      ) : (
        <>
          <span>{cameraItem.leftText}</span>
          <span>{cameraItem.rightText}</span>
        </>
      )}
    </>
  );

  if (cameraItem.inProcess) {
    return (
      <div
        style={cameraBlockContainerStyle && cameraBlockContainerStyle}
        className={getBorderStyle(classes.cameraBlockContainer)}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Skeleton className={classes.video} variant="rect" width="100%" height="100%" />

        {isMouseEnter && (
          <div className={classes.videoPanel}>
            <RemoveVideoButton />
          </div>
        )}

        <div className={getBorderStyle(classes.preloader)}>
          <CircularProgress style={{ color: 'white' }} />
          <Typography variant="h6">
            {t('globalProcessing')}
          </Typography>
        </div>

        <div className={classes.textContainer}>
          <div className={classes.text}>
            <Text />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      style={cameraBlockContainerStyle && cameraBlockContainerStyle}
      className={getBorderStyle(classes.cameraBlockContainer)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {isMouseEnter && (
        <div>
          <div className={getBorderStyle(classes.blackout)} />
          <div className={getBorderStyle(classes.videoPanelContainer)}>
            {inLineButtons ? (
              <div className={classes.videoPanel}>
                <SaveVideoButton />
                <PlayVideoButton />
                <RemoveVideoButton />
              </div>
            ) : (
              <>
                <div>
                  <PlayVideoButton />
                </div>
                <div className={classes.videoPanel}>
                  <SaveVideoButton />
                  <RemoveVideoButton />
                </div>
              </>
            )}
          </div>
        </div>
      )}

      {isDownload && (
        <div className={getBorderStyle(classes.downloadContainer)}>
          <CircularProgress className={classes.downloadIndicator} />
          <span>{t('globalConservation')}</span>
        </div>
      )}

      {isError ? (
        <div className={`${getBorderStyle(classes.blackout)} ${classes.blackArea}`} />
      ) : (
        <img
          loading="lazy"
          className={`${getBorderStyle(classes.video)} ${classes.blackArea}`}
          src={cameraItem.srcUrl}
          alt={cameraItem.srcUrl}
          onError={handleError}
        />
      )}

      <div className={classes.textContainer}>
        <div className={classes.text}>
          <Text />
        </div>
      </div>
    </div>
  );
};

export default CameraBlock;
