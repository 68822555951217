import React, { useEffect, useRef } from 'react';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import {
  Drawer, Paper, Tooltip, makeStyles,
} from '@material-ui/core';
import { useTranslation } from '../LocalizationProvider';
import { toTitleCase } from '../../utils/formatter';
import ComponentIcon from './icons/CommonIcon';
import ClusterIcon from './icons/ClusterIcon';
import TextIcon from './icons/TextIcon';
import scrollStyles from '../../theme/scrollStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: 300,
    [theme.breakpoints.down('xs')]: {
      paddingBottom: theme.spacing(2),
      maxHeight: '70%',
    },
    borderRadius: '7px 7px 0px 0px',
    ...scrollStyles(),
  },
  checkboxToggle: {
    borderRadius: '10px',
    backgroundColor: 'rgba(255,255,255,0.8)',
    width: 40,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(220,220,220,0.8)',
    },
  },
  toggleExtra: {
    borderRadius: '90px',
    minWidth: 0,
    backgroundColor: 'rgba(255,255,255,0.8)',
    transform: 'scale(0.7)',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(220,220,220,0.8)',
    },
  },
  avatar: {
    backgroundColor: 'rgba(255,255,255,0)',
  },
  indicators: {
    display: 'flex',
    alignItems: 'center',
  },
  itemText: {
    paddingRight: theme.spacing(6),
  },
  childrenItem: {
    paddingLeft: 30,
  },
  childrenButton: {
    borderRadius: '10px',
    backgroundColor: 'rgba(255,255,255,0.8)',
    cursor: 'pointer',
    width: 40,
    transform: 'scale(0.8)',
    '&:hover': {
      backgroundColor: 'rgba(220,220,220,0.8)',
    },
  },
}));

const ComponentDrawer = ({
  buttonsDrawerOpen, setButtonsDrawerOpen, panel, turnOnValue, setOnMouseEnter, onChildren,
  items, getButtonVisibleStatus, onMouseEnter, clickHandler, getClusterName, getTextName,
}) => {
  const t = useTranslation();
  const classes = useStyles();
  const childrenRef = useRef();

  useEffect(() => {
    if (onChildren && childrenRef.current) {
      childrenRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [onChildren]);

  return (
    <Drawer
      anchor="bottom"
      classes={{ paper: classes.root }}
      open={buttonsDrawerOpen}
      onClose={() => setButtonsDrawerOpen(false)}
    >
      <List>
        {items.filter((button) => getButtonVisibleStatus(button.title)).map((button) => (
          <div key={button.title}>
            <ListItem>
              <ListItemAvatar>
                <Paper
                  className={classes.checkboxToggle}
                  elevation={3}
                  onClick={() => clickHandler(button.title)}
                  onMouseEnter={() => setOnMouseEnter(button.title)}
                  onMouseLeave={() => setOnMouseEnter('')}
                >
                  <Avatar className={classes.avatar}>
                    <ComponentIcon
                      panel={panel}
                      value={button.title}
                      onMouseEnter={onMouseEnter}
                    />
                  </Avatar>
                </Paper>
              </ListItemAvatar>
              <ListItemText className={classes.itemText}>
                {t(`panel${toTitleCase(button.title)}`)}
              </ListItemText>
              <ListItemSecondaryAction className={classes.indicators}>
                {button.clusters && (
                  <Paper
                    className={classes.toggleExtra}
                    elevation={3}
                    onMouseEnter={() => setOnMouseEnter(`${toTitleCase(getClusterName(button))}`)}
                    onMouseLeave={() => setOnMouseEnter('')}
                    onClick={() => turnOnValue(`on${toTitleCase(getClusterName(button))}`)}
                  >
                    <Tooltip title={t(`panel${toTitleCase(getClusterName(button))}`)}>
                      <Avatar className={classes.avatar}>
                        <ClusterIcon panel={panel} value={getClusterName(button)} onMouseEnter={onMouseEnter} />
                      </Avatar>
                    </Tooltip>
                  </Paper>
                )}
                {button.text && (
                  <Paper
                    className={classes.toggleExtra}
                    elevation={3}
                    onClick={() => turnOnValue(`on${toTitleCase(getTextName(button))}`)}
                    onMouseEnter={() => setOnMouseEnter(`${toTitleCase(getTextName(button))}`)}
                    onMouseLeave={() => setOnMouseEnter('')}
                  >
                    <Tooltip title={t(`panel${toTitleCase(getTextName(button))}`)}>
                      <Avatar className={classes.avatar}>
                        <TextIcon panel={panel} value={getTextName(button)} onMouseEnter={onMouseEnter} />
                      </Avatar>
                    </Tooltip>
                  </Paper>
                )}
              </ListItemSecondaryAction>
            </ListItem>
            {onChildren === button.title && button.childrens?.map((child) => (
              <ListItem key={child.title} className={classes.childrenItem} ref={childrenRef}>
                <ListItemAvatar>
                  <Paper
                    className={classes.childrenButton}
                    elevation={3}
                    onClick={() => turnOnValue(`on${toTitleCase(button.title)}`, child.index)}
                    onMouseEnter={() => setOnMouseEnter(child.title)}
                    onMouseLeave={() => setOnMouseEnter('')}
                  >
                    <Avatar className={classes.avatar}>
                      <ComponentIcon
                        panel={panel}
                        value={child.title}
                        onMouseEnter={onMouseEnter}
                        parrent={button.title}
                        child={child.index}
                      />
                    </Avatar>
                  </Paper>
                </ListItemAvatar>
                <ListItemText className={classes.itemText}>
                  {t(`panel${toTitleCase(child.title)}`)}
                </ListItemText>
              </ListItem>
            ))}
          </div>
        ))}
      </List>
    </Drawer>
  );
};

export default ComponentDrawer;
