import fetchWithCluster from "./fetchWithCluster";
import fetchWithoutCluster from "./fetchWithoutCluster";

export default function handleFetchGeoJson(
  map,
  url,
  autoRefresh,
  extraMapId,
  signal,
  decrementGeoJsonCount,
  incrementGeoJsonCount,
  dispatch,
  onCluster,
  moveEndListenersRef
) {
  if (onCluster) {
    fetchWithCluster(map, url, autoRefresh, extraMapId, signal, decrementGeoJsonCount, incrementGeoJsonCount, dispatch)
      .then((listener) => {
        moveEndListenersRef.current[extraMapId] = listener;
      });
  } else {
    fetchWithoutCluster(map, url, autoRefresh, extraMapId, signal, decrementGeoJsonCount, incrementGeoJsonCount, dispatch);
  }
}