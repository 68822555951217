export default function removeGeojsonLayer(map, baseId, onClusterClick, onFeatureClick, onMouseEnter, onMouseLeave) {
  const layers = [
    `${baseId}-clusters`,
    `${baseId}-cluster-count`,
    `${baseId}-heatMap`,
    `${baseId}-circle`,
    `${baseId}-line`,
    `${baseId}-line-dashed`,
    `${baseId}-polygon`,
    `${baseId}-polygon3D`,
    `${baseId}-text`,
  ];

  map.off('click', `${baseId}-clusters`, onClusterClick);

  map.off('click', `${baseId}-circle`, onFeatureClick);
  map.off('click', `${baseId}-line`, onFeatureClick);
  map.off('click', `${baseId}-line-dashed`, onFeatureClick);
  map.off('click', `${baseId}-polygon`, onFeatureClick);
  map.off('click', `${baseId}-polygon3D`, onFeatureClick);

  map.off('mouseenter', `${baseId}-circle`, onMouseEnter);
  map.off('mouseleave', `${baseId}-circle`, onMouseLeave);
  map.off('mouseenter', `${baseId}-clusters`, onMouseEnter);
  map.off('mouseleave', `${baseId}-clusters`, onMouseLeave);
  map.off('mouseenter', `${baseId}-line`, onMouseEnter);
  map.off('mouseleave', `${baseId}-line`, onMouseLeave);
  map.off('mouseenter', `${baseId}-line-dashed`, onMouseEnter);
  map.off('mouseleave', `${baseId}-line-dashed`, onMouseLeave);
  map.off('mouseenter', `${baseId}-polygon`, onMouseEnter);
  map.off('mouseleave', `${baseId}-polygon`, onMouseLeave);
  map.off('mouseenter', `${baseId}-polygon3D`, onMouseEnter);
  map.off('mouseleave', `${baseId}-polygon3D`, onMouseLeave);

  layers.forEach((layerId) => {
    if (map.getLayer(layerId)) {
      map.removeLayer(layerId);
    }
  });
}
