import React from 'react';
import PropTypes from 'prop-types';
import {
  Badge, Paper, Tooltip, makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 0,
    cursor: 'pointer',
    borderRadius: '10px',
    backgroundColor: 'rgba(247, 251, 247, 0.8)',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: 'transparent',
  },
  iconColor: {
    color: theme.palette.primary.main,
  },
  iconActiveColor: {
    color: '#007FFF',
  },
}));

const CustomizableBadge = ({
  title, isActive, color, icon, onClick, badgeContent,
}) => {
  const classes = useStyles();

  const getIconClass = () => (isActive ? classes.iconActiveColor : classes.iconColor);

  return (
    <Tooltip title={title}>
      <Paper className={classes.root} elevation={3} onClick={onClick}>
        <Badge
          className={`${classes.avatar} ${getIconClass()}`}
          badgeContent={badgeContent}
          color={color ?? 'primary'}
        >
          {icon}
        </Badge>
      </Paper>
    </Tooltip>
  );
};

CustomizableBadge.propTypes = {
  icon: PropTypes.element.isRequired,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  badgeContent: PropTypes.any,
  title: PropTypes.string,
  color: PropTypes.string,
};

export default CustomizableBadge;
