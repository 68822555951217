/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-restricted-globals */
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { ButtonGroup, Resizable } from 'devextreme-react';
import { useMovable } from 'react-move-hook';
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { isUndefinedOrNull } from '../utils/stringUtils';
import scrollStyles from '../theme/scrollStyles';

const useStyles = makeStyles(() => ({
  container: {
    border: '1px solid #ddd',
    borderRadius: '6px',
    display: 'flex',
    flexDirection: 'column',
    // position: 'absolute',
    position: 'fixed',
    background: 'white',
  },
  header: {
    borderBottom: '1px solid #ddd',
    height: '50px',
    padding: '6px 6px 6px 20px',
    display: 'flex',
  },
  body: {
    flex: 1,
    padding: 15,
    margin: 5,
    // overflow: 'hidden',
    overflow: 'auto',
    zIndex: 1505,
    ...scrollStyles(5),
  },
  disableBodyPadding: {
    padding: 0,
  },
  movePlace: {
    cursor: 'move',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  },
  text: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

const FreeModalWindow = ({
  style,
  children, buttons = [], title,
  defaultWidth = 650, defaultHeight = 600, defaultPosition, setNewDefaultPosition,
  enableFullscreenMode, enableResizable, enableScroll, disableBodyPadding,
  onCloseModalWindow,
  onMouseDown,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const sizeRef = useRef();
  const prevPosition = useRef(undefined);
  const prevSizeWindow = useRef(undefined);

  const getScreenPosition = () => ({
    x: (window.innerWidth / 2) - (defaultWidth / 2),
    y: (window.innerHeight / 2) - (defaultHeight / 2),
  });

  const [sizeWindow, setSizeWindow] = useState({ width: defaultWidth, height: defaultHeight });

  const adjustWindowPosition = (position) => {
    if (position) {
      const { x, y } = position;
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;

      let adjustedX = x;
      let adjustedY = y;

      if (x < 0) adjustedX = 0;
      if (y < 0) adjustedY = 0;
      if (x + sizeWindow.width > screenWidth) adjustedX = screenWidth - sizeWindow.width;
      if (y + screenWidth.height > screenHeight) adjustedY = screenHeight - screenWidth.height;

      return { x: adjustedX, y: adjustedY };
    }
    return undefined;
  };

  const [isFullscreenMode, setIsFullscreenMode] = useState(false);
  const [positionData, setPositionData] = useState(() => ({
    moving: false,
    position: adjustWindowPosition(defaultPosition) || getScreenPosition(),
    delta: undefined,
  }));

  const notAvailableFreeSpaceOnScreen = isMobile || isFullscreenMode;
  const isAvailableResizable = !notAvailableFreeSpaceOnScreen && enableResizable;

  const switchScreenMode = () => {
    setIsFullscreenMode((prev) => !prev);
  };

  useEffect(() => {
    const handleResize = () => {
      if (!isMobile) {
        const newPos = adjustWindowPosition(positionData.position);
        setPositionData((prev) => ({ ...prev, position: newPos }));
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [positionData.position]);

  useEffect(() => {
    if (notAvailableFreeSpaceOnScreen) return;
    prevPosition.current = { ...positionData.position };
  }, [positionData.position]);

  useEffect(() => {
    if (notAvailableFreeSpaceOnScreen) return;
    prevSizeWindow.current = { ...sizeWindow };
  }, [sizeWindow]);

  const keyPress = (e) => {
    if (e.key === 'Escape') {
      setIsFullscreenMode(false);
    }
  };

  useEffect(() => {
    if (!enableFullscreenMode) return;
    document.body.addEventListener('keydown', keyPress);
    // eslint-disable-next-line consistent-return
    return () => document.body.removeEventListener('keydown', keyPress);
  }, []);

  useEffect(() => {
    if (isMobile) {
      setSizeWindow({ width: '100vw', height: '100vh' });
    } else {
      setSizeWindow({ width: defaultWidth, height: defaultHeight });
      setPositionData((prev) => ({ ...prev, position: getScreenPosition() }));
    }
  }, [isMobile]);

  // Определяет позицию на экране при переключении на режим полный экран и обратно
  useEffect(() => {
    setPositionData((state) => {
      let position;
      // let sizeWindow;

      if (notAvailableFreeSpaceOnScreen) {
        position = { x: 0, y: 0 };
        // sizeWindow = { width: '100vw', height: '100vh' };
      } else if (isUndefinedOrNull(prevPosition.current)) {
        position = adjustWindowPosition(defaultPosition) || getScreenPosition();
      } else {
        position = prevPosition.current;
        // sizeWindow = prevSizeWindow.current;
      }

      // setSizeWindow({ ...sizeWindow });

      return ({
        ...state,
        position: { ...position },
      });
    });
  }, [isFullscreenMode, isMobile]);

  // Определяет позицию на экране при переключении на режим полный экран и обратно
  useEffect(() => {
    setSizeWindow(() => {
      let sizeWindow;

      if (notAvailableFreeSpaceOnScreen) {
        sizeWindow = { width: '100vw', height: '100vh' };
      } else {
        sizeWindow = prevSizeWindow.current;
      }

      return ({ ...sizeWindow });
    });
  }, [isFullscreenMode]);

  const getButtons = () => {
    const buttonsList = buttons.slice();

    if (enableFullscreenMode && !isMobile) {
      buttonsList.push({
        icon: 'fullscreen',
        onClick: switchScreenMode,
      });
    }

    buttonsList.push({
      icon: 'close',
      onClick: onCloseModalWindow,
    });

    return buttonsList;
  };

  const handleMoveChange = useCallback((moveData) => {
    setPositionData((state) => {
      const newPosition = moveData.stoppedMoving
        ? {
          ...state.position,
          x: state.position.x + moveData.delta.x,
          y: state.position.y + moveData.delta.y,
        }
        : state.position;

      if (moveData.stoppedMoving) {
        if (setNewDefaultPosition) {
          setNewDefaultPosition(newPosition);
        }
      }

      return {
        moving: moveData.moving,
        position: newPosition,
        delta: moveData.moving ? moveData.delta : undefined,
      };
    });
  }, []);

  const containerStyle = {
    minWidth: 200,
    minHeight: 200,
    height: sizeWindow.height,
    width: sizeWindow.width,
    zIndex: 1501,
    left: positionData.position.x,
    top: positionData.position.y,
    transform: positionData.delta
      ? `translate3d(${positionData.delta.x}px, ${positionData.delta.y}px, 0)`
      : undefined,
  };

  const ref = useMovable({
    onChange: handleMoveChange,
    bounds: 'parent',
    sizeRef,
  });

  const handleChangePosition = useCallback((e) => {
    try {
      e.element.style.transform = 'none';

      const rect = e.element.getBoundingClientRect();
      const isTop = rect.top < 0;
      const isLeft = rect.left < 0;
      const isBottom = rect.bottom > (window.innerHeight || document.documentElement.clientHeight);
      const isRight = rect.right > (window.innerWidth || document.documentElement.clientWidth);

      if (![isTop, isLeft, isBottom, isRight].some((v) => v)) {
        return;
      }

      setPositionData((prev) => {
        let position;

        if (isTop) {
          position = { x: prev.position.x, y: 0 };
        } else if (isLeft) {
          position = { x: 0, y: prev.position.y };
        } else if (isBottom) {
          position = { x: prev.position.x, y: window.innerHeight - prevSizeWindow.current.height };
        } else if (isRight) {
          position = { x: window.innerWidth - prevSizeWindow.current.width, y: prev.position.y };
        }

        return ({
          ...prev,
          position: { ...position },
        });
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleResizable = (e) => {
    try {
      const isRightAndBottomSide = (e.handles.right && !e.handles.top)
        || (e.handles.bottom && e.handles.right)
        || (e.handles.bottom && !e.handles.left)
        || (e.handles.right && e.handles.bottom);
      if (isRightAndBottomSide) {
        setSizeWindow(() => ({ width: e.width, height: e.height }));
      } else {
        setSizeWindow(() => ({ width: e.width, height: e.height }));
        setPositionData((prev) => ({
          ...prev,
          position: {
            x: prev.position.x + (sizeWindow.width - e.width),
            y: prev.position.y + (sizeWindow.height - e.height),
          },
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      ref={sizeRef}
      className={`${classes.container} no-select`}
      style={Object.assign(containerStyle, style)}
    >
      <div className={classes.header}>
        <div
          ref={ref}
          className={classes.movePlace}
          onMouseDown={onMouseDown}
        >
          <div className={classes.text}>
            {title && (title)}
          </div>
        </div>
        <div>
          <ButtonGroup items={getButtons()} keyExpr="icon" stylingMode="text" />
        </div>
      </div>

      <div
        className={`${classes.body} ${enableScroll && classes.enableScroll} ${disableBodyPadding && classes.disableBodyPadding}`}
        onMouseDown={onMouseDown}
      >
        {children}
      </div>

      {isAvailableResizable && (
        <div>
          <Resizable
            style={{
              ...containerStyle,
              position: 'fixed',
              // background: '#80808045',
              zIndex: 1503,
              clipPath: 'polygon(0% 0%, 0% 100%, 1% 100%, 1% 1%, 99% 1%, 99% 99%, -200% 99%, 25% 100%, 100% 100%, 100% 0%)',
            }}
            keepAspectRatio
            handles={['top', 'left', 'right', 'bottom'].join(' ')}
            onResize={handleResizable}
            onResizeEnd={handleChangePosition}
          >
            <div />
          </Resizable>
        </div>
      )}
    </div>
  );
};

export default FreeModalWindow;
