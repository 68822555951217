import React from 'react';
import {
  TextField, makeStyles,
} from '@material-ui/core';
import 'react-calendar-timeline/lib/Timeline.css';
import { useTranslation } from '../../../common/components/LocalizationProvider';

const useStyles = makeStyles(() => ({
  container: {
    marginBottom: 10,
    display: 'flex',
    gap: 10,
  },
  item: {
    flex: 1,
  },
  searchItem: {
    flex: 2,
  },
}));

const FiltersPanel = ({
  searchFilter, setSearchFilter,
}) => {
  const classes = useStyles();
  const t = useTranslation();

  return (
    <div className={classes.container}>
      <div className={classes.searchItem}>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          label={t('sharedSearch')}
          value={searchFilter}
          onChange={(e) => setSearchFilter(e.target.value.toLowerCase())}
        />
      </div>
    </div>
  );
};

export default FiltersPanel;
