import React, { useState } from 'react';
import {
  makeStyles, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Paper, Typography, Card, CardContent, Chip,
  useTheme,
  Box,
  LinearProgress,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import moment from 'moment';
import 'react-calendar-timeline/lib/Timeline.css';
import scrollStyles from '../../common/theme/scrollStyles';
import { useTranslation } from '../../common/components/LocalizationProvider';
import { toTitleCase } from '../../common/utils/formatter';
import { useEffectAsync } from '../../common/utils/reactHelper';
import OptionsLayout from './OptionsLayout';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    overflowY: 'auto',
    margin: 5,
    padding: 3,
    ...scrollStyles(3),
  },
  card: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  linear: {
    height: 20,
  },
  chip: {
    marginRight: theme.spacing(1),
  },
  table: {
    marginTop: theme.spacing(2),
  },
  progressBarError: {
    '& .MuiLinearProgress-bar': {
      backgroundColor: theme.palette.common.red,
    },
  },
}));

const SystemInfoPage = () => {
  const classes = useStyles();
  const theme = useTheme();
  const t = useTranslation();
  const servicesVersions = useSelector((state) => state.session.servicesVersions);

  const [activeUsers, setActiveUsers] = useState([]);
  const [limits, setLimits] = useState({});
  const [dateExpiration, setDataExpiration] = useState('');
  const [availableCats, setAvailableCats] = useState([]);

  const [isLoadingActiveUsers, setIsLoadingActiveUsers] = useState(true);
  const [isLoadingLimits, setIsLoadingLimits] = useState(true);
  const [isLoadingDateExpiration, setIsLoadingDateExpiration] = useState(true);
  const [isLoadingAvailableCats, setIsLoadingAvailableCats] = useState(true);
  const [version, setVersion] = useState(servicesVersions);

useEffectAsync(async () => {
  const response = await fetch('/api/reports/active-sessions');
  if (response.ok) {
    const data = await response.json();
    setActiveUsers(data);
  }
  setIsLoadingActiveUsers(false);
}, []);

useEffectAsync(async () => {
  const response = await fetch('/api/server/restrictions');
  if (response.ok) {
    const data = await response.json();
    setLimits(data);
  }
  setIsLoadingLimits(false);
}, []);

useEffectAsync(async () => {
  const response = await fetch('/api/server/date_expiration');
  if (response.ok) {
    const data = await response.text();
    const formattedDate = moment(data).locale('ru').format('D MMMM YYYY');
    const yearsLeft = moment(data).diff(moment(), 'years');
    if (yearsLeft >= 10) {
      setDataExpiration(`${t('systemInfoIndefinitely')}`);
    } else {
      const daysLeft = moment(data).diff(moment(), 'days');
      setDataExpiration(`${formattedDate} (осталось ${daysLeft} дней)`);
    }
  }
  setIsLoadingDateExpiration(false);
}, []);

useEffectAsync(async () => {
  const response = await fetch('/api/devices/categories');
  if (response.ok) {
    const data = await response.json();
    setAvailableCats(data);
  }
  setIsLoadingAvailableCats(false);
}, []);

useEffectAsync(async () => {
  const response = await fetch('/version.txt');
  if (response.ok) {
    const data = await response.text();
    setVersion(data);
  }
}, []);

const getProgressClass = (value, limit) => {
  if (limit === -1) return 'primary';
  const percentage = (value / limit) * 100;
  return percentage > 80 ? classes.progressBarError : 'primary';
};

const showIPs = (value) => {
  let result = value.replace(/[\[\]]/g, '');
  result = result.replace(/unknown/g, t('systemInfoIPAddressUnknown'));

  return result;
}

function removeQuotes(str) {
  if (typeof str !== 'string') {
    return str;
  }
  return str.replace(/['"]/g, '');
}

return (
  <OptionsLayout>
    <div className={classes.container}>

      {/* Информация по активным сессиям */}
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h6" gutterBottom>{t('systemInfoActiveSessions')}</Typography>
          {isLoadingActiveUsers ? (
            <Skeleton variant="rect" height={200} />
          ) : (
            <TableContainer component={Paper} className={classes.table}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell><strong>{t('systemInfoUserName')}</strong></TableCell>
                    <TableCell><strong>{t('systemInfoUserId')}</strong></TableCell>
                    <TableCell><strong>{t('systemInfoIPAddress')}</strong></TableCell>
                    <TableCell><strong>{t('systemInfoIPCount')}</strong></TableCell>
                    <TableCell style={{ paddingLeft: '24px' }}><strong>{t('systemInfoSessionStatus')}</strong></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {activeUsers.map((user, index) => (
                    <TableRow key={`table_${index + 1}`}>
                      <TableCell>{user.userName}</TableCell>
                      <TableCell>{user.userId}</TableCell>
                      <TableCell>{showIPs(user.remoteAddress)}</TableCell>
                      <TableCell>{user.count}</TableCell>

                      <TableCell>
                        <Chip
                          label={user.isOpen ? t('systemInfoSessionStatusOpen') : t('systemInfoSessionStatusClose')}
                          style={{ backgroundColor: user.isOpen ? theme.palette.common.green : theme.palette.common.red }}
                          className={classes.chip}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </CardContent>
      </Card>

      {/* Ограничения пользователей и устройств */}
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            {t('systemInfoLimits')}
          </Typography>
          {isLoadingLimits ? (
            <Box>
              <Skeleton width="60%" />
              <Skeleton variant="rect" height={20} />

              <Skeleton width="60%" style={{ marginTop: 10 }} />
              <Skeleton variant="rect" height={20} />
            </Box>
          ) : (
            <>
              <Box>
                <Box>
                  <Typography>
                    <strong>
                      {t('systemInfoUserLimit')}:
                    </strong>
                    {' '}
                    {limits.clientUsed}/{limits.clientLimit === -1
                      ? <strong>∞</strong>
                      : limits.clientLimit}
                  </Typography>
                  <LinearProgress
                    className={`${classes.linear} ${getProgressClass(limits.clientUsed, limits.clientLimit)}`}
                    variant="determinate"
                    value={limits.clientLimit === -1
                      ? 0
                      : (limits.clientUsed / limits.clientLimit) * 100}
                  />
                </Box>
                <Box style={{ marginTop: 10 }}>
                  <Typography>
                    <strong>
                      {t('systemInfoDeviceLimit')}:
                    </strong>
                    {' '}
                    {limits.deviceUsed}/{limits.deviceLimit === -1
                      ? <strong>∞</strong>
                      : limits.deviceLimit}
                  </Typography>
                  <LinearProgress
                    className={`${classes.linear} ${getProgressClass(limits.deviceUsed, limits.deviceLimit)}`}
                    variant="determinate"
                    value={limits.deviceLimit === -1
                      ? 0
                      : (limits.deviceUsed / limits.deviceLimit) * 100}
                  />
                </Box>
              </Box>
            </>
          )}
        </CardContent>
      </Card>

      {/* Срок действия лицензии */}
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h6" gutterBottom>{t('systemInfoLicenseExpiration')}</Typography>
          {isLoadingDateExpiration ? (
            <Skeleton width="80%" />
          ) : (
            <Typography>{dateExpiration}</Typography>
          )}
        </CardContent>
      </Card>

      {/* Поддерживаемые категории устройств */}
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h6" gutterBottom>{t('systemInfoAvalibleCats')}</Typography>
          {isLoadingAvailableCats ? (
            <Skeleton width="50%" />
          ) : (
            <div>
              {availableCats.map((device, index) => (
                <Chip
                  key={`avalibleKeys_${index + 1}`}
                  label={t(`deviceCategories${toTitleCase(device)}`)}
                  color="primary"
                  className={classes.chip}
                />
              ))}
            </div>
          )}
        </CardContent>
      </Card>

      {/* Информация о версиях */}
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h6" gutterBottom>{t('systemInfoVersion')}</Typography>
          <div>
            <Typography >
              <strong>web: </strong>
              {`${version}`}
            </Typography>
            <Typography >
              <strong>server: </strong>
              {`${servicesVersions.server}`}
            </Typography>
            {servicesVersions.bscoder && (
              <Typography >
                <strong>bscoder: </strong>
                {`${removeQuotes(servicesVersions.bscoder)}`}
              </Typography>
            )}
          </div>
        </CardContent>
      </Card>
    </div>
  </OptionsLayout>
);
};

export default SystemInfoPage;
