/* eslint-disable no-case-declarations */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/alt-text */
import React, { memo, useState } from 'react';
import { LinearProgress, makeStyles } from '@material-ui/core';
import { isUndefinedOrNull } from '../utils/stringUtils';
import DisplayFileContentByUrl from './DisplayFileContentByUrl';
import { useTranslation } from './LocalizationProvider';
import { useEffectAsync } from '../utils/reactHelper';
import { getExtensionFromFileName } from '../utils/fileHelper';

const useStyles = makeStyles(() => ({
  mainContainer: {
    height: '100%',
    width: '100%',
  },
  docViewerContainer: {
    height: '100%',
    width: '100%',
  },
  defaultContainer: {
    // overflow: 'auto',
    // height: '100%',
    // width: '100%',
    // padding: 5,
    whiteSpace: 'break-spaces',
  },
}));

const supportedFileExtensions = {
  image: ['png', 'jpg', 'gif', 'svg', 'bmp', 'bmp ico', 'png ico', 'apng'],
  video: ['mp4', 'mov', 'webm', 'ogg', 'ogv', 'oga', 'ogx', 'avi', 'mkv'],
  audio: ['wav', 'mp3', 'mpeg', 'acc', 'aacp', 'x-caf', 'flac'],
  // doc: ['pdf', 'csv', 'txt', 'html', 'tiff', 'docx', 'msg', 'doc', 'htm', 'ppt', 'pptx', 'xls', 'xlsx'],
  // doc: ['pdf'],
  docDatagrid: ['xls', 'xlsx', 'csv'],
  text: ['txt', 'text', 'json', 'sql', 'js', 'css', 'html', 'log', 'cs', 'java', 'python', 'go'],
};

const fullscreenStyle = {
  height: '100%',
  width: '100%',
  objectFit: 'contain',
};

const PreviewFileWindow = ({
  path,
}) => {
  const classes = useStyles();
  const t = useTranslation();

  const [component, setComponent] = useState(<LinearProgress />);

  if (isUndefinedOrNull(path)) {
    throw new Error('The path is not specified');
  }

  useEffectAsync(async () => {
    try {
      const fileExtension = getExtensionFromFileName(path);
      let fileType = '';

      for (const [name, exts] of Object.entries(supportedFileExtensions)) {
        if (exts.includes(fileExtension)) {
          fileType = name;
          break;
        }
      }

      const handleError = () => setComponent(<div>{t('globalFailedToUpload')}</div>);

      switch (fileType) {
        case 'image':
          setComponent((<img style={fullscreenStyle} src={path} onError={handleError} />));
          break;

        case 'video':
          setComponent((<video controls muted autoPlay style={fullscreenStyle} src={path} onError={handleError} />));
          break;

        case 'audio':
          setComponent((<audio controls src={path} onError={handleError} />));
          break;

        case 'docDatagrid':
          setComponent((<DisplayFileContentByUrl path={path} />));
          break;

        case 'text':
          let text = t('globalFailedToUpload');
          try {
            const response = await fetch(path);
            if (response.ok) {
              text = await response.text();
            }
          } catch (error) {
            console.log(error);
          }
          setComponent((<div className={classes.defaultContainer}>{text}</div>));
          break;

        default:
          setComponent((<div>{t('globalFormatNotSupport')}</div>));
          break;
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <div className={classes.mainContainer}>
      {component}
    </div>
  );
};

export default memo(PreviewFileWindow);
