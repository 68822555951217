import React, { useRef } from 'react';
import Timeline, {
  DateHeader, SidebarHeader, TimelineHeaders,
} from 'react-calendar-timeline';
import 'react-calendar-timeline/lib/Timeline.css';
import 'moment/locale/ru';
import { makeStyles } from '@material-ui/core';
import { DELIMITER } from './constants';

const useStyles = makeStyles(() => ({
  itemDefaultColor: {
    background: '#dbdbdb !important',
    border: '1px solid #9e9e9e !important',
    color: 'black !important',
    overflow: 'hidden',
  },
  itemSelectedColor: {
    background: '#4272f3 !important',
    border: '1px solid #1a46bb !important',
    overflow: 'hidden',
  },
}));

const CameraTimeline = ({
  camerasDataset, camerasMetadata, groups, timeline, setVideoData,
}) => {
  const classes = useStyles();

  const selectedInterval = useRef('');

  return (
    <Timeline
      stackItems
      showCursorLine
      // lineHeight={60}
      canMove={false}
      canResize={false}
      items={Object.values(camerasDataset).flat()}
      groups={groups}
      defaultTimeStart={timeline.from.toDate()}
      defaultTimeEnd={timeline.to.toDate()}
      itemRenderer={({ item, getItemProps }) => {
        const itemColor = item.id === selectedInterval.current ? classes.itemSelectedColor : classes.itemDefaultColor;
        return (
          <div {...getItemProps()} className={`rct-item ${item.className} ${itemColor}`}>
            {item.title}
          </div>
        );
      }}
      onItemClick={(itemId) => {
        if (camerasDataset.length === 0) {
          return;
        }

        const [cameraId] = itemId.split(DELIMITER);
        const { cameraName, videoRecordService } = camerasMetadata[cameraId];
        for (const data of camerasDataset[cameraId]) {
          if (data.id === itemId) {
            const {
              id, startTimeUnix, endTimeUnix, label,
            } = data;

            selectedInterval.current = id;
            setVideoData({
              url: `${videoRecordService}/vod/${cameraName}/start/${startTimeUnix}/end/${endTimeUnix}/master.m3u8`,
              // eslint-disable-next-line object-shorthand
              label: label,
            });
            break;
          }
        }
      }}
    >
      <TimelineHeaders>
        <SidebarHeader>
          {({ getRootProps }) => (<div className="custom-date-header" {...getRootProps()} />)}
        </SidebarHeader>

        <DateHeader className="custom-date-header" unit="primaryHeader" />
        <DateHeader />
      </TimelineHeaders>
    </Timeline>
  );
};

export default CameraTimeline;
