import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PostAddIcon from '@material-ui/icons/PostAdd';
import {
  Accordion, AccordionDetails, AccordionSummary, FormControl, IconButton,
  InputAdornment, InputLabel, MenuItem, Select, TextField, Tooltip, Typography, makeStyles,
} from '@material-ui/core';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import { getAttr, setAttr } from '../../../common/utils/formatter';

const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
  adornmentButton: {
    padding: 0,
  },
}));

const PreferencesBlock = ({
  item, setItem,
}) => {
  const t = useTranslation();
  const classes = useStyles();

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="subtitle1">
          {t('sharedPreferences')}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        <TextField
          label={t('settingsServerSiteName')}
          margin="normal"
          variant="filled"
          value={getAttr(item, 'websiteName')}
          onChange={(e) => setAttr(setItem, 'websiteName', e.target.value)}
        />
        <TextField
          label={t('webUrlLabel')}
          margin="normal"
          value={item.webUrl || ''}
          onChange={(event) => setItem({ ...item, webUrl: event.target.value })}
          placeholder="http://your-web-address"
          variant="filled"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title={`${t('insertWebUrlTitle')}: ${window.location.origin}`}>
                  <IconButton
                    className={classes.adornmentButton}
                    onClick={() => setItem({ ...item, webUrl: window.location.origin })}
                  >
                    <PostAddIcon />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
        <FormControl variant="filled" fullWidth margin="normal">
          <InputLabel>{t('settingsSpeedUnit')}</InputLabel>
          <Select
            value={getAttr(item, 'speedUnit') ?? 'kn'}
            onChange={(e) => setAttr(setItem, 'speedUnit', e.target.value)}
          >
            <MenuItem value="kmh">{t('sharedKmh')}</MenuItem>
            <MenuItem value="mps">{t('sharedMps')}</MenuItem>
            <MenuItem value="mph">{t('sharedMph')}</MenuItem>
            <MenuItem value="kn">{t('sharedKn')}</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="filled" fullWidth margin="normal">
          <InputLabel>{t('settingsCoordinateFormat')}</InputLabel>
          <Select
            label={t('settingsCoordinateFormat')}
            value={item.coordinateFormat || 'dd'}
            onChange={(event) => setItem({ ...item, coordinateFormat: event.target.value })}
          >
            <MenuItem value="dd">{t('sharedDecimalDegrees')}</MenuItem>
            <MenuItem value="ddm">{t('sharedDegreesDecimalMinutes')}</MenuItem>
            <MenuItem value="dms">{t('sharedDegreesMinutesSeconds')}</MenuItem>
          </Select>
        </FormControl>
      </AccordionDetails>
    </Accordion>
  );
};

export default PreferencesBlock;
