import React from 'react';
import {
  Typography, makeStyles,
} from '@material-ui/core';
import 'react-calendar-timeline/lib/Timeline.css';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import scrollStyles from '../../../common/theme/scrollStyles';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    overflowY: 'auto',
    margin: 5,
    padding: 3,
    ...scrollStyles(6),
  },
  cameraElementsGridFull: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    },
    gap: 5,
  },
  cameraElementsGridHorizontal: {
    display: 'flex',
    gap: 5,
    width: '100%',
    height: '100%',
  },
  noData: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export const gridModeEnum = Object.freeze({
  Full: 'full',
  Horizontal: 'horizontal',
});

const CameraBlocksContainer = ({
  isNoData = false,
  gridMode = gridModeEnum.Full,
  containerStyle, gridStyle,
  containerClass, gridClass,
  filtersComponent, children,
}) => {
  const classes = useStyles();
  const t = useTranslation();

  const getClass = (cls) => cls.join(' ');

  const gridClasses = gridMode === gridModeEnum.Full ? classes.cameraElementsGridFull : classes.cameraElementsGridHorizontal;

  return (
    <div style={containerStyle && containerStyle} className={getClass([classes.container, containerClass])}>
      {isNoData ? (
        <div className={classes.noData}>
          <Typography variant="h6">{t('globalNoDataToDisplay')}</Typography>
        </div>
      ) : (
        <>
          {filtersComponent && (<>{filtersComponent}</>)}

          <div
            style={gridStyle && gridStyle}
            className={getClass([gridClasses, gridClass])}
          >
            {children}
          </div>
        </>
      )}
    </div>
  );
};

export default CameraBlocksContainer;
