import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';
import moment from 'moment';
import 'react-calendar-timeline/lib/Timeline.css';
import {
  FormControl, InputLabel, MenuItem, Select, TextField, makeStyles, useTheme,
} from '@material-ui/core';
import { useTranslation } from './LocalizationProvider';
import { minutesToMilliseconds } from '../utils/datetimeHelper';
import { isUndefinedOrNull } from '../utils/stringUtils';

const useStyles = makeStyles(() => ({
  datetimeContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    width: '100%',
  },
  column: {
    flexDirection: 'column',
  },
  inLine: {
    display: 'flex',
    gap: 10,
  },
  datetimeLabel: {
    backgroundColor: 'white',
    // padding: '0 2px',
  },
}));

export const periodsEnum = Object.freeze({
  CurrentHour: 'currentHour',
  PreviousHour: 'previousHour',
  Today: 'today',
  Yesterday: 'yesterday',
  ThisWeek: 'thisWeek',
  PreviousWeek: 'previousWeek',
  ThisMonth: 'thisMonth',
  PreviousMonth: 'previousMonth',
  Custom: 'custom',
});

export const periodRangeDisplayedEnum = Object.freeze({
  Months: 'months',
  Weeks: 'weeks',
  Days: 'days',
  Hours: 'hours',
});

export const periodRangeDisplayedDefault = [
  periodRangeDisplayedEnum.Weeks,
  periodRangeDisplayedEnum.Days,
  periodRangeDisplayedEnum.Months,
];

const datetimeEnum = Object.freeze({
  From: 'from',
  To: 'to',
});

/**
 * Компонент содержит набор готовых периодов, и позволяя установить собственный диапазон дат и времени.
 */
const DatetimeCombobox = ({
  disabled, column = false, inLine = false,
  defaultPeriod = '',
  variant = 'filled', size = 'medium',
  periodRangeDisplayed = periodRangeDisplayedDefault,
  datetimeTimer, timerDelay = minutesToMilliseconds(1),
  onChangeDatetime,
}) => {
  const t = useTranslation();
  const classes = useStyles();
  const theme = useTheme();

  const [period, setPeriod] = useState(defaultPeriod);
  const selectedPeriod = useRef(period);

  const defineDatetime = useCallback((period) => {
    const dateFns = {
      currentHour: () => [moment().startOf('hour'), moment().endOf('hour')],
      previousHour: () => [moment().subtract(1, 'hour').startOf('hour'), moment().subtract(1, 'hour').endOf('hour')],
      today: () => [moment().startOf('day'), moment().endOf('day')],
      yesterday: () => [moment().subtract(1, 'day').startOf('day'), moment().subtract(1, 'day').endOf('day')],
      thisWeek: () => [moment().startOf('week'), moment().endOf('week')],
      previousWeek: () => [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
      thisMonth: () => [moment().startOf('month'), moment().endOf('month')],
      previousMonth: () => [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    };

    const [selectedFrom, selectedTo] = dateFns[period] ? dateFns[period]() : [null, null];

    return {
      [datetimeEnum.From]: selectedFrom,
      [datetimeEnum.To]: selectedTo,
    };
  }, []);

  const [selectedDatetime, setSelectedDatetime] = useState(defineDatetime(period));

  useEffect(() => {
    if (isUndefinedOrNull(datetimeTimer)) {
      return;
    }

    const timer = () => {
      const { from, to } = defineDatetime(selectedPeriod.current);
      datetimeTimer(from, to);
    };
    const id = setInterval(timer, timerDelay);
    // eslint-disable-next-line consistent-return
    return () => clearInterval(id);
  }, []);

  useEffect(() => {
    selectedPeriod.current = period;

    if (onChangeDatetime && period !== periodsEnum.Custom) {
      const { from, to } = defineDatetime(period);
      onChangeDatetime(from, to);
    }
  }, [period]);

  useEffect(() => {
    if (onChangeDatetime) {
      onChangeDatetime(selectedDatetime[datetimeEnum.From], selectedDatetime[datetimeEnum.To]);
    }
  }, [selectedDatetime]);

  const handleChangePeriod = (event) => {
    const { value } = event.target;
    setPeriod(value);
  };

  const handleChangeDatetime = (event) => {
    const { name, value } = event.target;
    const newDatetime = moment(value, moment.HTML5_FMT.DATETIME_LOCAL);
    const newSelectedDatetime = { ...selectedDatetime };
    newSelectedDatetime[name] = newDatetime;
    setSelectedDatetime(newSelectedDatetime);
  };

  const periodsMenusItems = () => {
    const items = [];

    if (periodRangeDisplayed.includes(periodRangeDisplayedEnum.Hours)) {
      items.push(...[
        { value: periodsEnum.CurrentHour, label: 'globalCurrentHour' },
        { value: periodsEnum.PreviousHour, label: 'globalPreviousHour' },
      ]);
    }
    if (periodRangeDisplayed.includes(periodRangeDisplayedEnum.Days)) {
      items.push(...[
        { value: periodsEnum.Today, label: 'reportToday' },
        { value: periodsEnum.Yesterday, label: 'reportYesterday' },
      ]);
    }
    if (periodRangeDisplayed.includes(periodRangeDisplayedEnum.Weeks)) {
      items.push(...[
        { value: periodsEnum.ThisWeek, label: 'reportThisWeek' },
        { value: periodsEnum.PreviousWeek, label: 'reportPreviousWeek' },
      ]);
    }
    if (periodRangeDisplayed.includes(periodRangeDisplayedEnum.Months)) {
      items.push(...[
        { value: periodsEnum.ThisMonth, label: 'reportThisMonth' },
        { value: periodsEnum.PreviousMonth, label: 'reportPreviousMonth' },
      ]);
    }

    items.push({ value: periodsEnum.Custom, label: 'reportCustom' });

    return items.map(({ value, label }) => (<MenuItem key={value} value={value}>{t(label)}</MenuItem>));
  };

  return (
    <div className={`${inLine && classes.inLine}`}>
      <FormControl
        fullWidth
        variant={variant}
        size={size}
      >
        <InputLabel className={classes.datetimeLabel}>{t('reportPeriod')}</InputLabel>
        <Select
          variant={variant}
          value={period}
          onChange={handleChangePeriod}
          MenuProps={theme.overrides.MenuProps}
          displayEmpty
          disabled={disabled}
        >
          {periodsMenusItems()}
        </Select>
      </FormControl>

      {period === periodsEnum.Custom && (
        <div className={`${classes.datetimeContainer} ${column && classes.column}`}>
          <TextField
            size={size}
            variant={variant}
            name={datetimeEnum.From}
            disabled={disabled}
            label={t('reportFrom')}
            type="datetime-local"
            InputLabelProps={{ shrink: true }}
            onChange={handleChangeDatetime}
          />
          <TextField
            size={size}
            variant={variant}
            name={datetimeEnum.To}
            disabled={disabled}
            label={t('reportTo')}
            type="datetime-local"
            InputLabelProps={{ shrink: true }}
            onChange={handleChangeDatetime}
          />
        </div>
      )}
    </div>
  );
};

export default DatetimeCombobox;
