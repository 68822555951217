import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  TextField,
  Switch,
  Checkbox,
  FormControlLabel,
  Tooltip,
  CircularProgress,
} from '@material-ui/core';
import moment from 'moment';
import { useTheme } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import { DropDownBox } from 'devextreme-react';
import DataGrid, {
  Selection, Paging, FilterRow, Scrolling, Column,
} from 'devextreme-react/data-grid';
import ArrayStore from 'devextreme/data/array_store';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import useReportStyles from '../hooks/useReportStyles';

const ReportSmartFilter = ({
  children, handleSubmit, showOnly, columns, typeReport, off, setOff, progress, onBs, setT, setF, setSelectedItem,
  setPositionsPath, withoutDevices, devicesList, progressDevices, countProgress, periodChosen, setPeriodChosen,
  setIncludeTemporaries, includeTemporaries, deviceId, lastReplayPeriod
}) => {
  const t = useTranslation();
  const classes = useReportStyles();

  const [itemStore, setItemStore] = useState(new ArrayStore({
    key: 'id',
    data: [],
  }));

  const [deviceIds, setDeviceIds] = useState([]);
  const [period, setPeriod] = useState('');
  const [from, setFrom] = useState(moment().subtract(1, 'hour'));
  const [to, setTo] = useState(moment());
  const [selectedAnchorEl, setSelectedAnchorEl] = useState(null);
  const [selectedAnchorElExport, setSelectedAnchorElExport] = useState(null);
  const theme = useTheme();
  const [item, setItem] = useState(false);

  const [tempFrom, setTempFrom] = useState(from.format(moment.HTML5_FMT.DATETIME_LOCAL));
  const [tempTo, setTempTo] = useState(to.format(moment.HTML5_FMT.DATETIME_LOCAL));

  useEffect(() => {
    if (lastReplayPeriod) {
      setPeriod(lastReplayPeriod);
    }
  }, [lastReplayPeriod])

  useEffect(() => {
    if (deviceId) {
      setDeviceIds([deviceId]);
    }
  }, [deviceId])

  const handleFromChange = (e) => {
    const value = e.target.value;
    setTempFrom(value);

    if (moment(value, moment.HTML5_FMT.DATETIME_LOCAL, true).isValid()) {
      const newDate = moment(value, moment.HTML5_FMT.DATETIME_LOCAL);
      setFrom(newDate);
      if (setF) {
        setF(newDate);
      }
    }
  };

  const handleToChange = (e) => {
    const value = (e.target.value);
    setTempTo(value);

    if (moment(value, moment.HTML5_FMT.DATETIME_LOCAL, true).isValid()) {
      const newDate = moment(value, moment.HTML5_FMT.DATETIME_LOCAL);
      setTo(newDate);
      if (setT) {
        setT(newDate);
      }
    }
  };

  const disabled = !period
    || progressDevices
    || !!progress
    || (withoutDevices ? !!progress : (!deviceIds.length && includeTemporaries === false));

  const includeProperties = (property) => {
    /**
     * Обновляет список выключенных столбцов в таблице отчета
     */
    let updatedOff;
    if (off.includes(property)) {
      updatedOff = off.filter((item) => item !== property);
    } else {
      updatedOff = [...off, property];
    }
    window.localStorage.setItem(typeReport, JSON.stringify(updatedOff));
    setOff(updatedOff);
  };

  const handleClick = async (mail, format) => {
    if (setSelectedItem) {
      setSelectedItem(null);
    }
    if (setPositionsPath) {
      setPositionsPath({});
    }
    setItem(true);
    let selectedFrom;
    let selectedTo;
    switch (period) {
      case 'today':
        selectedFrom = moment().startOf('day');
        selectedTo = moment().endOf('day');
        break;
      case 'yesterday':
        selectedFrom = moment().subtract(1, 'day').startOf('day');
        selectedTo = moment().subtract(1, 'day').endOf('day');
        break;
      case 'thisWeek':
        selectedFrom = moment().startOf('week');
        selectedTo = moment().endOf('week');
        break;
      case 'previousWeek':
        selectedFrom = moment().subtract(1, 'week').startOf('week');
        selectedTo = moment().subtract(1, 'week').endOf('week');
        break;
      case 'thisMonth':
        selectedFrom = moment().startOf('month');
        selectedTo = moment().endOf('month');
        break;
      case 'previousMonth':
        selectedFrom = moment().subtract(1, 'month').startOf('month');
        selectedTo = moment().subtract(1, 'month').endOf('month');
        break;
      default:
        selectedFrom = from;
        selectedTo = to;
        break;
    }
    let accept;
    switch (format) {
      case 'csv':
        accept = 'text/csv';
        break;
      case 'excel':
        accept = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        break;
      default:
        accept = 'application/json';
        break;
    }
    if (setF) {
      setF(selectedFrom);
      setT(selectedTo);
    }
    handleSubmit(
      deviceIds,
      selectedFrom.toISOString(),
      selectedTo.toISOString(),
      { Accept: accept },
    );
  };

  useEffect(() => item && handleClick(false), [onBs]);

  const handleSelectedRowKeysChange = useCallback((keys) => {
    if (keys) {
      setDeviceIds(keys);
    }
  }, []);

  useEffect(() => {
    setItemStore(new ArrayStore({
      key: 'id',
      data: devicesList,
    }));
  }, [devicesList]);

  const dataGridRender = () => (
    <DataGrid
      height={345}
      dataSource={itemStore}
      hoverStateEnabled
      selectedRowKeys={deviceIds}
      onSelectedRowKeysChange={handleSelectedRowKeysChange}
    >
      <Selection mode="multiple" showCheckBoxesMode="always" allowSelectAll={devicesList.length < 100} />
      <Scrolling mode="virtual" />
      <Paging enabled pageSize={10} />
      <FilterRow visible />
      <Column
        key="name"
        name="name"
        dataField="name"
        dataType="string"
        caption={t('reportDevice')}
      />
      <Column
        key="uniqueId"
        name="uniqueId"
        dataField="uniqueId"
        dataType="string"
        caption={t('reportId')}
      />
    </DataGrid>
  );

  const syncDataGridSelection = (e) => {
    setDeviceIds(e.value || []);
  };

  const getDeviceSelectorComponent = () => {
    if (periodChosen === false) {
      return (
        <>
          <FormControl variant="filled" fullWidth style={{ height: '80%' }}>
            <InputLabel shrink>{t('reportDevices')}</InputLabel>
            <DropDownBox
              disabled
              placeholder={t('sharedChoosePeriod')}
              style={{
                height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.035)', border: 0,
              }}
            />
          </FormControl>
        </>

      );
    }
    if (progressDevices) {
      return (
        <>
          <FormControl variant="filled" fullWidth style={{ height: '80%' }}>
            <InputLabel shrink>{t('reportDevices')}</InputLabel>
            <DropDownBox
              disabled
              placeholder={`${countProgress} ${t('sharedNumbers')}`}
              style={{
                height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.035)', border: 0,
              }}
            />
          </FormControl>
        </>
      );
    }
    return (
      <FormControl variant="filled" fullWidth style={{ height: '80%' }}>
        <InputLabel shrink>{t('reportDevices')}</InputLabel>
        <DropDownBox
          value={deviceIds}
          valueExpr="id"
          deferRendering={false}
          // inputAttr={ownerLabel}
          displayExpr="Name"
          placeholder={`${deviceIds.length} ${t('sharedNumbers')}`}
          showClearButton
          dataSource={itemStore}
          onValueChanged={syncDataGridSelection}
          contentRender={dataGridRender}
          style={{
            height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.035)', border: 0,
          }}
        />
      </FormControl>
    );
  };

  return (
    <div className={classes.filter}>
      {setIncludeTemporaries && (
        <div className={classes.filterItem}>
          <Tooltip title={t('includeTemporariesCheckboxTitle')}>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={includeTemporaries}
                  onChange={(event) => setIncludeTemporaries(event.target.checked)}
                />
              )}
              label={t('reportIncludeTemporaries')}
            />
          </Tooltip>
        </div>
      )}
      <div className={classes.filterItem}>
        <FormControl variant="filled" fullWidth>
          <InputLabel>{t('reportPeriod')}</InputLabel>
          <Select
            value={period}
            onChange={(e) => {
              setPeriod(e.target.value);
              localStorage.setItem('lastReplayPeriod', e.target.value)
              if (setPeriodChosen) {
                setPeriodChosen(true);
              }
            }}
            MenuProps={theme.overrides.MenuProps}
            displayEmpty
          >
            <MenuItem value="today">{t('reportToday')}</MenuItem>
            <MenuItem value="yesterday">{t('reportYesterday')}</MenuItem>
            <MenuItem value="thisWeek">{t('reportThisWeek')}</MenuItem>
            <MenuItem value="previousWeek">{t('reportPreviousWeek')}</MenuItem>
            <MenuItem value="thisMonth">{t('reportThisMonth')}</MenuItem>
            <MenuItem value="previousMonth">{t('reportPreviousMonth')}</MenuItem>
            <MenuItem value="custom">{t('reportCustom')}</MenuItem>
          </Select>
        </FormControl>
      </div>
      {period === 'custom' && (
        <div className={classes.filterItem}>
          <TextField
            inputProps={{
              min: "0000-01-01T00:00",
              max: "9999-12-31T00:00",
            }}
            variant="filled"
            label={t('reportFrom')}
            type="datetime-local"
            value={tempFrom}
            onChange={handleFromChange}
            fullWidth
          />
        </div>
      )}
      {period === 'custom' && (
        <div className={classes.filterItem}>
          <TextField
            inputProps={{
              min: "0000-01-01T00:00",
              max: "9999-12-31T00:00",
            }}
            variant="filled"
            label={t('reportTo')}
            type="datetime-local"
            value={tempTo}
            onChange={handleToChange}
            fullWidth
          />
        </div>
      )}
      {!withoutDevices && (
        <div className={classes.filterItem}>
          {getDeviceSelectorComponent()}
        </div>
      )}
      {children}
      <div className={classes.filterButtons}>
        {!showOnly && (
          <>
            <Button
              onClick={(event) => setSelectedAnchorEl(event.currentTarget)}
              variant="outlined"
              color="primary"
              className={classes.filterButton}
            >
              {t('reportColumns')}
            </Button>
            <Menu
              open={!!selectedAnchorEl}
              anchorEl={selectedAnchorEl}
              onClose={() => setSelectedAnchorEl(null)}
              className={classes.menuEditRemove}
            >
              {columns && columns.map((column) => (
                <MenuItem key={column[0]} onClick={() => includeProperties(column[0])}>
                  <Switch checked={!off.includes(column[0])} />
                  {t(column[1])}
                </MenuItem>
              ))}
            </Menu>
          </>
        )}
        <Button
          onClick={() => handleClick(false)}
          variant="outlined"
          color="secondary"
          className={classes.filterButton}
          disabled={disabled}
        >
          {(progressDevices || progress) ? <CircularProgress /> : t('sharedSave')}
        </Button>
        {!showOnly && (
          <>
            <Button
              onClick={(event) => setSelectedAnchorElExport(event.currentTarget)}
              variant="outlined"
              color="secondary"
              className={classes.filterWideButton}
              disabled={disabled}
            >
              {t('reportExport')}
            </Button>
            <Menu
              open={!!selectedAnchorElExport}
              anchorEl={selectedAnchorElExport}
              onClose={() => setSelectedAnchorElExport(null)}
              className={classes.menuEditRemove}
            >
              <MenuItem
                key="csv"
                onClick={() => {
                  setSelectedAnchorElExport(null);
                  handleClick(false, 'csv');
                }}
              >
                CSV
              </MenuItem>
              <MenuItem
                key="excel"
                onClick={() => {
                  setSelectedAnchorElExport(null);
                  handleClick(false, 'excel');
                }}
              >
                EXCEL
              </MenuItem>
            </Menu>
          </>
        )}
      </div>
    </div>
  );
};

export default ReportSmartFilter;

ReportSmartFilter.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  progress: PropTypes.number.isRequired,
  showOnly: PropTypes.bool,
  columns: PropTypes.arrayOf(PropTypes.any),
  typeReport: PropTypes.string,
  off: PropTypes.arrayOf(PropTypes.string),
  setOff: PropTypes.func,
  onBs: PropTypes.number,
  setT: PropTypes.func,
  setF: PropTypes.func,
  setSelectedItem: PropTypes.func,
  setPositionsPath: PropTypes.func,
  withoutDevices: PropTypes.bool,
};
