import { warningsActions } from '../../store';

function checkProtocol(url) {
  if (url) {
    if (url.startsWith('https:')) {
      return true;
    }

    if (url.startsWith('http:')) {
      return false;
    }
  }

  return true;
}

export default function urlsProtocolCheck(dispatch, t, data) {
  const { attributes } = data;
  if (!checkProtocol(attributes.mapGeoсodeProviderUrl)) {
    dispatch(warningsActions.push(`${t('warningProtocolMapGeoсodeProvider')} ${t('warningProtocolInfo')}`));
  }
  if (!checkProtocol(data.mapUrl)) {
    dispatch(warningsActions.push(`${t('warningProtocolMapUrl')} ${t('warningProtocolInfo')}`));
  }

  if (attributes.extraMaps?.length > 0) {
    attributes.extraMaps.forEach((item) => {
      if (!checkProtocol(item.params?.tiles[0])) {
        dispatch(warningsActions.push(`${t('warningProtocolExtraMapPrefix')} "${item.name}" ${t('warningProtocolExtraMapPostfix')} ${t('warningProtocolInfo')}`));
      }
    });
  }
}
