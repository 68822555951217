import React from 'react';
import 'video.js/dist/video-js.css';
import VideoPlayer from './VideoPlayer';

const options = (url, videoType, additionalOptions) => ({
  autoplay: true,
  controls: true,
  responsive: true,
  fluid: true,
  muted: true,
  sources: [{
    src: url,
    type: videoType,
  }],
  ...additionalOptions,
});

const SimpleVideoPlayer = ({
  url,
  type = 'video/mp4',
  additionalOptions = {},
}) => (
  <VideoPlayer options={options(url, type, additionalOptions)} />
);

export default SimpleVideoPlayer;
