import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import ReplayPathMap from '../../../map/ReplayPathMap';
import PositionsMap from '../../../map/PositionsMap';
import SelectedPositionMap from '../../../map/SelectedPositionMap';
import GeofenceMap from '../../../map/GeofenceMap';
import Map, { map } from '../../../map/Map';
import positionsTypes from '../../../common/static/positionsTypes';
import BSMap from '../../../map/BSMap';
import MarkersMap from '../../../map/MarkersMap';
import MarkerOneMap from '../../../map/MarkerOneMap';
import RulerMap from '../../../map/RulerMap';
import { runLoadingResources } from '../../../map/MapControllerDynamic';
import CurrentLocationMap from '../../../map/CurrentLocationMap';

const ReplayMap = ({
  panel, nextPosition, setCountPosition, setNextPosition, statePositions,
  fromPeriod, toPeriod, needMoveMap, addMarkerMode, setAddMarkerMode, moveableMarker, setMoveableMarker,
  setNewMarkerCoordinates, currentPositions, setCurrentPositions, rulerMode, setRulerDistance,
  openChoice, setOpenChoice, setPositionDataVisible, setPositionsBS, setSelectedMarkerComponents,
  clearMarkerState, markerState, devicesObject, setOpacityMapsOpen,
}) => {
  const selectedPositionData = useSelector((state) => state.positions.selectedPositionData);

  useEffect(() => {
    const currentP = {};
    Object.keys(statePositions.positions).forEach((pId) => {
      currentP[pId] = [statePositions.positions[pId][statePositions.indexes[pId]]];
    });
    setCurrentPositions(currentP);
  }, [statePositions.positions, statePositions.indexes, fromPeriod, toPeriod]);

  useEffect(() => {
    if (addMarkerMode || moveableMarker) {
      map.getCanvas().style.cursor = 'crosshair';
    } else {
      map.getCanvas().style.cursor = 'grab';
    }
  }, [addMarkerMode, moveableMarker, map.getCanvas().style.cursor]);

  useEffect(() => runLoadingResources(), []);

  return (
    <Map needMoveMap={needMoveMap} setOpacityMapsOpen={setOpacityMapsOpen}>
      {devicesObject && (
        <>
          <ReplayPathMap positions={statePositions.positionsPath} devices={devicesObject} onLine3D={panel.onLine3D} onLine={panel.onLine} />
          {!!(panel.onCurrent && statePositions.indexes && currentPositions) && (
            <PositionsMap
              data={positionsTypes.current}
              setNextPosition={setNextPosition}
              onClusters={panel.onClustersCurrent}
              text={panel.onTextCurrent}
              setPositionsBS={statePositions.setPositionsBS}
              positions={currentPositions}
              onTower={panel.onTower}
              devices={devicesObject}
            />
          )}
          {panel.onSides && (
            <>
              <PositionsMap
                positions={statePositions.positions}
                data={positionsTypes.finishPoint}
                setNextPosition={setNextPosition}
                onClusters={panel.onClustersSides}
                text={panel.onTextSides}
                setPositionsBS={statePositions.setPositionsBS}
                onTower={panel.onTower}
                devices={devicesObject}
              />
              <PositionsMap
                positions={statePositions.positions}
                data={positionsTypes.startPoint}
                setNextPosition={setNextPosition}
                onClusters={panel.onClustersSides}
                text={panel.onTextSides}
                setPositionsBS={statePositions.setPositionsBS}
                onTower={panel.onTower}
                devices={devicesObject}
              />
            </>
          )}
          {panel.onPositions && (
            <PositionsMap
              positions={statePositions.positions}
              data={positionsTypes.arrow}
              positionsSimilar={statePositions.positionsSimilar}
              setNextPosition={setNextPosition}
              onClusters={panel.onClustersPositions}
              text={panel.onTextPositions}
              nextPosition={nextPosition}
              setCountPosition={setCountPosition}
              setPositionsBS={statePositions.setPositionsBS}
              onTower={panel.onTower}
              devices={devicesObject}
            />
          )}
          {panel.onStops && (
            <PositionsMap
              positions={statePositions.positionsStop}
              data={positionsTypes.stop}
              setNextPosition={setNextPosition}
              onClusters={panel.onClustersStops}
              text={panel.onTextStops}
              setPositionsBS={statePositions.setPositionsBS}
              devices={devicesObject}
            />
          )}
          {!!(panel.onTower && statePositions.positionsBS.length) && (
            <BSMap
              positions={statePositions.positionsBS}
              data={positionsTypes.bs}
              setNextPosition={setNextPosition}
              onTower={panel.onTower}
            />
          )}
          {Object.keys(selectedPositionData).length && <SelectedPositionMap position={selectedPositionData} />}
          {panel.onGeofence && (
            <GeofenceMap />
          )}
          <MarkerOneMap
            addMarkerMode={addMarkerMode}
            setAddMarkerMode={setAddMarkerMode}
            setNewMarkerCoordinates={setNewMarkerCoordinates}
            clearMarkerState={clearMarkerState}
            markerState={markerState}
          />
          {panel.onMarkers && (
            <MarkersMap
              noClick={addMarkerMode || rulerMode}
              openChoice={openChoice}
              setNextPosition={setNextPosition}
              setOpenChoice={setOpenChoice}
              setPositionDataVisible={setPositionDataVisible}
              setPositionsBS={setPositionsBS}
              setNewMarkerCoordinates={setNewMarkerCoordinates}
              moveableMarker={moveableMarker}
              setMoveableMarker={setMoveableMarker}
              onClusters={panel.onClustersMarkers}
              text={panel.onTextMarkers}
              setSelectedMarkerComponents={setSelectedMarkerComponents}
              markerState={markerState}
              clearMarkerState={clearMarkerState}
            />
          )}
          <RulerMap rulerMode={rulerMode} setDistance={setRulerDistance} />
          <CurrentLocationMap />
        </>
      )}
    </Map>
  );
};

export default ReplayMap;
