import React from 'react';
import { Paper, Typography, makeStyles } from '@material-ui/core';
import { useTranslation } from './LocalizationProvider';

const useStyles = makeStyles(() => ({
  root: {
    margin: '0 auto',
    width: 'max-content',
    opacity: 0.8,
  },
  text: {
    padding: '1px 10px',
    textAlign: 'center',
  },
}));
const TextRulerDistance = ({ distance }) => {
  const classes = useStyles();
  const t = useTranslation();

  return (
    <Paper className={classes.root}>
      <Typography variant="body1" className={classes.text}>
        {`${t('sharedTotalDistance')}: ${distance.toLocaleString()} ${t('sharedKm')}`}
      </Typography>
    </Paper>
  );
};

export default TextRulerDistance;
