import React from 'react';

import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import { useDispatch, useSelector } from 'react-redux';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as VirtualList } from 'react-window';
import {
  IconButton, ListItem, ListItemText, Paper, Tooltip, Typography, makeStyles,
} from '@material-ui/core';
import { useTranslation } from './LocalizationProvider';
import SidePanel from './PositionData/SidePanel';
import { formatNotificationTitle, formatDate, getAttr } from '../utils/formatter';
import { getPositionProperties, getPositionExtraProperties } from '../../map/funcs/positionSource';
import { eventsActions, positionsActions } from '../../store';
import positionsTypes from '../static/positionsTypes';
import scrollStyles from '../theme/scrollStyles';

const useStyles = makeStyles(() => ({
  paper: {
    height: '100%',
    width: '100%',
    paddingBottom: '0',
  },
  overflow: {
    overflowY: 'auto',
    overflowX: 'hidden',
    ...scrollStyles(5),
  },
  eventContent: {
    wordWrap: 'break-word',
  },
  noWrap: {
    // overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  noWrapHidden: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  textContainer: {
    display: 'flex',
    gap: 5,
  },
  containerScrollStyles: {
    ...scrollStyles(6),
  },
}));

const EventSidePanel = ({ setOpen }) => {
  const classes = useStyles();
  const t = useTranslation();
  const dispatch = useDispatch();
  const devices = useSelector((state) => state.devices.items);
  const groups = useSelector((state) => state.groups.items);
  const user = useSelector((state) => state.session.user);
  const events = useSelector((state) => state.events.items);
  const eventsSoundOn = useSelector((state) => state.events.sound);

  const formatType = (event) => formatNotificationTitle(t, {
    type: event.type,
    description: event.attributes?.description,
    attributes: {
      alarms: event.attributes?.alarm,
    },
  });

  const handleEventClick = async (event) => {
    if (event.positionId) {
      const response = await fetch(`/api/positions?id=${event.positionId}`);
      if (response.ok) {
        const position = await response.json();
        const properties = getPositionProperties(position, devices[event.deviceId], user, t, positionsTypes.arrow);
        try {
          const extraProperties = getPositionExtraProperties(position[0], devices[event.deviceId], positionsTypes.arrow, groups);
          dispatch(positionsActions.selectPosition({ ...properties, ...extraProperties }));
          dispatch(positionsActions.needToShowDataPanel(true));
        } catch (e) {
          console.warn(e);
        }
      }
    }
  };

  const prepareContent = (event) => {
    let content = formatDate(event.eventTime, 'DD.MM, HH:mm:ss');
    if (event.attributes.message) {
      content += ' • ';
      content += event.attributes.message;
    }
    return content;
  };

  return (
    <SidePanel
      headerContent={(
        <>
          <Typography variant="h6" color="inherit" noWrap style={{ width: '100%' }}>
            {t('reportEvents')}
          </Typography>
          {eventsSoundOn ? (
            <Tooltip title={t('eventsSoundOnTitle')}>
              <IconButton onClick={() => dispatch(eventsActions.turnSound(false))}>
                <VolumeUpIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title={t('eventsSoundOnTitle')}>
              <IconButton onClick={() => dispatch(eventsActions.turnSound(true))}>
                <VolumeOffIcon />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title={t('reportClear')}>
            <IconButton onClick={() => dispatch(eventsActions.deleteAll())}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('closeTitle')}>
            <IconButton
              onClick={() => setOpen(false)}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </>
      )}
      bodyContent={(
        <Paper className={classes.paper} elevation={0} square>
          <AutoSizer style={{ maxHeight: '100%', maxWidth: '100%' }}>
            {({ height, width }) => (
              <VirtualList
                className={classes.containerScrollStyles}
                height={height}
                width={width}
                itemCount={events.length}
                itemSize={52}
              >
                {({ index, style }) => (
                  <ListItem
                    key={events[index].id}
                    disabled={!events[index].id}
                    style={{ ...style, padding: 0 }}
                  >
                    <ListItemText
                      primary={(
                        <div className={`${classes.textContainer} ${classes.noWrap}`}>
                          <Tooltip title={devices[events[index].deviceId]?.name ?? t('iconUnknown')}>
                            <span className={classes.noWrap}>
                              {getAttr(events[index], 'deviceName') ?? devices[events[index].deviceId]?.name ?? t('iconUnknown')}
                            </span>
                          </Tooltip>
                          <span> • </span>
                          <Tooltip title={formatType(events[index])}>
                            <span className={classes.noWrapHidden}>{formatType(events[index])}</span>
                          </Tooltip>
                        </div>
                      )}
                      secondary={prepareContent(events[index])}
                      classes={{ secondary: classes.eventContent }}
                    />
                    {events[index].positionId !== 0 && (
                      <IconButton onClick={() => handleEventClick(events[index])}>
                        <MyLocationIcon fontSize="small" className={classes.negative} />
                      </IconButton>
                    )}
                    <IconButton onClick={() => dispatch(eventsActions.delete(events[index]))}>
                      <DeleteIcon fontSize="small" className={classes.negative} />
                    </IconButton>
                  </ListItem>
                )}
              </VirtualList>
            )}
          </AutoSizer>
        </Paper>
      )}
    />
  );
};

export default EventSidePanel;
