import React from 'react';

import {
  Box, Dialog, DialogTitle, Divider, IconButton, makeStyles, Typography,
} from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from '../../../common/components/LocalizationProvider';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
}));

export default function ExtraMapsHelp({ openHelp, setOpenHelp }) {
  const t = useTranslation();
  const classes = useStyles();

  return (
    <Dialog
      open={openHelp}
      onClose={() => { setOpenHelp(false); }}
    >
      <Box style={{ padding: 20 }}>
        <IconButton className={classes.closeButton} onClick={() => { setOpenHelp(false); }}>
          <CloseIcon />
        </IconButton>
        <DialogTitle style={{ padding: '0' }}>{t('sharedHelp')}</DialogTitle>

        <Box marginTop={2}>
          <Divider />
          <Typography variant="h5" style={{ fontWeight: 'bold', color: '#333' }}>
            XYZ схема
          </Typography>
          <Divider />
          <Typography variant="body1" style={{ marginBottom: 8 }}>
            Формат карт, где тайлы организованы по принципу: уровень масштаба (z), горизонтальная позиция (x) и вертикальная позиция (y), при этом
            <strong> ось Y идет снизу вверх.</strong>
            <br />
            <br />
            Пример:
            <pre style={{ background: '#f5f5f5', padding: 10, borderRadius: 4 }}>
              {'http://example.com/{z}/{x}/{y}.png'}
            </pre>
          </Typography>
        </Box>

        <Box marginTop={2}>
          <Divider />
          <Typography variant="h5" style={{ fontWeight: 'bold', color: '#333' }}>
            TMS схема
          </Typography>
          <Divider />

          <Typography variant="body1" style={{ marginBottom: 8 }}>
            Формат карт, где тайлы организованы по принципу: уровень масштаба (z), горизонтальная позиция (x) и вертикальная позиция (y), при этом
            <strong> ось Y идет снизу вверх</strong>
            <br />
            <br />
            Пример:
            <pre style={{ background: '#f5f5f5', padding: 10, borderRadius: 4 }}>
              {'http://example.com/{z}/{x}/{y}.png'}
            </pre>
          </Typography>
        </Box>

        <Box marginTop={2}>
          <Divider />
          <Typography variant="h5" style={{ fontWeight: 'bold', color: '#333' }}>
            GeoJSON
          </Typography>
        </Box>
        <Divider />
        <Box display="flex" alignItems="center" style={{ color: '#ffae72' }}>
          <WarningIcon style={{ color: '#ffae72', marginRight: 8 }} />
          <Typography>
            При работе с большими файлами GeoJSON могут возникать проблемы с производительностью и стабильностью приложения. Использование кластеров может значительно улучшить ситуацию.
          </Typography>
        </Box>
        <Divider />

        <Box marginTop={2}>
          <Typography variant="h6" style={{ fontWeight: 'bold', color: '#333' }}>
            Point - точка
          </Typography>
          <Typography variant="body1" style={{ marginBottom: 8 }}>
            -
            {' '}
            <strong>radius</strong>
            : число, радиус точки (по умолчанию: 3).
            <br />
            -
            {' '}
            <strong>color</strong>
            : строка в формате HEX, цвет точки (цвет по умолчанию).
            <br />
            -
            {' '}
            <strong>opacity</strong>
            : число от 0 до 1, прозрачность точки (по умолчанию: 1).
            <br />
            -
            {' '}
            <strong>strokeWidth</strong>
            : число, ширина обводки (по умолчанию: 1).
            <br />
            -
            {' '}
            <strong>strokeColor</strong>
            : строка в формате HEX, цвет обводки (по умолчанию: #000000).
            <br />
            -
            {' '}
            <strong>strokeOpacity</strong>
            : число от 0 до 1, прозрачность обводки (по умолчанию: 1).
            <br />
            <br />
            -
            {' '}
            <strong>title</strong>
            : строка, текст, отображаемый рядом с точкой (по умолчанию: пусто).
            <br />
            -
            {' '}
            <strong>titleSize</strong>
            : число, размер шрифта заголовка (по умолчанию: 12).
            <br />
            -
            {' '}
            <strong>titleColor</strong>
            : строка в формате HEX, цвет текста заголовка (по умолчанию: #000000).
            <br />
            -
            {' '}
            <strong>titleOpacity</strong>
            : число от 0 до 1, прозрачность текста заголовка (по умолчанию: 1).
            <br />
            <br />
            Пример:
            <pre style={{ background: '#f5f5f5', padding: 10, borderRadius: 4 }}>
              {`{
  type: 'Feature',
  properties: {
    radius: 20,
    color: '#ff0000',
    opacity: 0.5,
    strokeWidth: 5,
    strokeColor: '#295daa',
    strokeOpacity: 0.9,
    title: 'Точка',
    titleSize: 14,
    titleColor: '#ff0000',
    titleOpacity: 0.5,
  },
  geometry: {
    type: 'Point',
    coordinates: [30.4, 60.1],
  },
}`}
            </pre>
          </Typography>

          <Divider />

          <Typography variant="h6" style={{ fontWeight: 'bold', color: '#333', marginTop: 16 }}>
            LineString - линия
          </Typography>
          <Typography variant="body1" style={{ marginBottom: 8 }}>
            -
            {' '}
            <strong>color</strong>
            : строка в формате HEX, цвет линии (цвет по умолчанию).
            <br />
            -
            {' '}
            <strong>width</strong>
            : число, ширина линии (по умолчанию: 2).
            <br />
            -
            {' '}
            <strong>opacity</strong>
            : число от 0 до 1, прозрачность линии (по умолчанию: 1).
            <br />
            -
            {' '}
            <strong>type</strong>
            : строка, тип линии (&apos;dashed&apos; или не указан; по умолчанию: сплошная линия).
            <br />
            <br />
            -
            {' '}
            <strong>title</strong>
            : строка, текст, отображаемый рядом с линией (по умолчанию: пусто).
            <br />
            -
            {' '}
            <strong>titleSize</strong>
            : число, размер шрифта заголовка (по умолчанию: 12).
            <br />
            -
            {' '}
            <strong>titleColor</strong>
            : строка в формате HEX, цвет текста заголовка (по умолчанию: #000000).
            <br />
            -
            {' '}
            <strong>titleOpacity</strong>
            : число от 0 до 1, прозрачность текста заголовка (по умолчанию: 1).
            <br />
            <br />
            Пример:
            <pre style={{ background: '#f5f5f5', padding: 10, borderRadius: 4 }}>
              {`{
  type: 'Feature',
  properties: {
    color: '#102127',
    width: 2,
    type: 'dashed',
    opacity: 0.5,
    title: 'Линия',
    titleSize: 14,
    titleColor: '#102127',
    titleOpacity: 1,
  },
  geometry: {
    type: 'LineString',
    coordinates: [
      [30.4, 60.1],
      [30.4, 60.1],
    ],
  },
}`}
            </pre>
          </Typography>

          <Divider />

          <Typography variant="h6" style={{ fontWeight: 'bold', color: '#333', marginTop: 16 }}>
            Polygon - Многоугольник
          </Typography>
          <Typography variant="body1" style={{ marginBottom: 8 }}>
            -
            {' '}
            <strong>color</strong>
            : строка в формате HEX, цвет многоугольника (цвет по умолчанию).
            <br />
            -
            {' '}
            <strong>opacity</strong>
            : число от 0 до 1, прозрачность многоугольника (по умолчанию: 0.5). Не работает для 3D.
            <br />
            -
            {' '}
            <strong>altitude</strong>
            : число, высота основания многоугольника (опционально, но необходимо для отображения в 3D).
            <br />
            -
            {' '}
            <strong>height</strong>
            : число, высота многоугольника (обязательно при наличии altitude).
            <br />
            <br />
            -
            {' '}
            <strong>title</strong>
            : строка, текст, отображаемый рядом с многоугольником (по умолчанию: пусто).
            <br />
            -
            {' '}
            <strong>titleSize</strong>
            : число, размер шрифта заголовка (по умолчанию: 12).
            <br />
            -
            {' '}
            <strong>titleColor</strong>
            : строка в формате HEX, цвет текста заголовка (по умолчанию: #000000).
            <br />
            -
            {' '}
            <strong>titleOpacity</strong>
            : число от 0 до 1, прозрачность текста заголовка (по умолчанию: 1).
            <br />
            <br />
            Пример:
            <pre style={{ background: '#f5f5f5', padding: 10, borderRadius: 4 }}>
              {`{
  type: 'Feature',
  properties: {
      color: '#d8d0c9',
      altitude: 0,
      height: 27,
      title: 'Здание',
      titleSize: 16,
      titleColor: '#000000',
      titleOpacity: 1,
  },
  geometry: {
    type: 'Polygon',
    coordinates: [
      [
        [30.4, 60.1],
        [30.5, 60.2],
        [30.6, 60.3],
        [30.4, 60.1],
      ],
    ],
  },
}`}
            </pre>
          </Typography>
        </Box>
      </Box>
    </Dialog>
  );
}
