import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from './LocalizationProvider';

export default function DebuggingInfo({ replayInfo }) {
  const t = useTranslation();
  const debuggingOn = !!useSelector((state) => state.session.user.attributes.debuggingMode);

  const devices = useSelector((state) => state.devices.items);
  const positions = useSelector((state) => state.positions.items);
  const tailPositions = useSelector((state) => state.tail.positions);

  const markers = useSelector((state) => state.markers.items);
  const geofences = useSelector((state) => state.geofences.items);
  const replay = useSelector((state) => state.replay.items);

  const extraMapFeatureCount = useSelector((state) => state.map.extraMapFeatureCount);

  function sumValues(obj) {
    return Object.values(obj).reduce((sum, value) => sum + value, 0);
  }

  const geoJsonCount = sumValues(extraMapFeatureCount)

  const getObgArrCount = (positions) => Object.keys(positions).reduce((total, key) => {
    if (Array.isArray(positions[key])) {
      return total + positions[key].length;
    }
    return total;
  }, 0);

  let deviceCount = 0;
  let positionCount = 0;

  if (replayInfo) {
    deviceCount = Object.keys(replay).length;
    positionCount = getObgArrCount(replayInfo);
  } else {
    deviceCount = Object.keys(devices).length;
    positionCount = getObgArrCount(tailPositions) + Object.keys(positions).length;
  }

  const markerCount = Object.keys(markers).length;
  const geofenceCount = Object.keys(geofences).length;

  return (
    debuggingOn
    && (
      <div style={{
        position: 'absolute',
        top: '56px',
        right: '56px',
        textAlign: 'right',
        fontSize: '10px',
        zIndex: 2,
      }}
      >
        {t('debuggingDevice')}
        :
        {deviceCount}
        <br />
        {t('debuggingPositions')}
        :
        {positionCount}
        <br />
        {t('debuggingMarkers')}
        :
        {markerCount}
        <br />
        {t('debuggingGeofences')}
        :
        {geofenceCount}
        <br />
        {t('debuggingGeoJson')}
        :
        {geoJsonCount}
      </div>
    )
  );
}
