import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { warningsActions } from '../../store';
import { usePrevious } from '../utils/reactHelper';

const WarningHandler = () => {
  const dispatch = useDispatch();

  const warning = useSelector((state) => state.warnings.warnings.find(() => true));
  const previousWarning = usePrevious(warning);

  return (
    <Snackbar open={!!warning}>
      <Alert
        elevation={6}
        onClose={() => dispatch(warningsActions.pop())}
        severity="warning"
        variant="filled"
      >
        {warning || previousWarning}
      </Alert>
    </Snackbar>
  );
};

export default WarningHandler;
