import React, { useEffect, useState } from 'react';
import {
  Accordion, AccordionDetails, AccordionSummary, TextField, Typography,
  IconButton, Button, Box, Grid, useMediaQuery,
  Divider,
  Dialog,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  List,
  ListItem,
  Tooltip,
  makeStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import HelpIcon from '@material-ui/icons/HelpOutline';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import { setAttr } from '../../../common/utils/formatter';
import { prefixString } from '../../../common/utils/stringUtils';

const helpFields = ['message', 'device', 'deviceGroup', 'geofence', 'coordinates', 'address', 'eventTime', 'user'];

const useStyles = makeStyles((theme) => ({
  details: {
    flexDirection: 'column',
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    backgroundColor: 'rgba(0, 0, 0, 0.035)',
    borderRadius: theme.shape.borderRadius,
  },
  modalContent: {
    maxHeight: 'calc(100% - 64px)',
    overflowY: 'auto',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
  },
  helpButton: {
    position: 'absolute',
    right: theme.spacing(5),
    top: theme.spacing(1),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  textArea: {
    marginTop: theme.spacing(2),
    '& .MuiOutlinedInput-root': {
      borderRadius: theme.shape.borderRadius,
      backgroundColor: 'rgba(0, 0, 0, 0.035)',
      '& fieldset': {
        border: 'none',
      },
      '&.Mui-focused': {
        backgroundColor: 'rgba(0, 0, 0, 0.09)',
      },
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
      },
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.text.secondary,
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(12px, 8px) scale(0.75)',
    },
  },
  deleteButton: {
    marginLeft: theme.spacing(1),
  },
  saveButton: {
    marginTop: theme.spacing(2),
  },
  list: {
    padding: 0,
    marginBottom: theme.spacing(1),
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    padding: 0,
    marginBottom: theme.spacing(1),
  },
  fieldKey: {
    fontWeight: 'bold',
    marginRight: theme.spacing(1),
  },
  exampleField: {
    color: '#707070',
  },
}));

export default function WebHookBlock({
  item, setItem,
}) {
  const t = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const [openModal, setOpenModal] = useState(false);
  const [openHelp, setOpenHelp] = useState(false);

  const [currentWebhook, setCurrentWebhook] = useState(null);
  const [newWebhook, setNewWebhook] = useState({
    name: '', url: '', method: 'POST', headers: [], body: '', description: '',
  });
  const [errors, setErrors] = useState({ name: '' });

  const [webhooks, setWebhooks] = useState([]);

  useEffect(() => {
    const updatedWebhooks = [];
    if (item?.attributes?.webhooks) {
      Object.keys(item?.attributes?.webhooks).forEach((el) => {
        const updatedWebhook = { ...item.attributes.webhooks[el] };
        const updatedHeaders = [];
        Object.keys(item.attributes.webhooks[el].headers).forEach((headerEl) => {
          updatedHeaders.push({ key: headerEl, value: item.attributes.webhooks[el].headers[headerEl] });
        });
        updatedWebhook.headers = updatedHeaders;

        if (Object.keys(updatedWebhook.body).length > 0) {
          const stringifedBody = JSON.stringify(updatedWebhook.body, null, 2);
          updatedWebhook.body = stringifedBody;
        } else {
          updatedWebhook.body = '';
        }

        updatedWebhooks.push(updatedWebhook);
      });
    }
    setWebhooks(updatedWebhooks);
  }, [item.attributes?.webhooks]);

  const handleOpenModal = (webhook) => {
    if (webhook) {
      setCurrentWebhook(webhook);
      setNewWebhook(webhook);
    } else {
      setCurrentWebhook(null);
      setNewWebhook({
        name: '', url: '', method: 'POST', headers: [{ key: 'Content-Type', value: 'application/json' }], body: '', description: '',
      });
    }
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setCurrentWebhook(null);
    setErrors({ name: '' });
  };

  const replaceSpacesWithUnderscores = (str) => str.replace(/\s+/g, '_');

  const convertWebhookArrToObj = (updatedWebhooks) => {
    const webhookObject = {};
    updatedWebhooks.forEach((el) => {
      let webhook = {};
      const headersObject = {};
      el.headers.forEach((headerEl) => {
        if (headerEl.key.trim() !== '') {
          headersObject[headerEl.key] = headerEl.value;
        }
      });
      webhook = { ...el };
      webhook.headers = headersObject;
      if (webhook.body !== '') {
        if (headersObject['Content-Type'] === 'application/json') {
          webhook.body = JSON.parse(webhook.body);
        }
      } else {
        webhook.body = {};
      }
      webhook.inited = true;
      webhookObject[`wh_${replaceSpacesWithUnderscores(el.name)}`] = webhook;
    });
    return webhookObject;
  };

  const handleSaveWebhook = () => {
    let updatedWebhooks = [...(webhooks || [])];
    if (currentWebhook) {
      updatedWebhooks = updatedWebhooks.map((wh) => (wh.name === currentWebhook.name ? newWebhook : wh));
    } else {
      updatedWebhooks.push(newWebhook);
    }
    setAttr(setItem, 'webhooks', convertWebhookArrToObj(updatedWebhooks));
    handleCloseModal();
  };

  const handleDeleteWebhook = (name) => {
    const updatedWebhooks = (webhooks || []).filter(
      (wh) => wh.name !== name,
    );
    setAttr(setItem, 'webhooks', convertWebhookArrToObj(updatedWebhooks));
  };

  const handleHeaderChange = (index, field, value) => {
    const updatedHeaders = newWebhook.headers.map((header, i) => (i === index ? { ...header, [field]: value } : header));
    setNewWebhook({ ...newWebhook, headers: updatedHeaders });
  };

  const addHeader = () => {
    setNewWebhook({ ...newWebhook, headers: [...newWebhook.headers, { key: '', value: '' }] });
  };

  const removeHeader = (index) => {
    const updatedHeaders = newWebhook.headers.filter((_, i) => i !== index);
    setNewWebhook({ ...newWebhook, headers: updatedHeaders });
  };

  const validateName = () => {
    const existingNames = (webhooks || []).map((wh) => wh.name);
    const newErrors = { ...errors };
    const trimmedName = newWebhook.name.trim();

    if (trimmedName === '') {
      newErrors.name = t('webhookNameRequiredErr');
    } else if (trimmedName.includes(',')) {
      newErrors.name = t('webhookNameCommaErr');
    } else if (existingNames.includes(trimmedName) && (!currentWebhook || currentWebhook.name !== trimmedName)) {
      newErrors.name = t('webhookNameUniqueErr');
    } else {
      newErrors.name = '';
    }

    setErrors(newErrors);
  };

  const handleBodyType = (e) => {
    const type = e.target.value;
    const newHeaders = [...newWebhook.headers];
    const contentTypeIndex = newHeaders.findIndex((item) => item.key === 'Content-Type');

    if (type === 'JSON') {
      if (contentTypeIndex > -1) {
        newHeaders[contentTypeIndex].value = 'application/json';
      } else {
        newHeaders.unshift({ key: 'Content-Type', value: 'application/json' });
      }
    } else if (contentTypeIndex > -1 && newHeaders[contentTypeIndex].value === 'application/json') {
      const newErrors = { ...errors };
      newErrors.body = '';
      newHeaders.splice(contentTypeIndex, 1);
      setErrors(newErrors);
    }

    setNewWebhook((prev) => ({
      ...prev,
      headers: newHeaders,
    }));
  };

  const validateBody = () => {
    const { body, headers } = newWebhook;
    const newErrors = { ...errors };

    const contentTypeIndex = headers.findIndex((item) => item.key === 'Content-Type');
    if (headers[contentTypeIndex]?.value === 'application/json') {
      if (body.trim() === '') {
        newErrors.body = '';
      } else {
        try {
          JSON.parse(body);
          newErrors.body = '';
        } catch (err) {
          newErrors.body = `Ошибка JSON: ${err.message}`;
        }
      }
    } else {
      newErrors.body = '';
    }
    setErrors(newErrors);
  };

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="subtitle1">
          {t('sharedWebhooks')}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        {(webhooks || []).map((webhook) => (
          <div key={webhook.name} className={classes.buttonGroup}>
            <Typography variant="body1" style={{ marginLeft: theme.spacing(2) }}>{webhook.name}</Typography>
            <div>
              <IconButton onClick={() => handleOpenModal(webhook)}>
                <EditIcon />
              </IconButton>
              <IconButton onClick={() => handleDeleteWebhook(webhook.name)}>
                <DeleteIcon />
              </IconButton>
            </div>
          </div>
        ))}
        <Button variant="contained" onClick={() => handleOpenModal()}>
          {t('sharedAdd')}
        </Button>
      </AccordionDetails>
      <Dialog open={openModal} onClose={handleCloseModal} maxWidth="xs">
        <Box className={classes.modalContent}>
          <Tooltip title={t('webhookHelpTitle')}>
            <IconButton className={classes.helpButton} onClick={() => { setOpenHelp(true); }}>
              <HelpIcon />
            </IconButton>
          </Tooltip>

          <IconButton className={classes.closeButton} onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h6">{currentWebhook ? t('sharedEdit') : t('sharedAdd')}</Typography>
          <TextField
            className={classes.textField}
            fullWidth
            label={t('webhookNameLabel')}
            value={newWebhook.name}
            onChange={(e) => {
              setErrors({ name: '' });
              setNewWebhook({ ...newWebhook, name: e.target.value });
            }}
            onBlur={validateName}
            variant="filled"
            disabled={!!newWebhook.inited}
            error={!!errors.name}
            helperText={errors.name}
          />
          <TextField
            className={classes.textField}
            fullWidth
            label={t('webhookNameDescription')}
            value={newWebhook.description}
            onChange={(e) => {
              setNewWebhook({ ...newWebhook, description: e.target.value });
            }}
            variant="filled"
          />
          <TextField
            className={classes.textField}
            fullWidth
            label={t('webhookUrlLabel')}
            value={newWebhook.url}
            onChange={(e) => setNewWebhook({ ...newWebhook, url: e.target.value })}
            variant="filled"
          />
          <FormControl variant="filled" fullWidth margin="normal">
            <InputLabel>{t('webhookMethodLabel')}</InputLabel>
            <Select
              label={t('webhookMethodLabel')}
              value={newWebhook.method}
              onChange={(e) => setNewWebhook({ ...newWebhook, method: e.target.value })}
            >
              <MenuItem value="GET">GET</MenuItem>
              <MenuItem value="POST">POST</MenuItem>
            </Select>
          </FormControl>
          <Typography className={classes.textField}>{t('webhookHeadersLabel')}</Typography>
          {newWebhook.headers.map((header, index) => (
            <React.Fragment key={`wh_${index + 1}`}>
              <Grid
                container
                spacing={isMobile ? 0 : 1}
                alignItems="center"
                direction={isMobile ? 'column' : 'row'}
              >
                <Grid
                  item
                  xs={isMobile ? 12 : 5}
                  style={{ width: isMobile ? '100%' : 'auto' }}
                >
                  <TextField
                    className={classes.textField}
                    fullWidth
                    label={t('webhookKeyLabel')}
                    value={header.key}
                    onChange={(e) => handleHeaderChange(index, 'key', e.target.value)}
                    variant="filled"
                  />
                </Grid>
                <Grid
                  item
                  xs={isMobile ? 12 : 5}
                  style={{ width: isMobile ? '100%' : 'auto' }}
                >
                  <TextField
                    className={classes.textField}
                    fullWidth
                    label={t('webhookValueLabel')}
                    value={header.value}
                    onChange={(e) => handleHeaderChange(index, 'value', e.target.value)}
                    variant="filled"
                  />
                </Grid>
                <Grid
                  item
                  xs={isMobile ? 12 : 2}
                  style={{ width: isMobile ? '100%' : 'auto' }}
                >
                  {isMobile
                    ? (
                      <Button
                        onClick={() => removeHeader(index)}
                        variant="outlined"
                        fullWidth
                      >
                        {t('sharedRemove')}
                      </Button>
                    )
                    : (
                      <IconButton
                        className={classes.deleteButton}
                        onClick={() => removeHeader(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                </Grid>
              </Grid>
              <Divider />
            </React.Fragment>
          ))}
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            onClick={addHeader}
            startIcon={<AddIcon />}
          >
            {t('webhookAddHeader')}
          </Button>
          {
            newWebhook.method === 'POST'
            && (
              <>
                <FormControl variant="filled" fullWidth margin="normal">
                  <InputLabel>{t('webhookBodyType')}</InputLabel>
                  <Select
                    label={t('webhookBodyType')}
                    value={newWebhook.headers[newWebhook.headers.findIndex((item) => item.key === 'Content-Type')]?.value === 'application/json' ? 'JSON' : 'TEXT'}
                    onChange={handleBodyType}
                  >
                    <MenuItem value="JSON">{t('webhookBodyTypeJSON')}</MenuItem>
                    <MenuItem value="TEXT">{t('webhookBodyTypeText')}</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  className={classes.textArea}
                  fullWidth
                  label={t('webhookBodyLabel')}
                  value={newWebhook.body}
                  onChange={(e) => {
                    setErrors({ body: '' });
                    setNewWebhook({ ...newWebhook, body: e.target.value });
                  }}
                  onBlur={validateBody}
                  error={!!errors.body}
                  helperText={errors.body}
                  variant="outlined"
                  multiline
                  minRows={10}
                  maxRows={10}
                  style={{ backgroundColor: 'rgba(0, 0, 0, 0.035)' }}
                />
              </>
            )
          }
          <Button
            disabled={!!errors.name || !!errors.body || newWebhook.name.trim() === '' || newWebhook.url.trim() === ''}
            className={classes.saveButton}
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSaveWebhook}
          >
            {t('sharedApply')}
          </Button>
        </Box>
      </Dialog>

      <Dialog
        open={openHelp}
        onClose={() => { setOpenHelp(false); }}
      >
        <Box className={classes.modalContent}>
          <IconButton className={classes.closeButton} onClick={() => { setOpenHelp(false); }}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" gutterBottom>
            {t('webhookHelpTitle')}
          </Typography>
          <List className={classes.list}>
            {helpFields.map((field) => (
              <ListItem key={field} className={classes.listItem}>
                <Typography variant="body1" className={classes.fieldKey}>
                  •
                  {' '}
                  {`{${field}}`}
                </Typography>
                <Typography variant="body1" className={classes.fieldKey}>-</Typography>
                <Typography>{t(`${prefixString('webhookHelp', field)}`)}</Typography>
              </ListItem>
            ))}
          </List>
          <Divider />
          <Typography className={classes.exampleField}>{`${t('webhookHelpExampleURL')}: http://example.ru/alert?object={device}&zone={geofence}`}</Typography>
          <Typography className={classes.exampleField}>{`${t('webhookHelpExampleBody')}: {"object":"{device}","zone":"{geofence}"}`}</Typography>
          <Divider />
        </Box>
      </Dialog>
    </Accordion>
  );
}
