import { useEffect, useRef } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';

import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import {
  positionsActions, devicesActions, sessionActions, smsActions, markersActions,
  eventsActions, tailActions, geofencesActions,
  errorsActions,
} from './store';
import { useEffectAsync } from './common/utils/reactHelper';
import logout from './common/utils/logout';
import soundDeviceOnline from './common/static/sounds/deviceOnline.mp3';
import soundDefault from './common/static/sounds/default.mp3';
import soundAlarm from './common/static/sounds/alarm.mp3';
import soundTextMessage from './common/static/sounds/textMessage.mp3';
import soundSiren from './common/static/sounds/siren.wav';
import { prefixString } from './common/utils/stringUtils';
import { useTranslation } from './common/components/LocalizationProvider';
import { filterCurrent } from './common/utils/formatter';
import useBufferedArray from './common/hooks/useBufferedArray';
import { getPositionExtraProperties, getPositionProperties } from './map/funcs/positionSource';
import positionsTypes from './common/static/positionsTypes';
import urlsProtocolCheck from './common/utils/urlsProtocolCheck';

export const playSound = (type) => {
  let audio;
  switch (type) {
    case 'alarm':
      audio = new Audio(soundAlarm);
      break;
    case 'textMessage':
      audio = new Audio(soundTextMessage);
      break;
    case 'deviceOnline':
      audio = new Audio(soundDeviceOnline);
      break;
    case 'siren':
      audio = new Audio(soundSiren);
      break;
    default:
      audio = new Audio(soundDefault);
      break;
  }
  if (audio) {
    audio.play();
  }
};

const displayNotifications = (events, t, eventsSoundOn) => {
  if ('Notification' in window) {
    if (Notification.permission === 'granted') {
      events.forEach((event) => {
        if (eventsSoundOn) {
          playSound(event.id[1].attributes?.sound);
        }
        const notification = new Notification(
          `${event.id[1].attributes?.description || t(prefixString('event', event.id[0].type))}`,
          { icon: '/favicon.ico', vibrate: true },
        );
        setTimeout(notification.close.bind(notification), 10 * 1000);
      });
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission((permission) => {
        if (permission === 'granted') {
          displayNotifications(events, t, eventsSoundOn);
        }
      });
    }
  }
};

const SocketController = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const t = useTranslation();
  const eventsSoundOn = useSelector((state) => state.events.sound);
  const eventsSoundOnRef = useRef(eventsSoundOn);
  const closedDevices = useSelector((state) => state.devices.closedDevices);
  const stateSyncClosedDevices = useSelector((state) => state.devices.stateSyncClosedDevices);
  const [cookies] = useCookies();

  const selectedDeviceId = useSelector((state) => state.devices.selectedId);
  const trackingMode = useSelector((state) => state.positions.trackingMode);
  const replayItems = useSelector((state) => state.replay.items);
  const devices = useSelector((state) => state.devices.items);
  const user = useSelector((state) => state.session.user);
  const groups = useSelector((state) => state.groups.items);

  const trackingModeRef = useRef(trackingMode);
  const replayItemsRef = useRef(replayItems);
  const closedDevicesRef = useRef(closedDevices);
  const devicesRef = useRef(devices);
  const selectedDeviceIdRef = useRef(selectedDeviceId);

  const authenticated = useSelector((state) => !!state.session.user);
  const server = useSelector((state) => state.session.server);
  const serverRef = useRef(server);

  const getValuesFromObjectsArray = (array) => array.flatMap((item) => item.id);
  const getGroupedByMessageType = (data) => data.reduce((acc, obj) => {
    const { messageType } = obj;

    if (!acc[messageType]) {
      acc[messageType] = [];
    }

    delete obj.messageType;

    acc[messageType].push(obj);

    return acc;
  }, {});

  const actions = {
    devices: (devices) => {
      const items = devices.filter((item) => filterCurrent(item, serverRef.current));
      const devicesWithoutPosition = [];
      items.forEach((item) => {
        if (!item.positionId) {
          if (item.attributes.latitude && item.attributes.longitude) {
            devicesWithoutPosition.push({
              attributes: {},
              deviceId: item.id,
              latitude: item.attributes.latitude,
              longitude: item.attributes.longitude,
              accuracy: 99,
            });
          }
        }
      });
      dispatch(positionsActions.update(devicesWithoutPosition));

      dispatch(devicesActions.update(items));
      dispatch(tailActions.updateDevices(items));
    },
    positions: async (positions) => {
      dispatch(positionsActions.update(positions));
      dispatch(tailActions.add(positions.filter((p) => closedDevicesRef.current[p.deviceId] !== true)));

      if (selectedDeviceIdRef.current && !(Object.keys(replayItemsRef.current).length)) {
        positions.forEach(async (el) => {
          if ((selectedDeviceIdRef.current === el.deviceId) && trackingModeRef.current) {
            const response = await fetch(`/api/positions?id=${el.id}`);
            if (response.ok) {
              const position = await response.json();
              const text = 1;
              try {
                const properties = getPositionProperties(position[0], devicesRef.current[el.deviceId], user, t, positionsTypes.current, text);
                const extraProperties = getPositionExtraProperties(position[0], devicesRef.current[el.deviceId], positionsTypes.current, groups);
                dispatch(positionsActions.selectPosition({ ...properties, ...extraProperties, fromVirtualListDevice: true }));
              } catch (e) {
                console.warn(e);
              }
            }
          }
        });
      }
    },
    events: (events) => {
      dispatch(eventsActions.add(events.map((event) => {
        event.id[0].attributes.description = event.id[1].attributes.description;
        return event.id[0];
      })));
      displayNotifications(events, t, eventsSoundOnRef.current);
    },
    sms: (sms) => dispatch(smsActions.update(sms)),
    markers: (markers) => dispatch(markersActions.update(markers)),
    geofences: (geofences) => dispatch(geofencesActions.update(geofences)),
    deletedMarkerId: (markerIds) => dispatch(markersActions.remove(getValuesFromObjectsArray(markerIds))),
    deletedDeviceId: (deviceIds) => {
      const items = getValuesFromObjectsArray(deviceIds);
      dispatch(devicesActions.remove(items));
      dispatch(tailActions.removeDevices(items));
      dispatch(tailActions.remove(items));
      dispatch(positionsActions.remove(items));
    },
    deletedGeofenceId: (geofenceIds) => dispatch(geofencesActions.remove(getValuesFromObjectsArray(geofenceIds))),
  };

  const commonCaching = useBufferedArray((data) => {
    const groupedByMessageType = getGroupedByMessageType(data);

    // Выполняем действие на основе messageType
    for (const messageType in groupedByMessageType) {
      if (messageType in actions) {
        actions[messageType](groupedByMessageType[messageType]);
      }
    }
  }, 1000);

  const socketRef = useRef();
  const reconnectAttemptsRef = useRef(0);
  const maxReconnectAttempts = 3;
  const reconnectInterval = 10 * 1000;

  const connectSocket = () => {
    const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
    const socket = new WebSocket(`${protocol}//${window.location.host}/api/socket`);
    socketRef.current = socket;

    const attemptReconnect = () => {
      if (reconnectAttemptsRef.current < maxReconnectAttempts) {
        reconnectAttemptsRef.current++;
        setTimeout(() => {
          connectSocket();
        }, reconnectInterval);
      } else if (window.location.pathname === '/') {
        // eslint-disable-next-line no-alert
        window.alert('Соединение с сервером частично потеряно. Возможно, часть данных устарела. Обновите страницу');
      } else {
        const removeListener = history.listen((e) => {
          if (e.pathname === '/') {
            setTimeout(() => {
              // eslint-disable-next-line no-alert
              window.alert('Соединение с сервером частично потеряно. Возможно, часть данных устарела. Обновите страницу');
              removeListener();
            }, 1000);
          }
        });
      }
    };

    socket.onopen = () => {
      reconnectAttemptsRef.current = 0;
      dispatch(sessionActions.updateSocketOpened(true));
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      // Извлекаем первый ключ.
      const key = Object.keys(data)[0];

      if (!key) return;

      const arr = [];
      const isObject = data[key][0]?.id;

      for (const item of data[key]) {
        if (isObject) {
          arr.push({ ...item, messageType: key });
        } else {
          // Если элемент не является объектом, обрабатываем как массив
          arr.push({ id: item, messageType: key });
        }
      }

      commonCaching(arr);
    };

    socket.onclose = (event) => {
      if (event.wasClean) {
        dispatch(sessionActions.updateSocketOpened(false));
      } else {
        // например, сервер убил процесс или сеть недоступна
        // обычно в этом случае event.code 1006
        dispatch(sessionActions.updateSocketOpened(false));
      }
    };

    socket.onerror = () => {
      attemptReconnect();
    };
  };

  useEffect(() => {
    trackingModeRef.current = trackingMode;
  }, [trackingMode]);

  useEffect(() => {
    replayItemsRef.current = replayItems;
  }, [replayItems]);

  useEffect(() => {
    selectedDeviceIdRef.current = selectedDeviceId;
  }, [selectedDeviceId]);

  useEffect(() => {
    devicesRef.current = devices;
  }, [devices]);

  useEffect(() => {
    serverRef.current = server;
  }, [server]);

  useEffect(() => {
    eventsSoundOnRef.current = eventsSoundOn;
  }, [eventsSoundOn]);

  useEffect(() => {
    closedDevicesRef.current = closedDevices;
  }, [stateSyncClosedDevices]);

  useEffectAsync(async () => {
    const response = await fetch('/api/server');
    if (response.ok) {
      const json = await response.json();
      dispatch(sessionActions.updateServer(json));
      if (window.location.protocol === 'https:') {
        urlsProtocolCheck(dispatch, t, json);
      }
    }
  }, []);

  useEffectAsync(async () => {
    if (authenticated) {
      const response = await fetch('/api/devices?isActive=true');
      if (response.ok) {
        const items = await response.json();
        dispatch(devicesActions.refresh(items));
        dispatch(tailActions.refreshDevices(items));
        dispatch(devicesActions.init(true));

        const devicesWithoutPosition = [];
        items.forEach((item) => {
          if (!item.positionId) {
            if (item.attributes.latitude && item.attributes.longitude) {
              devicesWithoutPosition.push({
                attributes: {},
                deviceId: item.id,
                latitude: item.attributes.latitude,
                longitude: item.attributes.longitude,
                accuracy: 99,
              });
            }
          }
        });
        dispatch(positionsActions.update(devicesWithoutPosition));
      } else if (response.status === 401) {
        logout(history, dispatch);
      }
      const responseAllowed = await fetch(`/api/session/allowed?jsessionid=${cookies.JSESSIONID}`);
      if (responseAllowed.ok) {
        const allowed = await responseAllowed.json();
        if (allowed) {
          connectSocket();
          return () => {
            const socket = socketRef.current;
            if (socket) {
              socket.close();
            }
          };
        }
        dispatch(errorsActions.push(t('limitUsersErrorMsg')));
      } else if (response.status === 401) {
        logout(history, dispatch);
      }
      return null;
    }
    const response = await fetch('/api/session');
    if (response.ok) {
      dispatch(sessionActions.updateUser(await response.json()));
    } else {
      if (response.status === 400) {
        dispatch(sessionActions.updateTimeExpired(true));
      }
      logout(history, dispatch);
    }
    return null;
  }, [authenticated]);

  return null;
};

export default connect()(SocketController);
