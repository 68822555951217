/* eslint-disable no-bitwise */
export default function darkenColor(color, percent) {
  if (color[0] === '#') {
    color = color.substring(1);
  }

  let r = parseInt(color.substring(0, 2), 16);
  let g = parseInt(color.substring(2, 4), 16);
  let b = parseInt(color.substring(4, 6), 16);

  r = Math.max(0, Math.floor(r - (r * (percent / 100))));
  g = Math.max(0, Math.floor(g - (g * (percent / 100))));
  b = Math.max(0, Math.floor(b - (b * (percent / 100))));

  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;
}
