import { destination } from '@turf/turf';

export default function generateArrowPolygon(centerCoordinates, direction = 0, arrowSize) {
  const firstPoint = destination(centerCoordinates, arrowSize, direction);
  const secondPoint = destination(centerCoordinates, arrowSize / 2, direction - 90);
  const thirdPoint = destination(secondPoint, arrowSize / 4, direction + 90);
  const fourthPoint = destination(thirdPoint, arrowSize, direction + 180);
  const fifthPoint = destination(fourthPoint, arrowSize / 2, direction + 90);
  const sixthPoint = destination(fifthPoint, arrowSize, direction);
  const seventhPoint = destination(sixthPoint, arrowSize / 4, direction + 90);

  const arrowCoordinates = [
    firstPoint.geometry.coordinates,
    secondPoint.geometry.coordinates,
    thirdPoint.geometry.coordinates,
    fourthPoint.geometry.coordinates,
    fifthPoint.geometry.coordinates,
    sixthPoint.geometry.coordinates,
    seventhPoint.geometry.coordinates,
    firstPoint.geometry.coordinates,
  ];

  return arrowCoordinates;
}
