import React, { useState, useEffect } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {
  Accordion, AccordionSummary, AccordionDetails, List, ListItem, Checkbox, FormControlLabel, Container, FormControl, CircularProgress, Button,
  Typography, IconButton, makeStyles,
  useTheme,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { useDispatch } from 'react-redux';
import OptionsLayout from './OptionsLayout';
import icons from '../../common/static/icons';
import { useTranslation } from '../../common/components/LocalizationProvider';
import { toTitleCase } from '../../common/utils/formatter';
import { useEffectAsync } from '../../common/utils/reactHelper';
import { iconsCoverter } from '../../common/utils/converter';
import { errorsActions, iconsActions } from '../../store';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: '#fff',
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[4],
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    '& > *': {
      flexBasis: '33%',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
}));

export default function IconsCatalogPage() {
  const classes = useStyles();
  const history = useHistory();
  const t = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();

  const [isLoaded, setIsLoaded] = useState(false);
  const [progress, setProgress] = useState(0);
  const [iconsState, setIconsState] = useState({});
  const [iconsList, setIconsList] = useState([]);
  const [expanded, setExpanded] = useState([]);

  useEffectAsync(async () => {
    const response = await fetch('/api/icons');
    if (response.ok) {
      const data = await response.json();
      setIconsState(iconsCoverter(data) || {});
    }
    setIsLoaded(true);
  }, []);

  const getActiveIconsList = () => {
    const arr = [];
    Object.keys(iconsState).forEach((el) => {
      const iconName = iconsState[el].icon;
      if (!arr.includes(iconName)) {
        arr.push(iconName);
      }
    });
    return arr;
  };

  useEffect(() => {
    setIconsList(getActiveIconsList());
  }, [iconsState]);

  const handleIconToggle = (category, icon) => {
    const iconKey = `${category}_${icon}`;
    setIconsList((prevList) => {
      const newList = prevList.includes(iconKey)
        ? prevList.filter((item) => item !== iconKey) // удаляем
        : [...prevList, iconKey]; // добавляем
      return newList;
    });
  };

  const handleCategoryToggle = (category) => {
    const iconsInCategory = Object.keys(icons[category]);
    const allEnabled = iconsInCategory.every((icon) => iconsList.includes(`${category}_${icon}`));

    setIconsList((prevList) => {
      if (allEnabled) {
        return prevList.filter((icon) => !iconsInCategory.includes(icon.replace(`${category}_`, '')));
      }
      const iconsToAdd = iconsInCategory.map((icon) => `${category}_${icon}`);
      return [...new Set([...prevList, ...iconsToAdd])];
    });
  };

  const handleAccordionToggle = (category) => {
    setExpanded((prevExpanded) => {
      if (prevExpanded.includes(category)) {
        return prevExpanded.filter((item) => item !== category);
      }
      return [...prevExpanded, category];
    });
  };

  const returnBackOrCloseWindow = () => {
    try {
      if (new URLSearchParams(window.location.search)?.has('isNewTab')) {
        window.close();
      }
    } catch (error) {
      console.warn('Ошибка при проверки строки поиска.', error);
    }
    history.push('/settings/icons');
  };

  const handleBackButtonClick = () => {
    history.goBack();
  };

  const handleSave = async () => {
    try {
      setProgress(1);
      const newIconsState = { ...iconsState };

      const createPromises = iconsList.map(async (icon) => {
        if (!Object.values(iconsState).some((pseudo) => pseudo.icon === icon)) {
          const newItem = {
            attributes: { source: 'SmartTrack' },
            color: '#000000',
            name: icon,
            icon,
          };

          const response = await fetch('/api/icons', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(newItem),
          });

          if (response.ok) {
            const result = await response.json();
            const newIconsState = iconsCoverter([result]);

            newIconsState[icon] = {
              ...newIconsState[icon],
            };
          } else {
            dispatch(errorsActions.push(`${t('errorSaveIcons')} - ${response.status}`));
          }
        }
      });

      const deletedIds = [];
      Object.keys(iconsState).forEach((key) => {
        if (!iconsList.includes(iconsState[key].icon)) {
          deletedIds.push(iconsState[key].id);
          delete newIconsState[key];
        }
      });

      let deletePromise;
      if (deletedIds.length > 0) {
        deletePromise = fetch('/api/icons/bulk', {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ ids: deletedIds }),
        }).then((response) => {
          if (!response.ok) {
            dispatch(errorsActions.push(`${t('errorSaveIcons')} - ${response.status}`));
          }
        });
      } else {
        deletePromise = Promise.resolve();
      }

      await Promise.all([...createPromises, deletePromise]);

      setIconsState(newIconsState);
      dispatch(iconsActions.init(newIconsState));
      setProgress(0);
      history.push('/settings/icons');
    } catch (error) {
      dispatch(errorsActions.push(t('errorSaveIcons')));
    }
  };

  const getCheckboxColor = (category) => {
    const iconsInCategory = Object.keys(icons[category]);
    const allEnabled = iconsInCategory.every((icon) => iconsList.includes(`${category}_${icon}`));
    const noneEnabled = iconsInCategory.every((icon) => !iconsList.includes(`${category}_${icon}`));

    if (allEnabled) {
      return '#4caf50';
    } if (noneEnabled) {
      return '';
    }
    return '#e37c1b';
  };

  return (
    <OptionsLayout>
      <Container maxWidth="xs" className={classes.container}>

        <div className={classes.header}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleBackButtonClick}
            className={classes.backButton}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography>{t('settingsIcons')}</Typography>
        </div>

        {!isLoaded
          ? (
            <div style={{ display: 'flex', justifyContent: 'center', padding: theme.spacing(2) }}>
              <CircularProgress />
            </div>
          )
          : (
            <>
              {Object.keys(icons).map((category) => (
                <Accordion
                  key={category}
                  expanded={expanded.includes(category)}
                  onChange={() => handleAccordionToggle(category)}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <FormControlLabel
                      control={(
                        <Checkbox
                          checked={Object.keys(icons[category]).some((icon) => iconsList.includes(`${category}_${icon}`))}
                          onChange={(e) => {
                            e.stopPropagation();
                            handleCategoryToggle(category);
                          }}
                          style={{ color: getCheckboxColor(category) }}
                        />
                      )}
                      label={t(`iconsCategory${toTitleCase(category)}`)}
                      onClick={(e) => e.stopPropagation()}
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    <List style={{ display: 'flex', flexWrap: 'wrap' }}>
                      {Object.keys(icons[category]).map((icon) => (
                        <ListItem
                          key={icon}
                          onClick={() => handleIconToggle(category, icon)}
                          style={{
                            cursor: 'pointer',
                            opacity: iconsList.includes(`${category}_${icon}`) ? 1 : 0.3,
                            padding: 8,
                            margin: 4,
                            borderRadius: 4,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: 52,
                            height: 52,
                          }}
                          title={`${category}_${icon}`}
                        >
                          <img
                            src={`/images/icon/device/${category}_${icon}.svg`}
                            alt={`${category}_${icon}`}
                            style={{ width: '100%', height: '100%' }}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </AccordionDetails>
                </Accordion>
              ))}
            </>
          )}
        <FormControl fullWidth margin="normal">
          <div className={classes.buttons}>
            <Button color="primary" variant="outlined" onClick={returnBackOrCloseWindow} disabled={!!progress}>
              {t('sharedCancel')}
            </Button>
            <Button
              color="primary"
              variant={progress ? 'outlined' : 'contained'}
              onClick={handleSave}
              disabled={!!progress || !isLoaded}
            >
              {progress ? <CircularProgress /> : t('sharedSave')}
            </Button>
          </div>
        </FormControl>
      </Container>
    </OptionsLayout>
  );
}
