import icons from '../../common/static/icons';
import store from '../../store';

const oldIconsConverted = {
  airplane_silhouette: 'военные_самолет_1',
  plane: 'транспорт_самолет_1',
  uav_big: 'военные_самолет_2',
  uav_plane: 'транспорт_самолет_2',
  uav_wing: 'военные_самолет_3',
  bicycle: 'транспорт_велосипед_1',
  boat: 'транспорт_лодка_1',
  bus: 'транспорт_автобус_1',
  car: 'транспорт_автомобиль_1',
  pickup: 'транспорт_автомобиль_2',
  crane: 'транспорт_кран_1',
  helicopter: 'транспорт_вертолет_1',
  helicopter_silhouette: 'транспорт_вертолет_2',
  motorcycle: 'транспорт_мотоцикл_1',
  offroad: 'транспорт_джип_1',
  scooter: 'транспорт_скутер_1',
  ship: 'транспорт_корабль_1',
  vessel: 'транспорт_корабль_2',
  submarine: 'военные_подводка_1',
  tank_canon: 'военные_танк_1',
  tank_gun: 'военные_танк_2',
  tank_torpedo: 'военные_танк_3',
  tractor: 'транспорт_трактор_1',
  train: 'транспорт_поезд_1',
  tram: 'транспорт_трамвай_1',
  trolleybus: 'транспорт_троллейбус_1',
  truck: 'транспорт_грузовик_1',
  van: 'транспорт_грузовик_2',
  uav: 'транспорт_коптер_1',
  uav_copter: 'транспорт_коптер_2',

  alert_circle: 'знаки_внимание_1',
  default: 'знаки_поУмолчанию_1',
  location: 'знаки_навигатор_1',
  navigation: 'знаки_навигатор_1',
  message_circle: 'знаки_сообщение_1',
  unknown: 'знаки_вопрос_1',
  viewfinder: 'знаки_цель_1',

  animal: 'знаки_собака_1',
  bird: 'знаки_птица_1',
  birds: 'знаки_птицы_1',
  decoy: 'знаки_рыба_1',

  anti_aircraft_gun: 'военные_пушка_1',
  cannon: 'военные_пушка_2',
  bunker: 'военные_бункер_1',

  arrow_bounce: 'фигуры_высадка_1',

  artificial_object: 'фигуры_прямоугольник_1',
  asterisk: 'фигуры_звезда_1',
  circle_dashed: 'фигуры_круг_1',
  hierarchy: 'фигуры_иерархия_1',
  pentagon: 'фигуры_пятиугольник_1',
  point: 'фигуры_точка_1',
  point_fill: 'фигуры_точка_2',
  square: 'фигуры_квадрат_1',
  square_rotated: 'фигуры_квадрат_2',
  square_arrow_up: 'фигуры_квадрат_3',
  square_dot: 'фигуры_квадрат_4',

  explosion: 'военные_взрыв_1',
  person: 'знаки_человек_1',
  run: 'знаки_бег_1',
  soldiers: 'военные_солдаты_1',
  users: 'знаки_люди_1',

  radar: 'устройства_радар_1',
  radar_dish: 'устройства_радар_2',
  rocket: 'военные_ракета_1',
  torpedo: 'военные_торпеда_1',
  remote_control: 'устройства_пульт_1',
  'wireless-charging-i': 'устройства_телефон_1',
  'wireless-charging-t': 'устройства_телефон_2',
  phone: 'устройства_телефон_3',
  phone2: 'устройства_телефон_4',
  phone3: 'устройства_телефон_5',
  phone4: 'устройства_телефон_6',
  radio: 'устройства_радио_1',
  station: 'устройства_станция_1',
  station2: 'устройства_станция_2',
};

function splitString(str) {
  if (!str) return false;
  const index = str?.indexOf('_');

  if (index === -1) {
    return false;
  }

  const part1 = str.slice(0, index);
  const part2 = str.slice(index + 1);

  return [part1, part2];
}

export default function getIcon(name, defaultColor = '#000000') {
  const iconsState = store.getState().icons.items;
  const iconData = iconsState[name]?.icon || oldIconsConverted[name] || name;

  const splitedStr = splitString(iconData);
  if (splitedStr && icons?.[splitedStr[0]]?.[splitedStr[1]]) {
    return {
      icon: iconData,
      color: iconsState[name]?.color || defaultColor,
      canRotate: icons[splitedStr[0]][splitedStr[1]].canRotate,
    };
  }
  if (oldIconsConverted[iconData]) {
    const splitedStr = splitString(oldIconsConverted[iconData]);
    return {
      icon: oldIconsConverted[iconData],
      color: defaultColor,
      canRotate: icons[splitedStr[0]][splitedStr[1]].canRotate,
    };
  }

  return { icon: 'знаки_поУмолчанию_1', color: defaultColor, canRotate: false };
}
