import sector from '@turf/sector';
import { buffer, lineString } from '@turf/turf';
import { map } from '../Map';
import { errorsActions } from '../../store';
import orderLayers from '../../common/static/orderLayers';
import generateArrowPolygon from './generateArrowPolygon';

// 3D сферы, 3D сектора
// import * as THREE from 'three';
// import maplibregl from 'maplibre-gl';
// const modelOrigin = [30.4067, 60.02203];
// const modelAltitude = 20;
// const modelRotate = [Math.PI / 2, 0, 0];

// const modelAsMercatorCoordinate = maplibregl.MercatorCoordinate.fromLngLat(
//   modelOrigin,
//   modelAltitude,
// );

// const modelTransform = {
//   translateX: modelAsMercatorCoordinate.x,
//   translateY: modelAsMercatorCoordinate.y,
//   translateZ: modelAsMercatorCoordinate.z,
//   rotateX: modelRotate[0],
//   rotateY: modelRotate[1],
//   rotateZ: modelRotate[2],
//   scale: modelAsMercatorCoordinate.meterInMercatorCoordinateUnits(),
// };

// // Функция для создания полусферы
// function createHemisphere(radius = 1, color = 0x0077ff, opacity = 1, directionDeg = 0, widthDeg = 60) {
//   const direction = THREE.MathUtils.degToRad((-directionDeg - 90) - widthDeg / 2);
//   const width = THREE.MathUtils.degToRad(widthDeg);

//   const geometry = new THREE.SphereGeometry(
//     radius, // радиус
//     16, // (качество прорисовки)
//     32, // (качество прорисовки)
//     direction, // направление
//     width, // ширина
//     0, // начальный угол по высоте
//     Math.PI / 2, // деление сферы пополам
//   );

//   // Материал для полусферы
//   const material = new THREE.MeshStandardMaterial({
//     color,
//     transparent: opacity < 1,
//     opacity,
//     emissive: color,
//     side: THREE.DoubleSide,
//   });

//   const hemisphere = new THREE.Mesh(geometry, material);

//   if (widthDeg < 360) {
//     const sideMaterial = new THREE.MeshStandardMaterial({
//       color,
//       transparent: opacity < 1,
//       opacity,
//       side: THREE.DoubleSide,
//     });

//     // стенки
//     const circleGeometry = new THREE.CircleGeometry(radius, 32, 0, Math.PI / 2);

//     const cap1 = new THREE.Mesh(circleGeometry, sideMaterial);
//     cap1.rotation.y = THREE.MathUtils.degToRad(((-directionDeg + 90) + widthDeg / 2));

//     const cap2 = new THREE.Mesh(circleGeometry, sideMaterial);
//     cap2.rotation.y = THREE.MathUtils.degToRad(((-directionDeg + 90) - widthDeg / 2));

//     const group = new THREE.Group();
//     group.add(hemisphere);
//     group.add(cap1);
//     group.add(cap2);

//     return group;
//   }

//   return hemisphere;
// }

// const customLayer = {
//   id: '3d-model',
//   type: 'custom',
//   renderingMode: '3d',
//   onAdd(map, gl) {
//     this.camera = new THREE.Camera();
//     this.scene = new THREE.Scene();

//     const hemisphere = createHemisphere(1300, 0x074eb8, 0.3, 10, 120); //радиус, цвет, прозрачность, направление, ширина покрытия
//     this.scene.add(hemisphere);

//     this.map = map;

//     // добавление на карту
//     this.renderer = new THREE.WebGLRenderer({
//       canvas: map.getCanvas(),
//       context: gl,
//       antialias: true,
//     });

//     this.renderer.autoClear = false;
//   },
//   render(gl, matrix) {
//     const rotationX = new THREE.Matrix4().makeRotationAxis(
//       new THREE.Vector3(1, 0, 0),
//       modelTransform.rotateX,
//     );
//     const rotationY = new THREE.Matrix4().makeRotationAxis(
//       new THREE.Vector3(0, 1, 0),
//       modelTransform.rotateY,
//     );
//     const rotationZ = new THREE.Matrix4().makeRotationAxis(
//       new THREE.Vector3(0, 0, 1),
//       modelTransform.rotateZ,
//     );

//     const m = new THREE.Matrix4().fromArray(matrix);
//     const l = new THREE.Matrix4()
//       .makeTranslation(
//         modelTransform.translateX,
//         modelTransform.translateY,
//         modelTransform.translateZ,
//       )
//       .scale(
//         new THREE.Vector3(
//           modelTransform.scale,
//           -modelTransform.scale,
//           modelTransform.scale,
//         ),
//       )
//       .multiply(rotationX)
//       .multiply(rotationY)
//       .multiply(rotationZ);

//     this.camera.projectionMatrix = m.multiply(l);
//     this.renderer.resetState();
//     this.renderer.render(this.scene, this.camera);
//     this.map.triggerRepaint();
//   },
// };

// map.on('style.load', () => {
//   map.addLayer(customLayer, '');
// });

export const getBeforeId = (currentId) => {
  const indexId = orderLayers.findIndex((v) => currentId === v);
  if (indexId + 1 !== orderLayers.length) {
    // eslint-disable-next-line no-restricted-syntax
    for (const layer of orderLayers.slice(indexId + 1)) {
      if (map.getLayer(layer)) {
        return layer;
      }
    }
  }
  return '';
};

const createFeature = (type, color) => (
  {
    lineOpacity: type === 'timeGap' ? 0.3 : 1,
    color,
  }
);

export const createGeojsonPath = (id) => {
  map.addSource(id, {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [],
    },
  });
  map.addLayer({
    source: id,
    id,
    type: 'line',
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': ['get', 'color'],
      'line-width': 4,
      'line-dasharray': [2, 2],
      'line-opacity': { type: 'identity', property: 'lineOpacity' },
    },
  }, getBeforeId(id));

  return () => {
    if (map.getLayer(id)) {
      map.removeLayer(id);
    }
    if (map.getSource(id)) {
      map.removeSource(id);
    }
  };
};

export const createGeojson3DPath = (id) => {
  map.addSource(id, {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [],
    },
  });

  map.addLayer({
    source: id,
    id,
    type: 'fill-extrusion',
    paint: {
      'fill-extrusion-color': ['get', 'color'],
      'fill-extrusion-height': { type: 'identity', property: 'altitude' },
      'fill-extrusion-base': { type: 'identity', property: 'minAltitude' },
      'fill-extrusion-opacity': 0.5,
    },
  }, getBeforeId(id));

  return () => {
    if (map.getLayer(id)) {
      map.removeLayer(id);
    }
    if (map.getSource(id)) {
      map.removeSource(id);
    }
  };
};

export const createArrow3DPath = (id) => {
  map.addSource(id, {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [],
    },
  });

  map.addLayer({
    source: id,
    id,
    type: 'fill-extrusion',
    paint: {
      'fill-extrusion-color': ['get', 'color'],
      'fill-extrusion-height': { type: 'identity', property: 'altitude' },
      'fill-extrusion-base': { type: 'identity', property: 'minAltitude' },
      'fill-extrusion-opacity': 0.9,
    },
  }, getBeforeId(id));

  return () => {
    if (map.getLayer(id)) {
      map.removeLayer(id);
    }
    if (map.getSource(id)) {
      map.removeSource(id);
    }
  };
};

const transformCoordinates = (coordinates) => {
  const result = [];
  for (let i = 0; i < coordinates.length - 1; i++) {
    result.push([coordinates[i], coordinates[i + 1]]);
  }
  return result;
};

const linesToPolygonsCustom = (linesCoordinates) => {
  const bufferDistance = 0.01;
  return linesCoordinates.map((lineCoordinates) => {
    const line = lineString(lineCoordinates);
    const bufferr = buffer(line, bufferDistance);
    return bufferr.geometry.coordinates[0];
  });
};

export const pathDataFromPositions = (values, devices, onLine3D, onLine) => {
  const features = [];
  const featured3D = [];
  const featureArrows3D = [];

  Object.keys(values).forEach((id) => {
    values[id].forEach((position) => {
      let typePath;
      let path = position;
      if (!Array.isArray(position)) {
        [typePath] = Object.keys(position);
        path = position[typePath];
      }
      const coord = path.map((item) => item?.longitude && [item.longitude, item.latitude]);

      // линий на земле
      if (!devices[id]?.attributes.showIn3D || (devices[id]?.attributes.showIn3D && ((onLine3D === 3 || (onLine === 2))))) {
        features.push({
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: coord,
          },
          properties: createFeature(typePath, devices[id]?.attributes.color),
        });
      }

      // 3D линии со стрелочками
      if (devices[id]?.attributes.showIn3D && onLine === 1) {
        const transformed = transformCoordinates(coord);
        const polygonsCoordinates = linesToPolygonsCustom(transformed);

        polygonsCoordinates.forEach((coordinates, index) => {
          featured3D.push({
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: [coordinates],
            },
            properties: {
              color: createFeature(typePath, devices[id]?.attributes.color).color || '#074eb8',
              altitude: path[index].altitude + 10,
              minAltitude: path[index].altitude,
            },
          });
        });

        coord.forEach((coordinates, index) => {
          const arrowPolygonGeoJSON = generateArrowPolygon(coordinates, path[index].course, 0.008);
          featureArrows3D.push({
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: [arrowPolygonGeoJSON],
            },
            properties: {
              altitude: path[index].altitude + 8,
              minAltitude: path[index].altitude + 1,
            },
          });
        });
      }
    });
  });

  return (
    {
      lines: {
        type: 'FeatureCollection',
        features,
      },
      lines3D: {
        type: 'FeatureCollection',
        features: featured3D,
      },
      arrows3D: {
        type: 'FeatureCollection',
        features: featureArrows3D,
      },
    }
  );
};

const requestMatchOSRM = async (line, movement, dispatch) => {
  const positions = line.filter((position) => position.accuracy < 100);
  if (positions.length < 2) {
    return [];
  }

  const query = new URLSearchParams({
    coordinates: positions.map((position) => `${position.longitude},${position.latitude}`).join(';'),
    timestamps: positions.map((position) => parseInt(new Date(position.fixTime).valueOf() / 1000, 10)).join(';'),
    radiuses: positions
      .map((position) => (position.accuracy && position.accuracy !== 99 && position.accuracy < 20
        ? position.accuracy.toFixed(2) : 20)).join(';'),
    movement: movement || 'driving',
  });

  try {
    const response = await fetch(`/api/osrm/match?${query.toString()}`);
    const points = [];
    if (response.ok) {
      const data = await response.json();
      data.matchings.forEach((match) => {
        points.push(...match.geometry.coordinates.map((position) => ({
          longitude: position[0],
          latitude: position[1],
        })));
      });
    } else {
      return false;
    }
    return points;
  } catch {
    dispatch(errorsActions.push('OSRM сервис недоступен'));
    console.warn('OSRM сервис недоступен');
    return false;
  }
};

export const getCoordinatesFromOSRM = async (dispatch, line, deviceName, movement) => {
  const coordinatesCount = 100;
  const partsCount = Math.floor(line.length / coordinatesCount);
  const coordinates = [];

  for (let i = 0; i < partsCount; i += 1) {
    // eslint-disable-next-line no-await-in-loop
    const result = await requestMatchOSRM(line.slice(i * coordinatesCount, (i + 1) * coordinatesCount), movement, dispatch);

    if (result === false) {
      dispatch(errorsActions.push(`Не удалось привязать трек к дорожной сети для устройства: ${deviceName}`));
      return line;
    }

    coordinates.push(...result);
  }

  const lastResult = await requestMatchOSRM(line.slice(partsCount * coordinatesCount), movement, dispatch);

  if (lastResult === false) {
    dispatch(errorsActions.push(`Не удалось привязать трек к дорожной сети для устройства: ${deviceName}`));
    return line;
  }

  coordinates.push(...lastResult);
  return coordinates;
};

const getNumber = (num, defaultNum = 0) => (Number(num) || defaultNum);

export const createRadarFeature = (longitude, latitude, altitude, showIn3D, radius, azimuth, diagram, color, theme, category, opacity) => {
  if (category === 'sector' && opacity === 0) {
    return {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'Polygon',
        coordinates: [],
      },
    };
  }
  const feature = sector(
    [longitude, latitude],
    getNumber(radius, 1) / 1000,
    getNumber(azimuth) - getNumber(diagram) / 2,
    getNumber(azimuth) + getNumber(diagram) / 2,
    { steps: 35 },
  );
  feature.properties = {
    category: category ?? 'radar',
    showIn3D: showIn3D || false,
    radius: getNumber(radius, 1) / 1000,
    b1: getNumber(azimuth) - getNumber(diagram) / 2,
    b2: getNumber(azimuth) + getNumber(diagram) / 2,
    longitude,
    latitude,
    altitude: (altitude || 0) + 500,
    minAltitude: (altitude || 0),
    alarm: false,
    timer: 0,
    radarColor: color || theme.palette.tracks.replay0,
    opacity: opacity ?? 0.25,
  };
  return feature;
};
