import React from 'react';
import { useHistory } from 'react-router-dom';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import TimelineIcon from '@material-ui/icons/Timeline';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import { useDispatch } from 'react-redux';
import {
  Paper, BottomNavigation, BottomNavigationAction, makeStyles,
} from '@material-ui/core';
import { useTranslation } from './LocalizationProvider';
import { positionsActions } from '../../store';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'fixed',
    zIndex: 1300,
    marginTop: 10,
    left: theme.spacing(1.5),
    bottom: theme.spacing(1.5),
    width: theme.dimensions.drawerWidthDesktop,
    [theme.breakpoints.down('xs')]: {
      bottom: theme.spacing(0),
      left: '0px',
      width: '100%',
    },
  },
}));

const BottomMenu = ({ setOpenConfirmExit }) => {
  const classes = useStyles();
  const history = useHistory();
  const t = useTranslation();
  const dispatch = useDispatch();

  const handleSelection = async (_, value) => {
    switch (value) {
      case 0:
        history.push('/replay');
        dispatch(positionsActions.unselectPosition());
        break;
      case 1:
        history.push('/reports');
        break;
      case 2:
        history.push('/settings');
        break;
      case 3:
        setOpenConfirmExit(true);
        break;
      default:
        break;
    }
  };

  return (
    <Paper square elevation={3} className={classes.container}>
      <BottomNavigation
        onChange={handleSelection}
        showLabels
      >
        <BottomNavigationAction label={t('reportRoute')} icon={<TimelineIcon />} />
        <BottomNavigationAction label={t('reportTitle')} icon={<DescriptionOutlinedIcon />} />
        <BottomNavigationAction label={t('settingsTitle')} icon={<SettingsOutlinedIcon />} />
        <BottomNavigationAction label={t('loginLogout')} icon={<LogoutIcon />} />
      </BottomNavigation>
    </Paper>
  );
};

export default BottomMenu;
