import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Checkbox,
  Divider,
  FormControlLabel,
  Slider,
  makeStyles,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Tune as TuneIcon, Undo as UndoIcon } from '@material-ui/icons';
import { getExtraMapId } from '../utils/formatter';
import { useTranslation } from './LocalizationProvider';
import { errorsActions } from '../../store';
import FreeModalWindow from './FreeModalWindow';
import CustomColorPicker from './CustomColorPicker';
import theme from '../theme';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '0 auto',
    width: 'max-content',
    opacity: 0.8,
  },
  title: {
    display: 'flex',
  },
  text: {
    margin: 'auto',
  },
  formControl: {
    padding: theme.spacing(1, 2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  textField: {
    width: 110,
  },
  space: {
    marginLeft: 5,
  },
  mapItemBlock: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  settingsBlock: {
    marginTop: 10,
    width: 'calc(100% - 50px)',
    marginLeft: 25,
    marginRight: 25,
  },
  slidersBlock: {
    // maxHeight: '60vh',
    // overflowY: 'scroll',
    // overflowX: 'hidden',
  },
  tuneIcon: {
    marginLeft: theme.spacing(1),
  },
}));

const OpacityMapSliders = ({ opacityMaps, setOpacityMaps, setOpacityMapsOpen }) => {
  const classes = useStyles();
  const t = useTranslation();
  const extraMaps = useSelector((state) => state.session.server.attributes.extraMaps ?? []);
  const dispatch = useDispatch();

  const [currentMap, setCurrentMap] = useState(null);
  const [isLayerSettingsOpen, setLayerSettingsOpen] = useState(false);

  const handleClose = () => {
    setOpacityMapsOpen(false);
  };

  const handleUpdateParams = (key, newValue, mapId) => {
    const extraMapId = getExtraMapId(mapId);
    setOpacityMaps({ ...opacityMaps, [extraMapId]: { ...opacityMaps[extraMapId], [key]: newValue } });
  };

  const handleCheckbox = (e, mapId) => {
    const extraMapId = getExtraMapId(mapId);
    const extraMapsEl = extraMaps.find((item) => item.id === mapId);
    if (extraMapsEl.params.tiles.length) {
      setOpacityMaps({
        ...opacityMaps,
        [extraMapId]: {
          ...opacityMaps[extraMapId],
          s: e.target.checked,
          opacity: opacityMaps[extraMapId]?.opacity === undefined ? 1 : opacityMaps[extraMapId]?.opacity,
        },
      });
    } else {
      dispatch(errorsActions.push(`${extraMapsEl.name}: ${t('errorExtraMapUrl')}`));
      setOpacityMaps({ ...opacityMaps, [extraMapId]: { ...opacityMaps[extraMapId], s: false } });
    }
  };

  const setNewDefaultPosition = (position) => {
    const freeModalWindow = JSON.parse(localStorage.getItem('freeModalWindow') || '{}');
    freeModalWindow.opacityMapSliders = position;
    localStorage.setItem('freeModalWindow', JSON.stringify(freeModalWindow));
  };

  const getDefaultPosition = () => {
    const freeModalWindow = JSON.parse(localStorage.getItem('freeModalWindow') || '{}');
    return freeModalWindow.opacityMapSliders;
  };

  const openLayerSettings = (map) => {
    setCurrentMap(map);
    setLayerSettingsOpen(true);
  };

  const closeLayerSettings = () => {
    setLayerSettingsOpen(false);
    setCurrentMap(null);
  };

  return (
    <FreeModalWindow
      enableScroll
      enableResizable
      disableBodyPadding
      onCloseModalWindow={handleClose}
      title={t('opacityMapsTitle')}
      defaultWidth={320}
      defaultHeight={500}
      defaultPosition={getDefaultPosition()}
      setNewDefaultPosition={setNewDefaultPosition}
    >
      {isLayerSettingsOpen && currentMap ? (
        <div>
          <Divider />
          <div className={classes.mapItemBlock}>

            <Checkbox checked={opacityMaps[getExtraMapId(currentMap.id)]?.s ?? false} size="small" onClick={(e) => handleCheckbox(e, currentMap.id)} />
            <Typography>{currentMap.name}</Typography>
            <IconButton onClick={closeLayerSettings}>
              <UndoIcon />
            </IconButton>
          </div>
          <Divider />

          <div className={classes.settingsBlock}>
            <Typography>{t('opacityMapsOpacity')}</Typography>
            <Slider
              value={opacityMaps[getExtraMapId(currentMap.id)]?.opacity === undefined ? 1 : opacityMaps[getExtraMapId(currentMap.id)]?.opacity}
              onChange={(_, newValue) => handleUpdateParams('opacity', newValue, currentMap.id)}
              min={0}
              max={1}
              step={0.01}
            />
            <Divider />

            {(currentMap.params?.type === 'GeoJson')
              && (
                <>
                  <Typography>{t('opacityMapsColor')}</Typography>
                  <CustomColorPicker
                    color={opacityMaps[getExtraMapId(currentMap.id)]?.color ?? currentMap.params.color ?? theme.palette.clusters.red}
                    setColor={(e) => handleUpdateParams('color', e.toLowerCase(), currentMap.id)}
                    presetColors={theme.palette.clusters}
                  />
                  <Divider />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        disabled={opacityMaps[getExtraMapId(currentMap.id)]?.onCluster ?? currentMap.params.onCluster ?? true}
                        checked={opacityMaps[getExtraMapId(currentMap.id)]?.heatMap ?? currentMap.params.heatMap ?? false}
                        onChange={(e) => handleUpdateParams('heatMap', e.target.checked, currentMap.id)}
                      />
                    )}
                    label={t('opacityMapsHeatmap')}
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        disabled={opacityMaps[getExtraMapId(currentMap.id)]?.heatMap ?? currentMap.params.heatMap ?? false}
                        checked={opacityMaps[getExtraMapId(currentMap.id)]?.onCluster ?? currentMap.params.onCluster ?? true}
                        onChange={(e) => handleUpdateParams('onCluster', e.target.checked, currentMap.id)}
                      />
                    )}
                    label={t('opacityMapsCluster')}
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={opacityMaps[getExtraMapId(currentMap.id)]?.autoRefresh ?? currentMap.params.autoRefresh ?? false}
                        onChange={(e) => handleUpdateParams('autoRefresh', e.target.checked, currentMap.id)}
                      />
                    )}
                    label={t('opacityMapsAutoRefresh')}
                  />
                  <Divider />

                  {(opacityMaps[getExtraMapId(currentMap.id)]?.heatMap ?? currentMap.params.heatMap ?? false)
                    && (
                      <>
                        <Typography>{t('opacityMapsIntensity')}</Typography>
                        <Slider
                          value={opacityMaps[getExtraMapId(currentMap.id)]?.intensity === undefined ? 1 : opacityMaps[getExtraMapId(currentMap.id)]?.intensity}
                          onChange={(_, newValue) => handleUpdateParams('intensity', newValue, currentMap.id)}
                          min={0.1}
                          max={3}
                          step={0.1}
                        />
                        <Divider />

                        <Typography>{t('opacityMapsRadius')}</Typography>
                        <Slider
                          value={opacityMaps[getExtraMapId(currentMap.id)]?.radius === undefined ? 20 : opacityMaps[getExtraMapId(currentMap.id)]?.radius}
                          onChange={(_, newValue) => handleUpdateParams('radius', newValue, currentMap.id)}
                          min={5}
                          max={50}
                          step={1}
                        />
                      </>
                    )}
                </>
              )}
          </div>

        </div>
      ) : (
        <div className={classes.slidersBlock}>
          {extraMaps.map((map, ind) => (
            <div key={map.id}>
              <div className={classes.mapItemBlock}>
                <FormControlLabel
                  control={<Checkbox checked={opacityMaps[getExtraMapId(map.id)]?.s ?? false} size="small" onClick={(e) => handleCheckbox(e, map.id)} />}
                  label={map.name}
                />
                <IconButton className={classes.tuneIcon} onClick={() => openLayerSettings(map)}>
                  <TuneIcon />
                </IconButton>
              </div>
              {ind !== extraMaps.length - 1 && <Divider />}
            </div>
          ))}
        </div>
      )}
    </FreeModalWindow>
  );
};

export default OpacityMapSliders;
