import { parse, stringify } from 'wellknown';
import circle from '@turf/circle';
import theme from '../common/theme';

export const loadImage = (url) => new Promise((imageLoaded) => {
  const image = new Image();
  image.onload = () => imageLoaded(image);
  image.src = url;
});

const canvasTintImage = (image, color) => {
  const canvas = document.createElement('canvas');
  canvas.width = image.width * devicePixelRatio;
  canvas.height = image.height * devicePixelRatio;
  canvas.style.width = `${image.width}px`;
  canvas.style.height = `${image.height}px`;

  const context = canvas.getContext('2d');

  context.save();
  context.fillStyle = color;
  context.globalAlpha = 1;
  context.fillRect(0, 0, canvas.width, canvas.height);
  context.globalCompositeOperation = 'destination-atop';
  context.globalAlpha = 1;
  context.drawImage(image, 0, 0, canvas.width, canvas.height);
  context.restore();

  return canvas;
};

export const prepareIcon = (background, icon, config = {}) => {
  const canvas = document.createElement('canvas');
  if (background) {
    const resizer = config.downSize ? 0.5 : 1;
    canvas.width = background.width * devicePixelRatio * resizer;
    canvas.height = background.height * devicePixelRatio * resizer;
    canvas.style.width = `${background.width}px`;
    canvas.style.height = `${background.height}px`;
  } else {
    canvas.width = icon.width * devicePixelRatio;
    canvas.height = icon.height * devicePixelRatio;
    canvas.style.width = `${icon.width}px`;
    canvas.style.height = `${icon.height}px`;
  }
  const context = canvas.getContext('2d');
  if (background) {
    if (config?.colorBac) {
      context.drawImage(canvasTintImage(background, config.colorBac), 0, 0, canvas.width, canvas.height);
    } else {
      context.drawImage(background, 0, 0, canvas.width, canvas.height);
    }
    if (config.colorStroke) {
      context.strokeStyle = config.colorStroke;
      context.lineWidth = 3;
      context.arc(canvas.width / 2, canvas.height / 2, 20, 0, Math.PI * 2);
      context.stroke();
    }
  }
  if (icon) {
    const iconRatio = config?.downSize ? 1 : 0.5;
    const imageWidth = canvas.width * iconRatio;
    const imageHeight = canvas.height * iconRatio;
    if (config?.color) {
      context.drawImage(canvasTintImage(icon, config.color),
        (canvas.width - imageWidth) / 2, (canvas.height - imageHeight) / 2, imageWidth, imageHeight);
    } else {
      context.drawImage(icon,
        (canvas.width - imageWidth) / 2, (canvas.height - imageHeight) / 2, imageWidth, imageHeight);
    }
  }

  return context.getImageData(0, 0, canvas.width, canvas.height);
};

export const reverseCoordinates = (it) => {
  if (!it) {
    return it;
  } if (Array.isArray(it)) {
    if (it.length === 2 && !Number.isNaN(it[0]) && !Number.isNaN(it[1])) {
      return [it[1], it[0]];
    }
    return it.map((it) => reverseCoordinates(it));
  }
  return {
    ...it,
    coordinates: reverseCoordinates(it.coordinates),
  };
};

export const geofenceToFeature = (item) => {
  if (item.area.indexOf('CIRCLE') > -1) {
    const coordinates = item.area.replace(/CIRCLE|\(|\)|,/g, ' ').trim().split(/ +/);
    const options = { steps: 32, units: 'meters' };
    const polygon = circle([Number(coordinates[1]), Number(coordinates[0])], Number(coordinates[2]), options);
    return {
      id: item.id,
      type: 'Feature',
      geometry: polygon.geometry,
      properties: { name: item.name, color: (item.attributes.color ?? theme.palette.clusters.blue) },
    };
  }
  return {
    id: item.id,
    type: 'Feature',
    geometry: reverseCoordinates(parse(item.area)),
    properties: { name: item.name, color: (item.attributes.color ?? theme.palette.clusters.blue) },
  };
};

export const geometryToArea = (geometry) => stringify(reverseCoordinates(geometry));
