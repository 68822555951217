import {
  memo, useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { map } from './Map';
import {
  createArrow3DPath, createGeojson3DPath, createGeojsonPath, getCoordinatesFromOSRM, pathDataFromPositions,
} from './funcs/pathFuncs';

const ReplayPathMap = ({
  positions, tail, matching, devices, stateSyncPositions, onLine3D, onLine,
}) => {
  const [osrmTimeout, setOsrmTimeout] = useState(0);
  const [displaysPath, setDisplayesPath] = useState({});

  const osrmUrl = useSelector((state) => state.session.server?.attributes.services?.osrm?.url);
  const deviceToUpdate = useSelector((state) => state.tail.deviceToUpdate);
  const dispatch = useDispatch();

  const id = `path${matching ? '_matching' : ''}`;
  // const [colorsDevices, setColorsDevices] = useState({});

  const createCollection = (positions) => {
    const result = {};
    Object.keys(positions).forEach((id) => {
      const clearPosition = [];
      let lastPosition;
      let partPath = [];
      positions[id].forEach((position) => {
        if (lastPosition) {
          if (new Date(position.fixTime).getTime() - new Date(lastPosition.fixTime).getTime() > 3600000) {
            clearPosition.push(partPath);
            clearPosition.push({ timeGap: [lastPosition, position] });
            partPath = [];
          }
        }
        partPath.push(position);
        lastPosition = position;
      });
      if (partPath.length) {
        clearPosition.push(partPath);
      }
      result[id] = clearPosition;
    });
    return result;
  };

  // const getColors = () => {
  //   const colorsDevices = {};
  //   Object.values(devices)
  //     .forEach((device) => colorsDevices[device.id] = device.attributes.color);
  //   return colorsDevices;
  // };

  // useEffect(() => {
  //   setColorsDevices(getColors());
  // }, [stateSyncDevices]);

  useEffect(() => {
    const cleanupGeojsonPath = createGeojsonPath(id);

    const cleanupGeojson3DPath = createGeojson3DPath(`${id}-3D`);
    const cleanupArrow3DPath = createArrow3DPath(`${id}-3D-arrows`);
    return () => {
      if (cleanupGeojsonPath) {
        cleanupGeojsonPath();
      }
      if (cleanupGeojson3DPath) {
        cleanupGeojson3DPath();
        cleanupArrow3DPath();
      }
    };
  }, []);

  async function pathWithOsrm(positions) {
    const res = {};
    if (osrmUrl) {
      if (!tail || osrmTimeout === 0) {
        setOsrmTimeout(1);
        await Promise.all(Object.keys(positions).map(async (key) => {
          if (tail) {
            res[key] = await getCoordinatesFromOSRM(dispatch, positions[key], devices[key].name, devices[key].attributes.movement);
          } else {
            res[key] = [];
            await Promise.all(positions[key].map(async (line, i) => {
              if (Array.isArray(line)) {
                res[key][i] = await getCoordinatesFromOSRM(dispatch, line, devices[key].name, devices[key].attributes.movement);
              } else {
                res[key][i] = line;
              }
            }));
          }
        }));
        setOsrmTimeout(0);
      }
    }
    return res;
  }

  const loadDeviceData = async (collectionSource, deviceIds) => {
    const updatedDevices = {};
    deviceIds.forEach((deviceId) => {
      const filteredSource = collectionSource[deviceId];
      if (filteredSource) {
        updatedDevices[deviceId] = tail
          ? createCollection({ [deviceId]: filteredSource })[deviceId]
          : filteredSource;
      }
    });
    return updatedDevices;
  };

  const cleanObsoleteDevices = (currentDisplaysPath, currentPositions) => {
    const positionIds = Object.keys(currentPositions);
    const validDisplays = {};
    Object.keys(currentDisplaysPath).forEach((deviceId) => {
      if (positionIds.includes(deviceId)) {
        validDisplays[deviceId] = currentDisplaysPath[deviceId];
      }
    });
    return validDisplays;
  };

  const reloadAllDevices = async () => {
    let collectionSource = {};
    if (onLine === 1) {
      collectionSource = positions;
    } else if (onLine === 2) {
      collectionSource = await pathWithOsrm(positions);
    }

    const allDeviceIds = Object.keys(collectionSource);
    const updatedDevices = await loadDeviceData(collectionSource, allDeviceIds);

    const cleanedDisplays = cleanObsoleteDevices(updatedDevices, positions);

    setDisplayesPath(cleanedDisplays);
  };

  const updateSelectedDevices = async () => {
    let collectionSource = {};

    if (onLine === 1) {
      collectionSource = positions;
    } else if (onLine === 2) {
      const positionsToUpdate = {};

      deviceToUpdate.forEach((deviceId) => {
        if (positions[deviceId]) {
          positionsToUpdate[deviceId] = positions[deviceId];
        }
      });

      if (Object.keys(positionsToUpdate).length > 0) {
        const updatedData = await pathWithOsrm(positionsToUpdate);
        collectionSource = {
          ...positions,
          ...updatedData,
        };
      } else {
        collectionSource = positions;
      }
    }

    const updatedDevices = await loadDeviceData(collectionSource, deviceToUpdate);

    setDisplayesPath((prevState) => {
      const mergedDisplays = {
        ...prevState,
        ...updatedDevices,
      };

      const cleanedDisplays = cleanObsoleteDevices(mergedDisplays, positions);
      return cleanedDisplays;
    });
  };

  useEffect(() => {
    reloadAllDevices();
  }, [onLine, onLine3D]);

  useEffect(() => {
    if (tail) {
      if (deviceToUpdate.length > 0) {
        updateSelectedDevices();
      }
    } else {
      reloadAllDevices();
    }
  }, [deviceToUpdate, stateSyncPositions ?? positions]);

  useEffect(async () => {
    const pathData = pathDataFromPositions(
      displaysPath,
      devices,
      onLine3D,
      onLine,
    );
    if (map.getSource(id)) {
      map.getSource(id).setData(pathData.lines);
    }
    if (map.getSource(`${id}-3D`)) {
      map.getSource(`${id}-3D`).setData(pathData.lines3D);
    }
    if (map.getSource(`${id}-3D-arrows`)) {
      map.getSource(`${id}-3D-arrows`).setData(pathData.arrows3D);
    }
  }, [displaysPath]);

  return null;
};

export default memo(ReplayPathMap);

ReplayPathMap.propTypes = {
  positions: PropTypes.object.isRequired,
  devices: PropTypes.object.isRequired,
  stateSyncDevices: PropTypes.any,
  tail: PropTypes.bool,
  matching: PropTypes.bool,
};
