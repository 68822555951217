import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl, InputLabel, Select, MenuItem, ListItemIcon, ListItemText, Typography,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import getIcon from '../../../map/funcs/getIcon';
import { useEffectAsync } from '../../../common/utils/reactHelper';
import { iconsCoverter, iconsSortArr } from '../../../common/utils/converter';

const SelectDeviceIcon = ({
  currentIcon, onChange, disabled,
}) => {
  const t = useTranslation();
  const theme = useTheme();

  const [icons, setIcons] = useState({});
  const [sortedIconsArr, setSortedIconsArr] = useState([]);

  useEffectAsync(async () => {
    const response = await fetch('/api/icons');
    if (response.ok) {
      const data = await response.json();
      const storedIcons = iconsCoverter(data);
      const sortedIcons = iconsSortArr(storedIcons);
  
      setSortedIconsArr(sortedIcons);
      setIcons(storedIcons || {});
    }
  }, []);

  return (
    <>
      {sortedIconsArr.length > 0 ? (
        <FormControl fullWidth variant="filled" margin="dense" required>
          <InputLabel>{t('deviceIcon')}</InputLabel>
          <Select
            value={currentIcon}
            onChange={(e) => onChange(e.target.value, icons[e.target.value]?.color || theme.palette.markers.black)}
            disabled={disabled}
            renderValue={() => (
              <div style={{ display: 'flex', gap: theme.spacing(2) }}>
                <img src={`/images/icon/device/${getIcon(currentIcon, '#000000').icon}.svg`} alt="" />
                <Typography>{currentIcon === 'знаки_поУмолчанию_1' ? t('markerDefaultCat') : currentIcon}</Typography>
              </div>
            )}
          >
            {[
              { name: t('markerDefaultCat'), icon: 'знаки_поУмолчанию_1' },
              ...sortedIconsArr.map((key) => ({
                name: key,
                icon: icons[key]?.icon,
                color: icons[key]?.color,
              })),
            ].map((cat) => (
              <MenuItem value={cat.name} key={cat.name}>
                <ListItemIcon>
                  <img src={`/images/icon/device/${cat.icon}.svg`} alt="" />
                </ListItemIcon>
                <ListItemText>{cat.name}</ListItemText>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <Typography>{t('noIconsAvailable')}</Typography>
      )}
    </>
  );
};

SelectDeviceIcon.propTypes = {
  currentIcon: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default SelectDeviceIcon;
