import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import {
  abortRequest, collectReport, formatDate, getSortedArray,
} from '../../common/utils/formatter';
import usePersistedState from '../../common/utils/usePersistedState';
import logout from '../../common/utils/logout';
import ReportTemplate from './components/ReportTemplate';
import { prefixString } from '../../common/utils/stringUtils';
import { useTranslation } from '../../common/components/LocalizationProvider';
import { errorsActions } from '../../store';

const typeReport = 'logReport';

const columnsArray = [
  ['level', 'reportLevel', 0],
  ['deviceId', 'reportDevice', 1],
  ['logTime', 'reportLogTime', 1],
  ['protocol', 'reportProtocol', 1],
  ['direction', 'reportDirection', 1],
  ['remoteAddress', 'reportRemoteAddress', 1],
  ['message', 'reportMessage', 0],
];

const typesSorting = {
  logTime: (a, b) => getSortedArray(a.logTime, b.logTime),
  logTimeReverse: (a, b) => getSortedArray(a.logTime, b.logTime, true),
  deviceId: (a, b) => getSortedArray(a.deviceId, b.deviceId),
  deviceIdReverse: (a, b) => getSortedArray(a.deviceId, b.deviceId, true),
  protocol: (a, b) => getSortedArray(a.protocol, b.protocol),
  protocolReverse: (a, b) => getSortedArray(a.protocol, b.protocol, true),
  direction: (a, b) => getSortedArray(a.direction, b.direction),
  directionReverse: (a, b) => getSortedArray(a.direction, b.direction, true),
  remoteAddress: (a, b) => getSortedArray(a.remoteAddress, b.remoteAddress),
  remoteAddressReverse: (a, b) => getSortedArray(a.remoteAddress, b.remoteAddress, true),
};

const LogReportPage = () => {
  const [offColumns, setOffColumns] = usePersistedState(typeReport, []);
  const t = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [devicesList, setDevicesList] = useState([]);
  const [devicesObject, setDevicesObject] = useState({});
  const [progress, setProgress] = useState(0);
  const [progressDevices, setProgressDevices] = useState(false);
  const [page, setPage] = useState(0);
  const [countProgress, setCountProgress] = useState(0);
  const [progressReport, setProgressReport] = useState(0);
  const reportAbortController = useRef();

  const columnsArrayFiltered = columnsArray.filter((column) => !offColumns.includes(column[0]));

  const handleSubmit = (_, from, to, headers) => {
    setProgressDevices(true);
    const requestName = uuidv4();
    reportAbortController.current = new AbortController();
    const { signal } = reportAbortController.current;
    setProgress(1);
    const apiCall = async () => {
      try {
        const query = new URLSearchParams({
          from, to,
        });
        columnsArrayFiltered.forEach((it) => query.append('column', it[0]));
        const response = await fetch(`/api/reports/logs?${query.toString()}`, { headers, signal });
        if (response.ok) {
          const contentType = response.headers.get('content-type');
          if (contentType) {
            if (contentType === 'application/json') {
              setPage(0);
              const result = [];
              await collectReport(response, result, setProgressReport);
              setItems(result);
            } else {
              window.location.assign(window.URL.createObjectURL(await response.blob()));
            }
          }
        } else if (response.status === 401) {
          logout(history, dispatch);
        }
        setProgressDevices(false);
      } catch (error) {
        if (error.name !== 'AbortError') {
          dispatch(errorsActions.push(error.message));
        } else {
          await abortRequest(requestName);
        }
        setProgressDevices(false);
      }
    };
    apiCall();
    setProgress(100);
    setProgress(0);
  };

  const formatValue = (item, key, devices) => {
    switch (key) {
      case 'logTime':
        return formatDate(item[key]);
      case 'deviceId':
        return devices[item[key]]?.name;
      case 'direction':
        if (item[key]) {
          return t(prefixString('sms', item[key]));
        }
        return '';
      case 'level':
        return t(prefixString('reportLogLevel', item[key]));
      default:
        return item[key];
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;

    const apiCall = async () => {
      const requestName = uuidv4();
      try {
        setProgressDevices(true);
        const response = await fetch(`/api/devices/stream?requestName=${requestName}`, { signal });
        if (response.ok) {
          const result = [];
          await collectReport(response, result, setCountProgress);
          setDevicesList(result);

          const resultObj = {};
          result.forEach((item) => resultObj[item.id] = item);
          setDevicesObject(resultObj);
        } else if (response.status === 401) {
          if (response.status === 401) {
            logout(history, dispatch);
          }
        }
        setProgressDevices(false);
      } catch (error) {
        if (error.name !== 'AbortError') {
          dispatch(errorsActions.push(error.message));
        } else {
          await abortRequest(requestName);
        }
        setProgressDevices(false);
      }
    };
    apiCall();
    return () => {
      abortController.abort(); // Cancel the request if component unmounts
    };
  }, []);

  useEffect(() => () => {
    if (reportAbortController.current) {
      reportAbortController.current.abort(); // Cancel the request
    }
  }, []);

  return (
    <ReportTemplate
      items={items}
      progress={progress}
      progressDevices={progressDevices}
      columnsArray={columnsArray}
      handleSubmit={handleSubmit}
      typeSortingDefault="logTime"
      formatValue={formatValue}
      page={page}
      setPage={setPage}
      typesSorting={typesSorting}
      typeReport={typeReport}
      offColumns={offColumns}
      setOffColumns={setOffColumns}
      columnsArrayFiltered={columnsArrayFiltered}
      breadcrumbs={['reportTitle', 'logReports']}
      devicesList={devicesList}
      devicesObject={devicesObject}
      withoutDevices
      countProgress={countProgress}
      progressReport={progressReport}
    />
  );
};

export default LogReportPage;
