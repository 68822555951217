import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import {
  abortRequest, collectReport, formatDate, getSortedArray,
} from '../../common/utils/formatter';
import { prefixString } from '../../common/utils/stringUtils';
import { useTranslation } from '../../common/components/LocalizationProvider';
import usePersistedState from '../../common/utils/usePersistedState';
import logout from '../../common/utils/logout';
import ReportTemplate from './components/ReportTemplate';
import { errorsActions } from '../../store';

const typeReport = 'eventReport';

const columnsArray = [
  ['deviceId', 'reportDevice', 1],
  ['group', 'reportGroup', 1],
  ['eventTime', 'reportEventTime', 1],
  ['type', 'reportType', 0],
  ['geofenceId', 'reportGeofence', 0],
  ['message', 'reportMessage', 0],
];

const typesSorting = {
  deviceId: (a, b) => getSortedArray(a.deviceId, b.deviceId),
  deviceIdReverse: (a, b) => getSortedArray(a.deviceId, b.deviceId, true),
  group: (a, b) => getSortedArray(a.groupId, b.groupId),
  groupReverse: (a, b) => getSortedArray(a.groupId, b.groupId, true),
  eventTime: (a, b) => getSortedArray(a.eventTime, b.eventTime),
  eventTimeReverse: (a, b) => getSortedArray(a.eventTime, b.eventTime, true),
};

const events = {
  // allEvents: 'eventAll',
  deviceOnline: 'eventDeviceOnline',
  deviceUnknown: 'eventDeviceUnknown',
  deviceOffline: 'eventDeviceOffline',
  deviceInactive: 'eventDeviceInactive',
  deviceMoving: 'eventDeviceMoving',
  deviceStopped: 'eventDeviceStopped',
  geofenceEnter: 'eventGeofenceEnter',
  geofenceExit: 'eventGeofenceExit',
  textMessage: 'eventTextMessage',
  alarm: 'eventAlarm',
};

const EventReportPage = () => {
  const t = useTranslation();
  const [offColumns, setOffColumns] = usePersistedState(typeReport, []);

  const geofences = useSelector((state) => state.geofences.items);
  const groups = useSelector((state) => state.groups.items);
  const history = useHistory();
  const dispatch = useDispatch();

  const [items, setItems] = useState([]);
  const [devicesList, setDevicesList] = useState([]);
  const [devicesObject, setDevicesObject] = useState({});

  const [progress, setProgress] = useState(0);
  const [progressDevices, setProgressDevices] = useState(false);
  const [eventTypes, setEventTypes] = useState([]);
  const [page, setPage] = useState(0);
  const [periodChosen, setPeriodChosen] = useState(false);

  const columnsArrayFiltered = columnsArray.filter((column) => !offColumns.includes(column[0]));
  const [countProgress, setCountProgress] = useState(0);
  const [progressReport, setProgressReport] = useState(0);
  const reportAbortController = useRef();

  const handleSubmit = (deviceId, from, to, headers) => {
    const requestName = uuidv4();
    reportAbortController.current = new AbortController();
    const { signal } = reportAbortController.current;
    const apiCall = async () => {
      try {
        const query = new URLSearchParams({
          from, to,
        });
        columnsArrayFiltered.forEach((it) => query.append('column', it[0]));
        deviceId.forEach((it) => query.append('deviceId', it));
        eventTypes.forEach((it) => query.append('type', it));
        setProgress(1);
        const response = await fetch(`/api/reports/events?${query.toString()}`, { headers, signal });
        if (response.ok) {
          const contentType = response.headers.get('content-type');
          if (contentType) {
            if (contentType === 'application/json') {
              setPage(0);
              const result = [];
              await collectReport(response, result, setProgressReport);
              setItems(result);
            } else {
              window.location.assign(window.URL.createObjectURL(await response.blob()));
            }
          }
        } else if (response.status === 401) {
          logout(history, dispatch);
        }
        setProgress(0);
      } catch (error) {
        if (error.name !== 'AbortError') {
          dispatch(errorsActions.push(error.message));
        } else {
          await abortRequest(requestName);
        }
        setProgress(0);
      }
    };
    apiCall();
  };

  const chooseEvents = (values) => {
    if (values[values.length - 1] === 'all') {
      if (eventTypes.length) {
        setEventTypes([]);
      } else {
        setEventTypes(Object.keys(events));
      }
    } else {
      setEventTypes(values.filter((v) => v));
    }
  };

  const formatGeofence = (value) => {
    if (value > 0) {
      const geofence = geofences[value];
      return geofence ? geofence.name : '';
    }
    return '';
  };

  const formatValue = (item, key, devices) => {
    switch (key) {
      case 'eventTime':
        return formatDate(item[key]);
      case 'deviceId':
        return devices[item[key]]?.name;
      case 'group':
        return groups[devices[item.deviceId]?.groupId]?.name || '';
      case 'geofenceId':
        return formatGeofence(item[key]);
      case 'type':
        return t(prefixString('event', item[key]));
      case 'success':
        return item[key] ? '' : t('reportSmsNoSuccess');
      case 'message':
        return item.attributes[key];
      default:
        return item[key];
    }
  };

  useEffect(() => {
    if (periodChosen) {
      const abortController = new AbortController();
      const { signal } = abortController;

      const apiCall = async () => {
        const requestName = uuidv4();
        try {
          setProgressDevices(true);
          const response = await fetch(`/api/devices/stream?requestName=${requestName}`, { signal });
          if (response.ok) {
            const result = [];
            await collectReport(response, result, setCountProgress);
            setDevicesList(result);

            const resultObj = {};
            result.forEach((item) => resultObj[item.id] = item);
            setDevicesObject(resultObj);
          } else if (response.status === 401) {
            if (response.status === 401) {
              logout(history, dispatch);
            }
          }
          setProgressDevices(false);
        } catch (error) {
          if (error.name !== 'AbortError') {
            dispatch(errorsActions.push(error.message));
          } else {
            await abortRequest(requestName);
          }
          setProgressDevices(false);
        }
      };
      apiCall();
      return () => {
        abortController.abort(); // Cancel the request if component unmounts
      };
    }
    return null;
  }, [periodChosen]);

  useEffect(() => () => {
    if (reportAbortController.current) {
      reportAbortController.current.abort(); // Cancel the request
    }
  }, []);

  return (
    <ReportTemplate
      items={items}
      progress={progress}
      progressDevices={progressDevices}
      columnsArray={columnsArray}
      handleSubmit={handleSubmit}
      typeSortingDefault="eventTime"
      formatValue={formatValue}
      page={page}
      setPage={setPage}
      periodChosen={periodChosen}
      setPeriodChosen={setPeriodChosen}
      typesSorting={typesSorting}
      typeReport={typeReport}
      offColumns={offColumns}
      setOffColumns={setOffColumns}
      columnsArrayFiltered={columnsArrayFiltered}
      events={events}
      eventTypes={eventTypes}
      chooseEvents={chooseEvents}
      breadcrumbs={['reportTitle', 'reportEvents']}
      devicesList={devicesList}
      devicesObject={devicesObject}
      countProgress={countProgress}
      progressReport={progressReport}
    />
  );
};

export default EventReportPage;
