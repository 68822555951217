import { isUndefinedOrNull } from './stringUtils';

export const isVcam = (item) => {
  if (!isUndefinedOrNull(item.deviceCategory)) {
    return item.deviceCategory === 'vcam';
  }
  if (!isUndefinedOrNull(item.category)) {
    return item.category === 'vcam';
  }

  return undefined;
};

export const tryGetVcamPosition = (positionData, devicesList) => {
  if (!isVcam(positionData) && !isUndefinedOrNull(positionData.categoryParams)) {
    return positionData;
  }

  let newObject = { ...positionData };

  const foundDevice = devicesList[newObject.deviceId];
  if (!isUndefinedOrNull(foundDevice) && !isUndefinedOrNull(foundDevice.attributes?.categoryParams)) {
    const categoryParams = { ...foundDevice.attributes.categoryParams };
    newObject = { ...newObject, categoryParams };
  }

  return newObject;
};

/**
 * Возвращает url адрес для websocket.
 * @param {*} item Объект, который содержит информацию.
 */
export const getJsMpegUrl = (item) => {
  if (!isVcam(item) || isUndefinedOrNull(item?.attributes?.categoryParams?.videoRecordService)) {
    return undefined;
  }

  const { videoRecordService, cameraName } = item.attributes.categoryParams;
  // Получаем адрес сервиса без http протокола
  const [, address] = videoRecordService.split('://');

  return `ws://${address}/live/jsmpeg/${cameraName}`;
};
