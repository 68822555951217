import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Accordion, AccordionSummary, AccordionDetails, Typography, makeStyles,
} from '@material-ui/core';
import { useTranslation } from '../../common/components/LocalizationProvider';
import EditItemView from './components/EditItemView';
import LinkField from '../../common/form/LinkField';

const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
}));

const UnionPage = () => {
  const classes = useStyles();
  const t = useTranslation();

  const [item, setItem] = useState();
  const [permissions, setPermissions] = useState();

  useEffect(() => {
    if (item && !permissions) {
      const permissionsDict = {
        markers: {
          baseId: item.id,
          keyBase: 'unionId',
          keyLink: 'markerId',
          linked: new Set(),
          old: new Set(),
        },
        geofences: {
          baseId: item.id,
          keyBase: 'unionId',
          keyLink: 'geofenceId',
          linked: new Set(),
          old: new Set(),
        },
      };
      setPermissions(permissionsDict);
    }
  }, [item]);

  return (
    <EditItemView
      endpoint="unions"
      item={item}
      setItem={setItem}
      disabledSave={!item?.name}
      permissions={permissions}
    >
      {item
        && (
          <>
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">
                  {t('settingsUnion')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <TextField
                  required
                  margin="normal"
                  value={item.name || ''}
                  onChange={(event) => setItem({ ...item, name: event.target.value })}
                  label={t('sharedName')}
                  variant="filled"
                />
              </AccordionDetails>
            </Accordion>
            {!!(item.id && permissions)
              && (
                <Accordion defaultExpanded>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="subtitle1">
                      {t('sharedConnections')}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.details}>
                    <LinkField
                      margin="normal"
                      endpointAll="/api/markers?all=true"
                      endpointLinked={`/api/markers?unionId=${item.id}`}
                      label={t('settingsMarkers')}
                      variant="filled"
                      permissions={permissions}
                      setPermissions={setPermissions}
                      type="markers"
                    />
                    <LinkField
                      margin="normal"
                      endpointAll="/api/geofences?all=true"
                      endpointLinked={`/api/geofences?unionId=${item.id}`}
                      label={t('settingsGeofences')}
                      variant="filled"
                      permissions={permissions}
                      setPermissions={setPermissions}
                      type="geofences"
                    />
                  </AccordionDetails>
                </Accordion>
              )}
          </>
        )}
    </EditItemView>
  );
};

export default UnionPage;
