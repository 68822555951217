/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { useSelector } from 'react-redux';
import {
  InputAdornment, IconButton, TextField, List, ListItem, ListItemText, Divider, makeStyles,
} from '@material-ui/core';
import { useTranslation } from './LocalizationProvider';

export default function SearchAddress({ collapsed, setSearchCoords }) {
  const server = useSelector((state) => state.session.server);
  const user = useSelector((state) => state.session.user);

  const currentLocation = {
    lat: useSelector((state) => ((state.session.user.longitude === 0) && (state.session.user.latitude === 0) ? state.session.server.latitude : state.session.user.latitude)),
    lon: useSelector((state) => ((state.session.user.longitude === 0) && (state.session.user.latitude === 0) ? state.session.server.longitude : state.session.user.longitude)),
  };

  const t = useTranslation();

  const useStylesSearchAddress = makeStyles((theme) => ({
    searchDiv: {
      backgroundColor: 'white',
      width: '260px',
      [theme.breakpoints.down('xs')]: {
        width: 'calc(100% - 145px)',
      },
    },
    searchField: {
      backgroundColor: 'white',
      margin: theme.spacing(1.5, 0, 0, 48),
      transition: 'margin .5s ease',
      width: '100%',
    },
    searchFieldCollapsed: {
      margin: theme.spacing(1.5, 0, 0, 10),
      [theme.breakpoints.down('md')]: {
        margin: theme.spacing(1.5, 0, 0, 8),
      },
      [theme.breakpoints.down('xs')]: {
        margin: theme.spacing(7.5, 0, 0, 0),
      },
    },
    searchButton: {
      transform: 'translateX(15px)',
    },
    list: {
      backgroundColor: 'white',
      width: '100%',
      margin: theme.spacing(0.5, 0, 0, 48),
      maxHeight: '300px',
      overflowY: 'auto',
      transition: 'margin .5s ease',
    },
    listCollapsed: {
      margin: theme.spacing(0.5, 0, 0, 10),
      [theme.breakpoints.down('md')]: {
        margin: theme.spacing(0.5, 0, 0, 8),
      },
      [theme.breakpoints.down('xs')]: {
        margin: theme.spacing(0.5, 0, 0, 0),
      },
    },
    listItem: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#f5f5f5',
      },
    },
  }));
  const classes = useStylesSearchAddress([]);

  const [searchValue, setSearchValue] = useState('');
  const [data, setData] = useState([]);
  const [isSearchOn, setSearchOn] = useState();

  useEffect(() => {
    if (server.attributes.mapGeocodeProvider === 'off' || !server.attributes.mapGeocodeProvider) {
      setSearchOn(false);
    } else {
      setSearchOn(true);
    }
  }, []);

  useEffect(() => {
    if (searchValue === '') {
      setData([]);
    }
  }, [searchValue]);

  function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    const R = 6371;
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
      + Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180))
      * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;
    return distance;
  }

  async function onSubmit() {
    if (searchValue !== '') {
      if (server.attributes.mapGeocodeProvider === 'nominatim') {
        try {
          const viewbox = [
            currentLocation.lon - 0.1,
            currentLocation.lat - 0.1,
            currentLocation.lon + 0.1,
            currentLocation.lat + 0.1,
          ].join(',');

          const response = await fetch(
            `${server.attributes.mapGeoсodeProviderUrl}/search?q=${searchValue}&limit=50&format=jsonv2&viewbox=${viewbox}&bounded=0&accept-language=ru`,
          );
          const data = await response.json();
          if (data.length > 0) {
            const newData = data.map((el) => {
              const text = el.display_name;
              let points = el.boundingbox;
              const point = [parseFloat(el.lon), parseFloat(el.lat)];
              points = [
                [
                  Number(points[2]),
                  Number(points[0]),
                ],
                [
                  Number(points[3]),
                  Number(points[1]),
                ],
              ];
              return {
                text, point, points, distance: getDistanceFromLatLonInKm(currentLocation.lat, currentLocation.lon, point[1], point[0]),
              };
            });

            newData.sort((a, b) => a.distance - b.distance);

            setData(newData);
          } else {
            setData([
              {
                text: t('searchNotFound'),
                points: {
                  lat: -1000,
                  lon: -1000,
                },
              },
            ]);
            setTimeout(() => { setData([]); }, 3000);
          }
        } catch (error) {
          setData([
            {
              text: t('searchError'),
              points: {
                lat: -1000,
                lon: -1000,
              },
            },
          ]);
          console.error(error);
          setTimeout(() => { setData([]); }, 3000);
        }
      }
    }
  }
  return (
    <>
      {(user.attributes.showSearch && isSearchOn) ? (
        <div className={classes.searchDiv}>
          <TextField
            className={`${classes.searchField} ${collapsed && classes.searchFieldCollapsed}`}
            onChange={(e) => { setSearchValue(e.target.value); }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                onSubmit();
              }
            }}
            value={searchValue}
            placeholder={t('mapSearch')}
            variant="outlined"
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton className={`${classes.searchButton}`} onClick={() => { onSubmit(); }}>
                    <SearchIcon
                      edge="end"
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {data.length > 0 ? (
            <List className={`${classes.list} ${collapsed && classes.listCollapsed}`}>
              <Divider component="li" />
              {data.map((el) => (
                <div key={el.points}>
                  <ListItem
                    className={`${classes.listItem}`}
                    onClick={(el.text === t('searchNotFound') || el.text === t('searchError')) ? () => undefined : () => {
                      setSearchCoords({
                        points: el.points,
                        point: el.point,
                      });
                      setSearchValue('');
                    }}
                  >
                    <ListItemText primary={el.text} />
                  </ListItem>
                  <Divider component="li" />
                </div>
              ))}
            </List>
          ) : <></>}
        </div>
      ) : (<></>)}
    </>
  );
}
