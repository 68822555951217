import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import {
  Button, Slider, Typography, Container, makeStyles,
} from '@material-ui/core';
import moment from 'moment';
import { useTranslation } from '../../../../common/components/LocalizationProvider';
import TimeLineFragment from './TimeLineFragment';

let scrollLeft = 0;
export default function TimeLineSlider({
  initFrom, initTo, positions, indexes, setIndexes,
}) {
  const to = initTo.clone();
  const from = initFrom.clone();
  const ids = Object.keys(positions);
  const scrollContainerRef = useRef(null);
  const selectorContainerRef = useRef(null);
  const timeoutSliderZoomRef = useRef(null);
  const timeoutScrollRef = useRef(null);
  const [isDragging, setDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [datesArr, setDatesArr] = useState([]);
  const [positionsTimes, setPositionsTimes] = useState([]);
  const [width, setWidth] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [fragments, setFragments] = useState(null);
  const [sliderSpeedValue, setSliderSpeedValue] = useState(1);
  const [speedValue, setSpeedValue] = useState(1);
  const [sliderZoomValue, setSliderZoomValue] = useState(1);
  const [previousSliderZoomValue, setPreviousSliderZoomValue] = useState(1);
  const [previousScrollLeft, setPreviousScrollLeft] = useState(1);
  const [zoomMinutes, setZoomMinutes] = useState(1440);
  const [zoomHoursTitle, setZoomHoursTitle] = useState('scrollZoomTitle24h');
  const [selectorText, setSelectorText] = useState({ date: from.format('L'), time: from.format('HH:mm') });
  const [zoomLimit, setZoomLimit] = useState({ min: 0, max: 9 });

  const t = useTranslation();

  const useStylesTimeLineSlider = makeStyles((theme) => ({
    timeLineSlider: {
      padding: 0,
      border: '1px solid #707070',
      borderRadius: '5px',
      marginBottom: '5px',
    },
    controlWrapper: {
      width: '100%',
      backgroundColor: '#fff',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      borderTopLeftRadius: '5px',
      borderTopRightRadius: '5px',
      [theme.breakpoints.down('xs')]: {
        padding: '0px 5px',
      },
    },
    controlButtonsContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '200px',
      padding: '0px',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        width: '80px',
      },
    },
    controlButton: {
      marginRight: theme.spacing(1),
      [theme.breakpoints.down('xs')]: {
        margin: '0',
      },
    },
    controlArrowButtons: {
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    controlSlidersContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      alignContent: 'center',
      paddingTop: theme.spacing(1),
      width: 'calc(100% - 200px)',
      padding: '0px',
      [theme.breakpoints.down('xs')]: {
        width: 'calc(100% - 80px)',
      },
    },
    controlSlider: {
      width: '50%',
      [theme.breakpoints.down('xs')]: {
        width: 'calc(50% - 20px)',
        padding: '0',
      },
    },
    controlSliderText: {
      [theme.breakpoints.down('xs')]: {
        fontSize: '14px',
      },
    },
    scrollbarWrapper: {
      borderTop: '1px solid #707070',
      position: 'relative',
    },
    selector: {
      backgroundColor: '#368efc',
      width: '2px',
      padding: '0px 1px',
      height: '58px',
      position: 'absolute',
      top: '30%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      pointerEvents: 'none',
    },
    selectorTextDate: {
      fontWeight: 'bold',
      position: 'absolute',
      top: '2px',
      left: '-88px',
    },
    selectorTextTime: {
      fontWeight: 'bold',
      position: 'absolute',
      top: '2px',
      left: '6px',
    },
    scrollbar: {
      backgroundColor: '#fff',
      width: '100%',
      overflowY: 'hidden',
      overflowX: 'scroll',
      cursor: 'pointer',
      transition: 'scrollLeft 0.25s ease',
      scrollbarWidth: 'none',
      borderBottomLeftRadius: '5px',
      borderBottomRightRadius: '5px',
    },
    timeLineFragments: {
      display: 'flex',
      flexDirection: 'row-reverse',
      justifyContent: 'center',
      paddingTop: '40px',
      paddingBottom: '55px',
      paddingLeft: '50%',
      paddingRight: '50%',
      color: '#303030',
      fontSize: '24px',
    },
  }));
  const classes = useStylesTimeLineSlider([]);

  function getZoomLimit(from, to) {
    let maxZoom = 9;
    let minZoom = 0;
    const diffMinutes = to.diff(from, 'minutes');
    if (diffMinutes > 700) {
      maxZoom = 8;
    }
    if ((diffMinutes / 5) > 700) {
      maxZoom = 7;
    }
    if ((diffMinutes / 10) > 700) {
      maxZoom = 6;
    }
    if ((diffMinutes / 30) > 700) {
      maxZoom = 5;
    }
    if ((diffMinutes / 60) > 700) {
      maxZoom = 4;
    }
    if ((diffMinutes / 120) > 700) {
      maxZoom = 3;
    }
    if ((diffMinutes / 360) > 700) {
      maxZoom = 2;
    }
    if ((diffMinutes / 720) > 700) {
      maxZoom = 1;
    }

    if (diffMinutes <= 1440) {
      minZoom = 1;
    }
    if (diffMinutes <= 720) {
      minZoom = 2;
    }
    if (diffMinutes <= 360) {
      minZoom = 3;
    }
    if (diffMinutes <= 120) {
      minZoom = 4;
    }
    if (diffMinutes <= 60) {
      minZoom = 5;
    }
    if (diffMinutes <= 30) {
      minZoom = 6;
    }
    if (diffMinutes <= 10) {
      minZoom = 7;
    }
    if (diffMinutes <= 5) {
      minZoom = 8;
    }
    if (diffMinutes === 1) {
      minZoom = 9;
    }

    return {
      min: minZoom,
      max: maxZoom,
    };
  }

  useEffect(() => {
    const limit = getZoomLimit(from, to);
    setZoomLimit(limit);
    setSliderZoomValue(limit.min);
  }, []);

  function getTimeUnderSelector() {
    const selector = selectorContainerRef.current;
    const selectorRect = selector.getBoundingClientRect();
    const selectorLeft = selectorRect.left + 1;

    let currentFragment;
    for (let i = 0; i < fragments.length; i++) {
      const fragment = fragments[i];
      const fragmentRect = fragment.getBoundingClientRect();
      const fragmentLeft = fragmentRect.left;

      if (selectorLeft >= fragmentLeft && selectorLeft <= fragmentRect.right) {
        currentFragment = fragment;
        break;
      }
    }

    if (currentFragment) {
      const currentFragmentRect = currentFragment.getBoundingClientRect();
      const selectorOffset = selectorLeft - currentFragmentRect.left;
      const index = Array.from(currentFragment.parentNode.children).indexOf(currentFragment);
      const currentDate = moment(datesArr[index], 'YYYY-MM-DD HH:mm:ss');
      const totalSeconds = Math.round((selectorOffset / currentFragmentRect.width) * zoomMinutes * 60);
      const resultDate = currentDate.clone().add(totalSeconds, 'seconds');

      return resultDate;
    }
    return null;
  }

  function getFragmentUnderSelector() {
    const resultDate = getTimeUnderSelector();

    if (resultDate) {
      let isUpdated = false;
      let newIndexes = { ...indexes };
      positionsTimes.forEach((el) => {
        const id = Object.keys(el);
        const positionTimesArr = el[id];
        let lastMomentIndex = positionTimesArr.length - 1;
        for (let i = 0; i < positionTimesArr.length; i++) {
          if (resultDate.isSameOrBefore(positionTimesArr[i])) {
            lastMomentIndex = i;
            break;
          }
        }

        if (lastMomentIndex !== indexes[id]) {
          newIndexes = { ...newIndexes, [id]: lastMomentIndex };
          isUpdated = true;
        }
      });
      if (isUpdated) {
        setIndexes(newIndexes);
      }
    }
  }

  useEffect(() => {
    ids.forEach((el) => {
      const newArr = [];
      for (let i = 0; i < positions[el].length; i++) {
        newArr.push(moment(positions[el][i].fixTime));
      }
      const newPositionTimes = positionsTimes;
      newPositionTimes.push({ [el]: newArr });
      setPositionsTimes(newPositionTimes);
    });
  }, []);

  useEffect(() => {
    to.add(1, 'millisecond');
    if (width > 0) {
      let count;

      switch (sliderZoomValue) {
        case 0:
          while (to.day() !== 1) {
            to.add(1, 'day');
          }
          count = (to.diff(from, 'minutes') / 10800);
          setZoomHoursTitle('scrollZoomTitle1week');
          setZoomMinutes(10080);
          break;
        case 1:
          count = (to.diff(from, 'minutes') / 1440);
          setZoomHoursTitle('scrollZoomTitle24h');
          setZoomMinutes(1440);
          break;
        case 2:
          count = (to.diff(from, 'minutes') / 720);
          setZoomHoursTitle('scrollZoomTitle12h');
          setZoomMinutes(720);
          break;
        case 3:
          count = (to.diff(from, 'minutes') / 360);
          setZoomHoursTitle('scrollZoomTitle6h');
          setZoomMinutes(360);
          break;
        case 4:
          count = (to.diff(from, 'minutes') / 120);
          setZoomHoursTitle('scrollZoomTitle2h');
          setZoomMinutes(120);
          break;
        case 5:
          count = (to.diff(from, 'minutes') / 60);
          setZoomHoursTitle('scrollZoomTitle1h');
          setZoomMinutes(60);
          break;
        case 6:
          count = (to.diff(from, 'minutes') / 30);
          setZoomHoursTitle('scrollZoomTitle30m');
          setZoomMinutes(30);
          break;
        case 7:
          count = (to.diff(from, 'minutes') / 10);
          setZoomHoursTitle('scrollZoomTitle10m');
          setZoomMinutes(10);
          break;
        case 8:
          count = (to.diff(from, 'minutes') / 5);
          setZoomHoursTitle('scrollZoomTitle5m');
          setZoomMinutes(5);
          break;
        case 9:
          count = (to.diff(from, 'minutes'));
          setZoomHoursTitle('scrollZoomTitle1m');
          setZoomMinutes(1);
          break;
        default:
          count = (to.diff(from, 'minutes') / 1440);
          setZoomMinutes(1440);
          break;
      }
      const newArr = [];
      const date = to.clone();
      for (let i = 0; i < count; i++) {
        switch (sliderZoomValue) {
          case 0:
            newArr.push(date.subtract(1, 'week').format('YYYY-MM-DD HH:mm:ss'));
            break;
          case 1:
            newArr.push(date.subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss'));
            break;
          case 2:
            newArr.push(date.subtract(12, 'hour').format('YYYY-MM-DD HH:mm:ss'));
            break;
          case 3:
            newArr.push(date.subtract(6, 'hour').format('YYYY-MM-DD HH:mm:ss'));
            break;
          case 4:
            newArr.push(date.subtract(2, 'hour').format('YYYY-MM-DD HH:mm:ss'));
            break;
          case 5:
            newArr.push(date.subtract(1, 'hour').format('YYYY-MM-DD HH:mm:ss'));
            break;
          case 6:
            newArr.push(date.subtract(30, 'minute').format('YYYY-MM-DD HH:mm:ss'));
            break;
          case 7:
            newArr.push(date.subtract(10, 'minute').format('YYYY-MM-DD HH:mm:ss'));
            break;
          case 8:
            newArr.push(date.subtract(5, 'minute').format('YYYY-MM-DD HH:mm:ss'));
            break;
          case 9:
            newArr.push(date.subtract(1, 'minute').format('YYYY-MM-DD HH:mm:ss'));
            break;
          default:
            newArr.push(date.subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss'));
            break;
        }
      }

      setDatesArr(newArr);
    }
  }, [width, sliderZoomValue]);

  useEffect(() => {
    let previousScrollLeftTemp;
    switch (previousSliderZoomValue) {
      case 0:
        previousScrollLeftTemp = previousScrollLeft / (1 / 7);
        break;
      case 1:
        previousScrollLeftTemp = previousScrollLeft / 1;
        break;
      case 2:
        previousScrollLeftTemp = previousScrollLeft / 2;
        break;
      case 3:
        previousScrollLeftTemp = previousScrollLeft / 4;
        break;
      case 4:
        previousScrollLeftTemp = previousScrollLeft / 12;
        break;
      case 5:
        previousScrollLeftTemp = previousScrollLeft / 24;
        break;
      case 6:
        previousScrollLeftTemp = previousScrollLeft / 48;
        break;
      case 7:
        previousScrollLeftTemp = previousScrollLeft / 144;
        break;
      case 8:
        previousScrollLeftTemp = previousScrollLeft / 288;
        break;
      case 9:
        previousScrollLeftTemp = previousScrollLeft / 1440;
        break;
      default:
        previousScrollLeftTemp = previousScrollLeft / 1;
        break;
    }
    switch (sliderZoomValue) {
      case 0:
        scrollContainerRef.current.scrollLeft = previousScrollLeftTemp * (1 / 7);
        break;
      case 1:
        scrollContainerRef.current.scrollLeft = previousScrollLeftTemp * 1;
        break;
      case 2:
        scrollContainerRef.current.scrollLeft = previousScrollLeftTemp * 2;
        break;
      case 3:
        scrollContainerRef.current.scrollLeft = previousScrollLeftTemp * 4;
        break;
      case 4:
        scrollContainerRef.current.scrollLeft = previousScrollLeftTemp * 12;
        break;
      case 5:
        scrollContainerRef.current.scrollLeft = previousScrollLeftTemp * 24;
        break;
      case 6:
        scrollContainerRef.current.scrollLeft = previousScrollLeftTemp * 48;
        break;
      case 7:
        scrollContainerRef.current.scrollLeft = previousScrollLeftTemp * 144;
        break;
      case 8:
        scrollContainerRef.current.scrollLeft = previousScrollLeftTemp * 288;
        break;
      case 9:
        scrollContainerRef.current.scrollLeft = previousScrollLeftTemp * 1440;
        break;
      default:
        scrollContainerRef.current.scrollLeft = previousScrollLeftTemp * 1;
        break;
    }
  }, [width]);

  useEffect(() => {
    setWidth(datesArr.length * 144 + (selectorContainerRef.current.parentNode.offsetWidth));
    setFragments(scrollContainerRef.current.querySelectorAll(`.${classes.timeLineFragments} > *`));
  }, [datesArr]);

  useEffect(() => {
    let intervalId = null;
    let secondIntervalId = null;
    if (isPlaying) {
      intervalId = setInterval(() => {
        scrollContainerRef.current.scrollTo({
          left: scrollContainerRef.current.scrollLeft + speedValue,
          behavior: 'smooth',
        });
      }, 100);
      secondIntervalId = setInterval(() => {
        getFragmentUnderSelector();
      }, 500);
    }

    return () => {
      clearInterval(intervalId);
      clearInterval(secondIntervalId);
    };
  }, [isPlaying, speedValue]);

  const handleMouseDown = (e) => {
    setDragging(true);
    setStartX(e.pageX - scrollContainerRef.current.offsetLeft);
    scrollLeft = scrollContainerRef.current.scrollLeft;
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const x = e.pageX - scrollContainerRef.current.offsetLeft;
    const walk = (x - startX);
    scrollContainerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  const handleScroll = () => {
    const resultDate = getTimeUnderSelector();
    if (resultDate) {
      const newSelectorText = {
        date: resultDate.format('L'),
        time: resultDate.format('HH:mm'),
      };
      setSelectorText(newSelectorText);
    }
    if (!isPlaying) {
      if (timeoutScrollRef.current) clearTimeout(timeoutScrollRef.current);
      timeoutScrollRef.current = setTimeout(() => {
        getFragmentUnderSelector();
      }, 150);
    }
  };

  const handlePlayPauseClick = () => {
    setIsPlaying(!isPlaying);
  };

  const handleSliderSpeedChange = (event, newValue) => {
    if (sliderSpeedValue !== newValue) {
      setSliderSpeedValue(newValue);
      switch (newValue) {
        case 1:
          setSpeedValue(1);
          break;
        case 2:
          setSpeedValue(2);
          break;
        case 3:
          setSpeedValue(6);
          break;
        case 4:
          setSpeedValue(12);
          break;
        case 5:
          setSpeedValue(24);
          break;
        default: {
          setSpeedValue(1);
          break;
        }
      }
    }
  };

  const handleSliderZoomChange = (event, newValue) => {
    if (timeoutSliderZoomRef.current) clearTimeout(timeoutSliderZoomRef.current);
    timeoutSliderZoomRef.current = setTimeout(() => {
      if (sliderZoomValue !== newValue) {
        setPreviousScrollLeft(scrollContainerRef.current.scrollLeft);
        setPreviousSliderZoomValue(sliderZoomValue);
        setSliderZoomValue(newValue);
      }
    }, 100);
  };

  function checkDateBetween(startDate, endDate) {
    return positionsTimes.some((el) => {
      const id = Object.keys(el)[0];
      const positionTimesArr = el[id];
      return positionTimesArr.some((time) => time.isBetween(startDate, endDate));
    });
  }

  function isDateBetween(el) {
    const startDate = moment(el, 'YYYY-MM-DD HH:mm:ss');
    let endDate;

    switch (sliderZoomValue) {
      case 0:
        endDate = startDate.clone().add(1, 'week');
        break;
      case 1:
        endDate = startDate.clone().add(1, 'days');
        break;
      case 2:
        endDate = startDate.clone().add(12, 'hour');
        break;
      case 3:
        endDate = startDate.clone().add(6, 'hour');
        break;
      case 4:
        endDate = startDate.clone().add(2, 'hour');
        break;
      case 5:
        endDate = startDate.clone().add(1, 'hour');
        break;
      case 6:
        endDate = startDate.clone().add(30, 'minute');
        break;
      case 7:
        endDate = startDate.clone().add(10, 'minute');
        break;
      case 8:
        endDate = startDate.clone().add(5, 'minute');
        break;
      case 9:
        endDate = startDate.clone().add(1, 'minute');
        break;
      default:
        endDate = startDate.clone().add(1, 'days');
        break;
    }
    const secondsDifference = (endDate.diff(startDate, 'seconds') / 6);
    const tempStart = startDate.clone();
    const tempEnd = endDate.clone();
    tempEnd.subtract((secondsDifference * 6), 'seconds');
    return {
      all: checkDateBetween(startDate, endDate),
      1: checkDateBetween(tempStart, tempEnd.add(secondsDifference, 'seconds')),
      2: checkDateBetween(tempStart.add(secondsDifference, 'seconds'), tempEnd.add(secondsDifference, 'seconds')),
      3: checkDateBetween(tempStart.add(secondsDifference, 'seconds'), tempEnd.add(secondsDifference, 'seconds')),
      4: checkDateBetween(tempStart.add(secondsDifference, 'seconds'), tempEnd.add(secondsDifference, 'seconds')),
      5: checkDateBetween(tempStart.add(secondsDifference, 'seconds'), tempEnd.add(secondsDifference, 'seconds')),
      6: checkDateBetween(tempStart.add(secondsDifference, 'seconds'), tempEnd.add(secondsDifference, 'seconds')),
    };
    // return {
    //   all: checkDateBetween(startDate, endDate),
    //   1: true,
    //   2: false,
    //   3: false,
    //   4: false,
    //   5: true,
    //   6: false,
    // };
  }

  return (
    <Container className={classes.timeLineSlider}>
      <Container className={classes.controlWrapper}>
        <Container className={classes.controlButtonsContainer}>
          <Button
            className={`${classes.controlButton} ${classes.controlArrowButtons}`}
            variant="outlined"
            color="primary"
            onClick={() => {
              scrollContainerRef.current.scrollTo({
                left: scrollContainerRef.current.scrollLeft - 144,
                behavior: 'smooth',
              });
            }}
          >
            <ArrowLeftIcon />
          </Button>
          <Button
            className={classes.controlButton}
            variant="outlined"
            color="primary"
            onClick={handlePlayPauseClick}
          >
            {isPlaying ? <PauseCircleOutlineIcon /> : <PlayCircleOutlineIcon />}
          </Button>
          <Button
            className={`${classes.controlButton} ${classes.controlArrowButtons}`}
            variant="outlined"
            color="primary"
            onClick={() => {
              scrollContainerRef.current.scrollTo({
                left: scrollContainerRef.current.scrollLeft + 144,
                behavior: 'smooth',
              });
            }}
          >
            <ArrowRightIcon />
          </Button>
        </Container>
        <Container className={classes.controlSlidersContainer}>
          <Container className={classes.controlSlider}>
            <Typography className={classes.controlSliderText}>
              {`${t('scrollSpeed')}: `}
              <span style={{ color: '#368efc', fontWeight: 'bold' }}>{`${speedValue}x`}</span>
            </Typography>
            <Slider
              size="small"
              value={sliderSpeedValue}
              min={1}
              max={5}
              step={1}
              onChange={handleSliderSpeedChange}
            />
          </Container>
          <Container className={classes.controlSlider}>
            <Typography className={classes.controlSliderText}>
              {`${t('scrollZoom')}: `}
              <span style={{ color: '#368efc', fontWeight: 'bold' }}>{`${t(zoomHoursTitle)}`}</span>
            </Typography>
            <Slider
              size="small"
              value={sliderZoomValue}
              min={zoomLimit.min}
              max={zoomLimit.max}
              step={1}
              onChange={handleSliderZoomChange}
            />
          </Container>
        </Container>
      </Container>
      <div className={classes.scrollbarWrapper}>
        <div className={classes.selector} ref={selectorContainerRef}>
          <Typography className={classes.selectorTextDate}>
            {selectorText.date}
          </Typography>
          <Typography className={classes.selectorTextTime}>
            {selectorText.time}
          </Typography>
        </div>
        <div
          role="button"
          tabIndex={0}
          className={classes.scrollbar}
          ref={scrollContainerRef}
          onScroll={handleScroll}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
        >
          <div
            className={classes.timeLineFragments}
            style={{
              width: `${width}px`,
            }}
          >
            {useMemo(() => datesArr.map((el) => <TimeLineFragment key={uuidv4()} time={el} sliderZoomValue={sliderZoomValue} isLast={(el === datesArr[0])} isHavePoint={isDateBetween(el)} />), [datesArr])}
          </div>
        </div>
      </div>
    </Container>
  );
}
