import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Accordion, AccordionDetails, AccordionSummary, TextField, Typography, makeStyles,
} from '@material-ui/core';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import { setAttr } from '../../../common/utils/formatter';

const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
}));

const ServicesBlock = ({
  item, setItem, errorBscoder, setErrorBscoder, errorOsrm, setErrorOsrm,
}) => {
  const t = useTranslation();
  const classes = useStyles();

  const changeService = (value, service) => {
    const services = { ...item.attributes.services || {} };
    services[service] = { url: value };
    setAttr(setItem, 'services', services);
  };

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="subtitle1">
          {t('sharedServices')}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        <TextField
          margin="normal"
          value={item.attributes.services?.bscoder?.url || ''}
          onChange={(event) => {
            setErrorBscoder(false);
            changeService(event.target.value, 'bscoder');
          }}
          label={t('bscoderServiceLabel')}
          error={errorBscoder}
          helperText={errorBscoder ? t('errorUrl') : ''}
          placeholder="http://your-bscoder-service"
          variant="filled"
        />
        <TextField
          margin="normal"
          value={item.attributes.services?.osrm?.url || ''}
          onChange={(event) => {
            setErrorOsrm(false);
            changeService(event.target.value, 'osrm');
          }}
          label={t('OSRMServiceLabel')}
          error={errorOsrm}
          helperText={errorOsrm ? t('errorUrl') : ''}
          placeholder="http://your-osrm-service"
          variant="filled"
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default ServicesBlock;
