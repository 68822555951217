import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'icons',
  initialState: {
    items: {},
  },
  reducers: {
    init(state, action) {
      state.items = action.payload;
    },
  },
});

export { actions as iconsActions };
export { reducer as iconsReducer };
