import React, { useState } from 'react';
import {
  Button, LinearProgress, makeStyles,
} from '@material-ui/core';
import PageLayout from '../../../common/components/PageLayout';
import ReportsMenu from '../components/ReportsMenu';
import 'react-calendar-timeline/lib/Timeline.css';
import { useEffectAsync } from '../../../common/utils/reactHelper';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import scrollStyles from '../../../common/theme/scrollStyles';
import M3U8 from '../../../common/utils/m3u8';
import FiltersPanel from './FiltersPanel';
import { minutesToMilliseconds } from '../../../common/utils/datetimeHelper';
import CameraBlocksContainer from '../components/CameraBlocksContainer';
import CameraBlock from '../components/CameraBlock';
import EventReviewLayer from '../components/EventReviewLayer';
import CameraActionsWindows from '../components/CameraActionsWindows';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    overflowY: 'auto',
    margin: 5,
    padding: 3,
    ...scrollStyles(6),
  },
  videoGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    },
    gap: 5,
  },
  noData: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const breadcrumbs = ['reportTitle', 'reportEventsRecording'];

const CameraEventReportPage = () => {
  const classes = useStyles();
  const t = useTranslation();

  const [eventReviews, setEventReviews] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchFilter, setSearchFilter] = useState('');
  const [datetimeFilter, setDatetimeFilter] = useState({ from: undefined, to: undefined });

  const eventReviewLayer = EventReviewLayer({});

  const acceptDeleteConfirmWindow = async (item) => {
    try {
      const { videoRecordService, id } = item;
      const url = `${videoRecordService}/api/reviews/delete`;
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          ids: [id],
        }),
      });

      if (response.ok) {
        setEventReviews((prev) => {
          const newVideos = prev.filter((v) => v.id !== id);
          return newVideos;
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const cameraActionsWindows = CameraActionsWindows({
    onDeleteConfirm: acceptDeleteConfirmWindow,
    type: 'application/x-mpegURL',
    additionalOptions: {
      disablePictureInPicture: true,
      controlBar: {
        skipButtons: {
          backward: 5,
          forward: 5,
        },
      },
    },
  });

  const loadEventReviews = async () => {
    try {
      await eventReviewLayer.loadEventReviews();
      setEventReviews(eventReviewLayer.getEventReviews());
      if (isError) {
        setIsError(false);
      }
    } catch (error) {
      console.error(error);
      setIsError(true);
    }
  };

  const loadPageData = async () => {
    setIsLoading(true);
    await loadEventReviews();
    setIsLoading(false);
  };

  useEffectAsync(async () => {
    await loadPageData();

    const id = setInterval(loadEventReviews, minutesToMilliseconds(1));
    return () => clearInterval(id);
  }, []);

  const handleRemoveVideo = (videoItem) => {
    cameraActionsWindows.setDataForWindows(videoItem);
    cameraActionsWindows.openDeleteConfirmWindow();
  };

  const handlePlayVideo = (videoItem) => {
    cameraActionsWindows.setDataForWindows({
      ...videoItem,
      srcUrl: videoItem.videoUrl,
    });
    cameraActionsWindows.openVideoPreviewWindow();
  };

  const handleSaveVideo = async (videoItem, startDownload, endDownload) => {
    startDownload();

    try {
      const m3u8 = new M3U8();
      const download = m3u8.start(videoItem.videoUrl, { filename: `${videoItem.text}.mp4` });
      download.on('finished', endDownload).on('error', endDownload).on('aborted', endDownload);
    } catch (error) {
      console.error(error);
    }
  };

  if (isError) {
    return (
      <PageLayout menu={<ReportsMenu />} breadcrumbs={breadcrumbs}>
        <div style={{ flexDirection: 'column' }} className={classes.noData}>
          <div>{t('globalErrorOccurredReceiving')}</div>
          <Button disabled={isLoading} variant="outlined" color="primary" onClick={loadPageData}>
            {t('globalAgain')}
          </Button>
        </div>
      </PageLayout>
    );
  }

  if (isLoading) {
    return (
      <PageLayout menu={<ReportsMenu />} breadcrumbs={breadcrumbs}>
        <LinearProgress />
      </PageLayout>
    );
  }

  return (
    <PageLayout menu={<ReportsMenu />} breadcrumbs={breadcrumbs}>
      {cameraActionsWindows.ActionsWindows}

      <CameraBlocksContainer
        isNoData={eventReviews.length === 0}
        filtersComponent={(
          <FiltersPanel
            setDatetimeFilter={setDatetimeFilter}
            searchFilter={searchFilter}
            setSearchFilter={setSearchFilter}
          />
        )}
      >
        {eventReviews
          .filter((cameraItem) => cameraItem.text.toLowerCase().includes(searchFilter))
          .filter((cameraItem) => cameraItem.start_time >= datetimeFilter.from && cameraItem.end_time <= datetimeFilter.to)
          .map((eventReviewItem) => (
            <CameraBlock
              key={eventReviewItem.id}
              cameraItem={{
                ...eventReviewItem,
                id: eventReviewItem.id,
                srcUrl: eventReviewItem.thumbUrl,
                leftText: eventReviewItem.camera,
                rightText: eventReviewItem.datetimeText,
              }}
              onPlayVideo={handlePlayVideo}
              onRemoveVideo={handleRemoveVideo}
              onSaveVideo={handleSaveVideo}
            />
          ))}
      </CameraBlocksContainer>
    </PageLayout>
  );
};

export default CameraEventReportPage;
