export default {
  onCurrent: 1,
  onBs: 0,
  onStops: 1,
  onSides: 1,
  onPositions: 1,
  onGeofence: 1,
  onTower: 2,
  onLine: 1,
  onLine3D: 3,
  onMarkers: 1,
  onClustersCurrent: 0,
  onClustersMarkers: 0,
  onClustersPositions: 1,
  onClustersSides: 0,
  onClustersStops: 0,
  onTextCurrent: 1,
  onTextMarkers: 1,
  onTextPositions: 0,
  onTextSides: 0,
  onTextStops: 0,
};
