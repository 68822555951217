export class OpacityLayersControl {
  onAdd(map) {
    this.map = map;

    this.controlContainer = document.createElement('div');
    this.controlContainer.classList.add('maplibregl-ctrl', 'maplibregl-ctrl-group');

    this.button = document.createElement('button');
    this.button.type = 'button';
    this.button.classList.add('opacity-layers-button');
    this.loader = document.createElement('div');

    this.button.addEventListener('click', () => {
      this.setOpacityMapsOpen(true);
    });
    this.loader.addEventListener('click', () => {
      this.setOpacityMapsOpen(true);
    });

    this.controlContainer.appendChild(this.loader);
    this.controlContainer.appendChild(this.button);

    return this.controlContainer;
  }

  updateSetOpacity(setOpacityMapsOpen) {
    this.setOpacityMapsOpen = setOpacityMapsOpen;
  }

  updateIsLoaded(value) {
    this.isExtraMapLoaded = value;
    if (value) {
      this.loader.classList.remove('loader-animate')
    } else {
      this.loader.classList.add('loader-animate')
    }
  }
}
