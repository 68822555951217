import React from 'react';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import PinDropIcon from '@material-ui/icons/PinDrop';
import TuneIcon from '@material-ui/icons/Tune';
import StraightenIcon from '@material-ui/icons/Straighten';
import { toTitleCase } from '../../../utils/formatter';

const ComponentIcon = ({
  panel, value, onMouseEnter, addMarkerMode, rulerMode, item, parrent, child,
}) => {
  const getIconSrc = (value, check) => {
    let iconName;
    if (parrent === 'line' && child === 3 && panel.onLine3D === 3) {
      if (panel.onLine3D === 3) {
        return '/images/icon/switch/line3D-3.svg';
      }
    }

    if (child && child === check) {
      iconName = `${value}-1`;
    } else if (check && !child) {
      iconName = `${value}-${Number(check)}`;
    } else if (onMouseEnter === value) {
      iconName = `${value}-off-enter`;
    } else {
      iconName = `${value}-off-enter`; // убрать -enter если нужен серый цвет
    }
    return `/images/icon/switch/${iconName}.svg`;
  };

  const getIconAddMarkerSrc = () => {
    let iconName;
    if (addMarkerMode) {
      iconName = 'marker-add-1';
    } else if (onMouseEnter === 'markerAdd') {
      iconName = 'marker-add-off-enter';
    } else {
      iconName = 'marker-add-off-enter'; // убрать -enter если нужен серый цвет
    }
    return `/images/icon/switch/${iconName}.svg`;
  };

  switch (value) {
    case 'ruler':
      return <StraightenIcon style={{ color: '#000000' }} />;
    case 'tail':
      return (
        <img
          src={getIconSrc(value, item)}
          alt=""
        />
      );
    case 'extra':
      return <TuneIcon style={{ color: '#000000' }} />;
    case 'extraLayers':
      return <PinDropIcon style={{ color: '#000000' }} />;
    case 'layers':
      return <MoreHorizIcon style={{ color: '#000000' }} />;
    case 'markerAdd':
      return (
        <img
          src={getIconAddMarkerSrc()}
          alt=""
        />
      );
    default:
      return (
        <img
          src={getIconSrc(value, panel[`on${toTitleCase(parrent || value)}`])}
          alt=""
        />
      );
  }
};

export default ComponentIcon;
