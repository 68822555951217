import React from 'react';
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, makeStyles,
} from '@material-ui/core';
import 'react-calendar-timeline/lib/Timeline.css';
import { useTranslation } from '../../../common/components/LocalizationProvider';

const useStyles = makeStyles(() => ({
  confirmWindowContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
}));

const DeleteConfirmWindow = ({
  text, closeConfirmWindow, acceptConfirmWindow,
}) => {
  const classes = useStyles();
  const t = useTranslation();

  return (
    <Dialog open onClose={closeConfirmWindow}>
      <DialogTitle>{t('globalDeletionConfirmation')}</DialogTitle>
      <DialogContent>
        <div className={classes.confirmWindowContainer}>
          <Typography variant="subtitle2">
            {t('globalWantToDelete')}
            :
            {` ${text}?`}
          </Typography>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeConfirmWindow} color="primary">
          {t('sharedCancel')}
        </Button>
        <Button onClick={acceptConfirmWindow} style={{ color: '#d9534f' }}>
          {t('sharedRemove')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmWindow;
