import React, { useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Accordion, AccordionDetails, AccordionSummary,
  Box,
  Dialog,
  FormControl, IconButton, InputLabel, List, ListItem, ListItemText, MenuItem, Select, TextField, Tooltip, Typography, makeStyles,
} from '@material-ui/core';
import HelpIcon from '@material-ui/icons/HelpOutline';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from '../../../common/components/LocalizationProvider';

const useStyles = makeStyles((theme) => ({
  details: {
    flexDirection: 'column',
  },
  adornmentButton: {
    padding: 0,
  },
  modalContent: {
    maxHeight: 'calc(100% - 64px)',
    overflowY: 'auto',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  list: {
    padding: 0,
    marginBottom: theme.spacing(1),
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    padding: 0,
    marginBottom: theme.spacing(1),
  },
  fieldKey: {
    fontWeight: 'bold',
    marginRight: theme.spacing(1),
  },
}));

const GeocodeBlock = ({
  item, setItem,
}) => {
  const t = useTranslation();
  const classes = useStyles();
  const [openHelp, setOpenHelp] = useState(false);

  const handleReverseChanged = (e) => {
    if (e.target.value === 'off') {
      setItem({ ...item, attributes: { ...item.attributes, 'geocoder.enable': false } });
    } else {
      console.log({ 'geocoder.enable': true, 'geocoder.type': e.target.value });
      setItem({ ...item, attributes: { ...item.attributes, 'geocoder.enable': true, 'geocoder.type': e.target.value } });
    }
  };

  return (
    <>
      {/* Прямое геокодирование */}
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="subtitle1">
            {t('sharedGeocode')}
            <Tooltip title={t('sharedHelp')}>
              <IconButton className={classes.helpButton} onClick={(e) => { e.stopPropagation(); setOpenHelp('direct'); }}>
                <HelpIcon />
              </IconButton>
            </Tooltip>
          </Typography>
        </AccordionSummary>

        <AccordionDetails className={classes.details}>
          <FormControl variant="filled" fullWidth margin="normal">
            <InputLabel>{t('mapGeocodeProvider')}</InputLabel>
            <Select
              value={item.attributes.mapGeocodeProvider || 'off'}
              onChange={(e) => setItem({ ...item, attributes: { ...item.attributes, mapGeocodeProvider: e.target.value } })}
            >
              <MenuItem value="off">{t('mapGeocodeProviderOff')}</MenuItem>
              <MenuItem value="nominatim">{t('mapGeocodeProviderNominatim')}</MenuItem>
            </Select>
          </FormControl>
          {(item.attributes.mapGeocodeProvider === 'nominatim') && (
            <TextField
              label={t('mapGeoсodeProviderUrl')}
              margin="normal"
              value={item.attributes.mapGeoсodeProviderUrl || ''}
              onChange={(event) => setItem({ ...item, attributes: { ...item.attributes, mapGeoсodeProviderUrl: event.target.value } })}
              variant="filled"
            />
          )}
        </AccordionDetails>
      </Accordion>

      {/* Обратное геокодирование */}
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="subtitle1">
            {t('sharedReverseGeocode')}
            <Tooltip title={t('sharedHelp')}>
              <IconButton className={classes.helpButton} onClick={(e) => { e.stopPropagation(); setOpenHelp('reverse'); }}>
                <HelpIcon />
              </IconButton>
            </Tooltip>
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <FormControl variant="filled" fullWidth margin="normal">
            <InputLabel>{t('mapGeocodeProvider')}</InputLabel>
            <Select
              value={(item.attributes['geocoder.enable'] && item.attributes['geocoder.type']) || 'off'}
              onChange={handleReverseChanged}
            >
              <MenuItem value="off">{t('mapGeocodeProviderOff')}</MenuItem>
              <MenuItem value="nominatim">{t('mapGeocodeProviderNominatim')}</MenuItem>
            </Select>
          </FormControl>
          {(item.attributes['geocoder.enable'] && item.attributes['geocoder.type'] === 'nominatim') && (
            <TextField
              label={t('reverseMapGeoсodeProviderUrl')}
              margin="normal"
              value={item.attributes['geocoder.url'] || ''}
              onChange={(event) => setItem({ ...item, attributes: { ...item.attributes, 'geocoder.url': event.target.value } })}
              variant="filled"
            />
          )}
        </AccordionDetails>
      </Accordion>

      <Dialog
        open={openHelp}
        onClose={() => { setOpenHelp(false); }}
      >
        <Box className={classes.modalContent}>
          <IconButton className={classes.closeButton} onClick={() => { setOpenHelp(false); }}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" gutterBottom>
            {t('sharedHelp')}
          </Typography>
          <List className={classes.list}>
            {openHelp === 'direct' &&
              <ListItem>
                <ListItemText
                  primary={t('sharedGeocode')}
                  secondary={t('sharedGeocodeDescription')}
                />
              </ListItem>
            }
            {openHelp === 'reverse' &&
              <ListItem>
                <ListItemText
                  primary={t('sharedReverseGeocode')}
                  secondary={t('sharedReverseGeocodeDescription')}
                />
              </ListItem>
            }
          </List>
        </Box>
      </Dialog>
    </>
  );
};

export default GeocodeBlock;
