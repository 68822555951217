const smarttrackWhite = '#FFF';
const smarttrackBlack = '#2b2b2bf9';
const smarttrackGreen = '#4CAF50';
const smarttrackRed = '#CC2222';
const smarttrackBurgundy = '#962f2f';
const smarttrackGray = '#888888';
const smarttrackOrange = '#fa9639';
const smarttrackBlue = '#70c7fa';
const smarttrackBeige = '#ffe3b0';
const smarttrackBlue2 = '#4682B4';
const smarttrackYellow = '#d6ac02';

export default {
  common: {
    black: smarttrackBlack,
    green: smarttrackGreen,
    red: smarttrackRed,
    gray: smarttrackGray,
    orange: smarttrackOrange,
    burgundy: smarttrackBurgundy,
    blue: smarttrackBlue,
    beige: smarttrackBeige,
    blue2: smarttrackBlue2,
    yellow: smarttrackYellow,
  },
  primary: {
    main: smarttrackBlack,
  },
  secondary: {
    main: smarttrackGreen,
    contrastText: smarttrackWhite,
  },
  colors: {
    red: {
      color: smarttrackRed,
    },
    green: {
      color: smarttrackGreen,
    },
    gray: {
      color: smarttrackGray,
    },
    orange: {
      color: smarttrackOrange,
    },
  },
  replay: {
    replay0: '#074eb8',
    replay1: '#c94c4c',
    replay2: '#1d4524',
    replay3: '#6b5b95',
    replay4: '#ff7b25',
    replay5: '#3b3a30',
    replay6: '#618685',
    replay7: '#bc5a45',
    replay8: '#563f46',
    replay9: '#e0876a',
    replay10: '#074eb8',
    replay11: '#c94c4c',
    replay12: '#ff7b25',
    replay13: '#6b5b95',
    replay14: '#86af49',
    replay15: '#3b3a30',
    replay16: '#618685',
    replay17: '#bc5a45',
    replay18: '#563f46',
    replay19: '#e0876a',
    replay20: '#074eb8',
    replay21: '#c94c4c',
    replay22: '#ff7b25',
    replay23: '#6b5b95',
    replay24: '#86af49',
    replay25: '#3b3a30',
    replay26: '#618685',
    replay27: '#bc5a45',
    replay28: '#563f46',
    replay29: '#e0876a',
    replay30: '#ababab',
  },
  tracks: {
    replay0: '#074eb8',
    replay1: '#c94c4c',
    replay2: '#1d4524',
    replay3: '#6b5b95',
    replay4: '#ff7b25',
    replay5: '#3b3a30',
    replay6: '#618685',
    replay7: '#bc5a45',
    replay8: '#563f46',
    replay9: '#e0876a',
    replay10: '#ababab',
  },
  markers: {
    black: '#000000',
    darkRed: '#8b0000',
    crimson: '#dc143c',
    mediumBlue: '#0000cd',
    dodgerBlue: '#1e90ff',
    darkCyan: '#008b8b',
    goldenRod: '#daa520',
    forestGreen: '#228b22',
    olive: '#808000',
    siena: '#a0522d',
    darkViolet: '#9400d3',
    slateBlue: '#6a5acd',
  },
  clusters: {
    red: '#FF1744',
    pink: '#FF007F',
    purple: '#9C27B0',
    skyBlue: '#2962FF',
    blue: '#00B0FF',
    turquoise: '#1DE9B6',
    green: '#00E676',
    lime: '#76FF03',
    yellow: '#FFEB3B',
    orange: '#FF5722',
  },
};
