import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'warnings',
  initialState: {
    warnings: [],
  },
  reducers: {
    push(state, action) {
      state.warnings.push(action.payload);
    },
    pop(state) {
      if (state.warnings.length) {
        state.warnings.shift();
      }
    },
  },
});

export { actions as warningsActions };
export { reducer as warningsReducer };
