import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';

import { useHistory } from 'react-router-dom';
import ClosedDeviceInitialization from './ClosedDeviceInitialization';
import {
  bsActions, devicesActions, eventsActions, geofencesActions, groupsActions, markersActions,
  positionsActions, iconsActions, sessionActions, smsActions, tailActions, unionsActions,
} from './store';
import { useEffectAsync } from './common/utils/reactHelper';
import logout from './common/utils/logout';
import { filterCurrent } from './common/utils/formatter';
import { isUndefinedOrNull } from './common/utils/stringUtils';
import { reloadResourcesOnSite } from './common/utils/domHelper';
import { iconsCoverter } from './common/utils/converter';

const CachingController = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const authenticated = useSelector((state) => !!state.session.user);
  const user = useSelector((state) => state.session.user);
  const server = useSelector((state) => state.session.server);
  const devices = useSelector((state) => Object.values(state.devices.items));
  const stateSyncDevices = useSelector((state) => state.devices.stateSyncDevices);
  const closedDevices = useSelector((state) => state.devices.closedDevices);
  const stateSyncClosedDevices = useSelector((state) => state.devices.stateSyncClosedDevices);
  const categories = useSelector((state) => state.devices.categories);
  const servicesVersions = useSelector((state) => state.session.servicesVersions);

  const devicesRef = useRef(devices);
  const closedDevicesRef = useRef(closedDevices);
  const categoriesRef = useRef(categories);

  const [groupInited, setGroupInited] = useState(false);
  const [closedDevicesInited, setClosedDevicesInited] = useState(false);

  const bscoderUrl = server?.attributes.services?.bscoder?.url;

  useEffectAsync(async () => {
    let websiteVersion;
    let serverVersion;

    const [websiteResponse, serverResponse] = await Promise.all([
      fetch('/version.txt'),
      fetch('/api/server/version'),
    ]);

    if (websiteResponse.ok) {
      websiteVersion = await websiteResponse.text();
    }
    if (serverResponse.ok) {
      serverVersion = (await serverResponse.json()).version;
    }

    const newVersions = {
      website: websiteVersion,
      server: serverVersion,
    };

    dispatch(sessionActions.updateServicesVersions(newVersions));
  }, []);

  useEffect(() => {
    if (isUndefinedOrNull(servicesVersions.website)) return;

    const websiteVersion = localStorage.getItem('websiteVersion');

    if (isUndefinedOrNull(websiteVersion)) {
      localStorage.setItem('websiteVersion', JSON.stringify(servicesVersions));
      return;
    }

    const localVersions = JSON.parse(websiteVersion);

    if (localVersions.website === servicesVersions.website
      && localVersions.server === servicesVersions.server) {
      return;
    }

    localStorage.setItem('websiteVersion', JSON.stringify(servicesVersions));
    reloadResourcesOnSite();
    window.location.reload();
  }, [servicesVersions]);

  useEffect(() => {
    devicesRef.current = devices;
  }, [stateSyncDevices]);

  useEffect(() => {
    closedDevicesRef.current = closedDevices;
  }, [stateSyncClosedDevices]);

  useEffect(() => {
    categoriesRef.current = categories;
  }, [categories]);

  useEffectAsync(async () => {
    if (authenticated) {
      const response = await fetch('/api/devices/categories');
      if (response.ok) {
        const result = await response.json();
        const exitingCategories = new Set(['detected_object', 'radar', 'anti_uav', 'vcam']);
        dispatch(devicesActions.addAccessibleCategories(result.filter((cat) => exitingCategories.has(cat))));

        const updatedCategories = { ...categories };

        result.forEach((key) => {
          if (!updatedCategories.hasOwnProperty(key)) {
            updatedCategories[key] = {
              title: key,
              expanded: true,
              order: Object.keys(updatedCategories).length,
              iconText: null,
              iconCluster: true,
            };
          }
        });

        Object.keys(updatedCategories).forEach((key) => {
          if (!result.includes(key)) {
            delete updatedCategories[key];
          }
        });

        dispatch(devicesActions.changeCategories(updatedCategories));
      }
    }
  }, [authenticated]);

  useEffectAsync(async () => {
    if (authenticated) {
      let response;
      if (user.administrator) {
        response = await fetch('/api/unions?all=true');
      } else {
        response = await fetch(`/api/unions?userId=${user.id}`);
      }
      if (response.ok) {
        dispatch(unionsActions.update(await response.json()));
      } else if (response.status === 401) {
        dispatch(sessionActions.updateUser(null));
      }
    }
  }, [authenticated, user]);

  useEffectAsync(async () => {
    if (authenticated) {
      const response = await fetch('/api/icons');
      if (response.ok) {
        const data = await response.json();
        dispatch(iconsActions.init(iconsCoverter(data)));
      } else if (response.status === 401) {
        dispatch(sessionActions.updateUser(null));
      }
    }
  }, [authenticated, user]);

  useEffectAsync(async () => {
    if (authenticated) {
      let response;
      if (user.administrator) {
        response = await fetch('/api/groups?all=true');
      } else {
        response = await fetch(`/api/groups?userId=${user.id}`);
      }
      if (response.ok) {
        dispatch(groupsActions.update(await response.json()));
        setGroupInited(true);
      } else if (response.status === 401) {
        dispatch(sessionActions.updateUser(null));
      }
    }
  }, [authenticated, user]);

  useEffectAsync(async () => {
    if (authenticated) {
      const response = await fetch('/api/geofences');
      if (response.ok) {
        dispatch(geofencesActions.update(await response.json()));
      } else if (response.status === 401) {
        logout(history, dispatch);
      }
    }
  }, [authenticated]);

  useEffectAsync(async () => {
    if (authenticated) {
      const response = await fetch(`/api/markers?userId=${user.id}`);
      if (response.ok) {
        dispatch(markersActions.update(await response.json()));
      } else if (response.status === 401) {
        logout(history, dispatch);
      }
    }
  }, [authenticated]);

  useEffectAsync(async () => {
    if (authenticated) {
      const response = await fetch('/api/sms/new');
      if (response.ok) {
        dispatch(smsActions.update(await response.json()));
        dispatch(smsActions.updateNewSmsInitialized(true));
      } else if (response.status === 401) {
        logout(history, dispatch);
      }
    }
  }, [authenticated]);

  useEffectAsync(async () => {
    if (authenticated && bscoderUrl) {
      try {
        const response = await fetch('/api/bscoder');
        if (response.ok) {
          dispatch(bsActions.update(true));
          const bscoder = await response.text();
          dispatch(sessionActions.updateServicesVersions({ bscoder }));
        } else if (response.status === 401) {
          logout(history, dispatch);
        }
      } catch {
        console.warn('BSCoder сервис недоступен');
      }
    }
  }, [authenticated, bscoderUrl]);

  useEffectAsync(async () => {
    if (authenticated) {
      const response = await fetch('/api/server/date_expiration');
      if (response.ok) {
        dispatch(sessionActions.updateTimeExpiration(await response.text()));
      }
    }
  }, [authenticated]);

  useEffect(() => {
    const removeDevices = () => {
      const removedDevices = [];
      devicesRef.current.forEach((device) => {
        if (!filterCurrent(device, server)) {
          removedDevices.push(device.id);
        }
      });
      if (removedDevices.length > 0) {
        dispatch(devicesActions.remove(removedDevices));
        dispatch(tailActions.removeDevices(removedDevices));
        dispatch(positionsActions.remove(removedDevices));
        dispatch(tailActions.remove(removedDevices));
        dispatch(eventsActions.deleteOld());
      }
    };
    removeDevices();
    const clearDevicesInterval = setInterval(() => removeDevices(), 10000);
    return () => {
      removeDevices();
      clearInterval(clearDevicesInterval);
    };
  }, [server]);

  if (!closedDevicesInited) {
    return (
      <ClosedDeviceInitialization
        closedDevicesInited={closedDevicesInited}
        setClosedDevicesInited={setClosedDevicesInited}
        groupInited={groupInited}
      />
    );
  }

  return null;
};

export default connect()(CachingController);
