import React, { useState } from 'react';
import Drawer from '@material-ui/core/Drawer';
import ContainerDimensions from 'react-container-dimensions';
import { useHistory } from 'react-router-dom';
import ListIcon from '@material-ui/icons/ViewList';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useDispatch } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import {
  Divider, isWidthUp, withWidth, Typography, IconButton, Button, Tooltip, makeStyles,
} from '@material-ui/core';
import { useTranslation } from '../../common/components/LocalizationProvider';
import Map from '../../map/Map';
import SearchField from '../../common/form/SearchField';
import SelectedGeofenceMap from '../../map/SelectedGeofenceMap';
import GeofenceEditMap from '../../map/GeofenceEditMap';
import { geofencesActions } from '../../store';
import GeofencesList from './components/GeofencesList';
import GeofenceTablet from './components/GeofenceTablet';
import logout from '../../common/utils/logout';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flexGrow: 1,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
    },
  },
  drawerPaper: {
    position: 'fixed',
    [theme.breakpoints.up('sm')]: {
      width: 350,
    },
    [theme.breakpoints.down('xs')]: {
      maxHeight: 250,
      paddingBottom: theme.spacing(2),
    },
  },
  drawerHeader: {
    ...theme.mixins.toolbar,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
  },
  mapContainer: {
    flexGrow: 1,
  },
  sidebarToggle: {
    position: 'fixed',
    left: theme.spacing(1.5),
    top: theme.spacing(3),
    borderRadius: '0px',
    minWidth: 0,
    backgroundColor: 'white',
    [theme.breakpoints.down('md')]: {
      left: 0,
    },
  },
  homeToggle: {
    position: 'fixed',
    left: theme.spacing(1.5),
    top: theme.spacing(13),
    borderRadius: '0px',
    minWidth: 0,
    backgroundColor: 'white',
    [theme.breakpoints.down('md')]: {
      left: 0,
    },
  },
  HomeToggleBg: {
    backgroundColor: 'white',
    color: '#777777',
  },
  sidebarToggleBg: {
    backgroundColor: 'white',
    color: '#777777',
  },
  sidebarToggleText: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.only('xs')]: {
      display: 'none',
    },
  },
  buttonBack: {
    margin: 0,
    textAlign: 'right',
  },
}));

const GeofencesPage = ({ width }) => {
  const classes = useStyles();
  const history = useHistory();
  const t = useTranslation();
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(false);
  const [saveMode, setSaveMode] = useState(false);
  const [searchField, setSearchField] = useState(false);
  const [searchName, setSearchName] = useState('');

  const refreshGeofences = async () => {
    const response = await fetch('/api/geofences');
    if (response.ok) {
      dispatch(geofencesActions.refresh(await response.json()));
    } else if (response.status === 401) {
      logout(history, dispatch);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.mapContainer}>
          <ContainerDimensions>
            <Map>
              <SelectedGeofenceMap />
              <GeofenceEditMap setSaveMode={setSaveMode} refreshGeofences={refreshGeofences} />
            </Map>
          </ContainerDimensions>
        </div>
        {collapsed && (
          <>
            <Button
              variant="contained"
              classes={{ containedPrimary: classes.HomeToggleBg }}
              className={classes.sidebarToggle}
              onClick={() => setCollapsed(!collapsed)}
              disableElevation
            >
              <ListIcon />
              <div className={classes.sidebarToggleText}>{t('sharedGeofences')}</div>
            </Button>
            <Tooltip title={t('backTitle')}>
              <Button
                variant="contained"
                classes={{ containedPrimary: classes.sidebarToggleBg }}
                className={classes.homeToggle}
                onClick={() => history.push('settings/')}
                disableElevation
              >
                <ArrowBackIcon />
              </Button>
            </Tooltip>
          </>
        )}
        <Drawer
          variant="persistent"
          open={!collapsed}
          anchor={isWidthUp('sm', width) ? 'left' : 'bottom'}
          classes={{ paper: classes.drawerPaper }}
        >
          <div className={classes.drawerHeader}>
            <Tooltip title={t('backTitle')}>
              <IconButton onClick={() => history.push('settings/')}>
                <ArrowBackIcon />
              </IconButton>
            </Tooltip>

            {!searchField && (
              <Typography variant="h6" color="inherit" noWrap style={{ width: '100%' }}>
                {t('sharedGeofences')}
              </Typography>
            )}
            {!searchField ? (
              <Tooltip title={t('searchGeofenceTitle')}>
                <IconButton onClick={() => setSearchField(true)}>
                  <SearchIcon />
                </IconButton>
              </Tooltip>

            ) : (
              <>
                <SearchField searchName={searchName} setSearchName={setSearchName} placeholder={t('searchGeofenceName')} />
                <Tooltip title={t('searchFieldTitle')}>
                  <IconButton
                    onClick={() => {
                      setSearchField(false);
                      setSearchName('');
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}
            <Tooltip title={t('collapseTitle')}>
              <IconButton onClick={() => setCollapsed(!collapsed)}>
                <ArrowBackIosIcon />
              </IconButton>
            </Tooltip>
          </div>
          <Divider />
          <GeofencesList setSaveMode={setSaveMode} searchName={searchName} />
        </Drawer>
        {saveMode && <GeofenceTablet setSaveMode={setSaveMode} id={saveMode} refreshGeofences={refreshGeofences} />}
      </div>
    </div>
  );
};

export default withWidth()(GeofencesPage);
