import React, { useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, Dialog, DialogActions,
  DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, Grid, MenuItem,
  Select, TextField, Typography, makeStyles,
} from '@material-ui/core';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import tailPeriodsChoosing from '../../../common/static/tailPeriodsChoosing';
import tailPeriods from '../../../common/static/tailPeriods';
import {
  getAttr, getPeriodName, isValid, setAttr, toTitleCase,
} from '../../../common/utils/formatter';

const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
  adornmentButton: {
    padding: 0,
  },
}));

const timesList = ['second', 'minute', 'hour', 'day', 'month'];

const MIN_VALUE = 0;
const MAX_VALUE = 500;

const TimeoutsBlock = ({
  item, setItem, categoryLifetime, setCategoryLifetime,
  temporaryLifetime, setTemporaryLifetime,
}) => {
  const t = useTranslation();
  const classes = useStyles();

  const [periodDialogOpen, setPeriodDialogOpen] = useState(false);
  // Настройки периода времени, когда скрывать устройства в левом боковом меню
  const [lifetimeDevicesDialog, setLifetimeDevicesDialog] = useState(false);

  const sortPeriod = (a, b) => {
    const aArray = a.split('_');
    const bArray = b.split('_');
    const aSuf = Number(aArray[0]);
    const bSuf = Number(bArray[0]);
    const aPre = aArray[1];
    const bPre = bArray[1];
    if (aPre === bPre) {
      return aSuf - bSuf;
    }
    if (aPre === 's' && ['m', 'h'].includes(bPre)) {
      return -1;
    }
    if (aPre === 'm' && bPre === 'h') {
      return -1;
    }
    return 0;
  };

  const changeTailPeriod = (val) => {
    const periods = [...(getAttr(item, 'tailPeriods') || tailPeriods)];
    const valIndex = periods.indexOf(val);
    if (valIndex === -1) {
      periods.push(val);
    } else {
      periods.splice(valIndex, 1);
    }
    setAttr(setItem, 'tailPeriods', periods.sort(sortPeriod));
  };

  const getCheckedPeriod = (val) => {
    const periods = getAttr(item, 'tailPeriods') || tailPeriods;
    return periods.indexOf(val) !== -1;
  };

  // const concatValue = (val, symbol = '_') => `${val.value}${symbol}${val.time}`;

  const isNumberRangeCorrect = (event) => event.target.value >= MIN_VALUE && event.target.value <= MAX_VALUE;

  const handleChoiceForTemporaryDevice = (item) => {
    if (typeof item === 'number' && (item > MIN_VALUE && item <= MAX_VALUE)) {
      setTemporaryLifetime((prev) => ({ ...prev, value: item }));
    } else {
      setTemporaryLifetime((prev) => ({ ...prev, time: item }));
    }
  };

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="subtitle1">
          {t('sharedTimeouts')}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        <Typography variant="body2" style={{ textAlign: 'center' }}>
          {t('logLifetimeTitle')}
        </Typography>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={6}>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={getAttr(item, 'logEnable')}
                  onChange={(event) => setAttr(setItem, 'logEnable', event.target.checked)}
                />
              )}
              label={t('logEnableTitle')}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="normal"
              value={item.logLifetime}
              onChange={(event) => {
                const value = Number(event.target.value);
                if (!Number.isNaN(value)) {
                  setItem({ ...item, logLifetime: value });
                }
              }}
              label={`${t('logLifetimeTimeTitle')}, ${t('sharedDays')}`}
              variant="filled"
              disabled={!getAttr(item, 'logEnable')}
              inputProps={{ type: 'number' }}
            />
          </Grid>
        </Grid>

        <Typography variant="body2" style={{ textAlign: 'center' }}>
          {t('temporaryStoragetimeTitle')}
        </Typography>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={6}>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={getAttr(item, 'temporaryStoragetimeAlways') ?? true}
                  onChange={(event) => setAttr(setItem, 'temporaryStoragetimeAlways', event.target.checked)}
                />
              )}
              label={t('sharedNotDelete')}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="normal"
              value={getAttr(item, 'temporaryStoragetime') ?? 0}
              onChange={(event) => {
                const value = Number(event.target.value);
                if (!Number.isNaN(value)) {
                  setAttr(setItem, 'temporaryStoragetime', value);
                }
              }}
              label={`${t('temporaryStoragetimeTimeTitle')}, ${t('sharedDays')}`}
              variant="filled"
              disabled={getAttr(item, 'temporaryStoragetimeAlways') ?? true}
            />
          </Grid>
        </Grid>

        <Typography variant="body2" style={{ textAlign: 'center' }}>
          {t('positionStoragetimeTitle')}
        </Typography>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={6}>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={getAttr(item, 'positionStoragetimeAlways') ?? true}
                  onChange={(event) => setAttr(setItem, 'positionStoragetimeAlways', event.target.checked)}
                />
              )}
              label={t('sharedNotDelete')}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="normal"
              value={getAttr(item, 'positionStoragetime') ?? 0}
              onChange={(event) => {
                const value = Number(event.target.value);
                if (!Number.isNaN(value)) {
                  setAttr(setItem, 'positionStoragetime', value);
                }
              }}
              label={`${t('positionStoragetimeTimeTitle')}, ${t('sharedDays')}`}
              variant="filled"
              disabled={getAttr(item, 'positionStoragetimeAlways') ?? true}
            />
          </Grid>
        </Grid>

        <Button onClick={() => setPeriodDialogOpen(true)} fullWidth variant="contained">
          {t('tailTimeTitle')}
        </Button>

        <Dialog
          maxWidth="xs"
          open={periodDialogOpen}
          onClose={() => setPeriodDialogOpen(false)}
        >
          <DialogTitle>{t('tailTimeTitle')}</DialogTitle>
          <DialogContent
            style={{ width: 250 }}
          >
            <FormGroup>
              {tailPeriodsChoosing.map((val) => (
                <FormControlLabel
                  key={val}
                  control={<Checkbox checked={getCheckedPeriod(val)} onChange={() => changeTailPeriod(val)} />}
                  label={getPeriodName(val, t)}
                />
              ))}
            </FormGroup>
          </DialogContent>

          <DialogActions>
            <Button onClick={() => setPeriodDialogOpen(false)}>Закрыть</Button>
          </DialogActions>
        </Dialog>

        {/* Кнопка для открытия формы настройки периода времени, когда скрывать устройства в левом боковом меню */}
        <Button onClick={() => setLifetimeDevicesDialog(true)} fullWidth variant="contained">
          {t('serverPageDeviceDisplayPeriod')}
        </Button>

        {/* Форма для настройки периода времени, когда скрывать устройства в левом боковом меню */}
        <Dialog
          maxWidth="xs"
          open={lifetimeDevicesDialog}
          onClose={() => setLifetimeDevicesDialog(false)}
        >
          <DialogTitle>{t('serverPageDeviceDisplayPeriod')}</DialogTitle>
          <DialogContent
            style={{ width: 350 }}
          >
            <Grid container spacing={1}>
              {Object.entries(categoryLifetime).map(([category, lifetime]) => (
                <Grid item xs={12} key={category}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={8}>
                      <TextField
                        label={t(`serverPage${toTitleCase(category)}`)}
                        value={lifetime.value}
                        inputProps={{ min: MIN_VALUE, max: MAX_VALUE }}
                        fullWidth
                        margin="normal"
                        variant="filled"
                        type="number"
                        onChange={(e) => isNumberRangeCorrect(e)
                          && setCategoryLifetime((prevValue) => ({ ...prevValue, [category]: { ...prevValue[category], value: Number(e.target.value) } }))}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl
                        style={{ whiteSpace: 'nowrap' }}
                        fullWidth
                        margin="normal"
                      >
                        <Select
                          value={lifetime.time}
                          fullWidth
                          onChange={(e) => setCategoryLifetime((prevValue) => ({ ...prevValue, [category]: { ...prevValue[category], time: e.target.value } }))}
                        >
                          {timesList.map((val) => (
                            <MenuItem key={val} value={val}>
                              {t(`globalTime${toTitleCase(val)}`)}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              ))}

              <Grid item xs={12}>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={8}>
                    <TextField
                      label={t('serverPageTemporary')}
                      value={temporaryLifetime.value}
                      inputProps={{ min: MIN_VALUE, max: MAX_VALUE }}
                      fullWidth
                      margin="normal"
                      variant="filled"
                      type="number"
                      onChange={(event) => handleChoiceForTemporaryDevice(Number(event.target.value), temporaryLifetime.value)}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl
                      style={{ whiteSpace: 'nowrap' }}
                      fullWidth
                      margin="normal"
                    >
                      <Select
                        value={temporaryLifetime.time}
                        fullWidth
                        onChange={(event) => handleChoiceForTemporaryDevice(event.target.value, temporaryLifetime.time)}
                      >
                        {timesList.map((val) => (
                          <MenuItem key={val} value={val}>
                            {t(`globalTime${toTitleCase(val)}`)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setLifetimeDevicesDialog(false)}>Закрыть</Button>
          </DialogActions>
        </Dialog>

        <TextField
          label={`${t('settingsDeviceTimeout')}, ${t('sharedS')}`}
          margin="normal"
          variant="filled"
          value={getAttr(item, 'status.timeout')}
          onChange={(e) => setAttr(setItem, 'status.timeout', e.target.value)}
          error={!isValid('status.timeout', getAttr(item, 'status.timeout'))}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default TimeoutsBlock;
