import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import {
  abortRequest,
  collectReport,
  formatDate, formatSmsType, getSortedArray,
} from '../../common/utils/formatter';
import { useTranslation } from '../../common/components/LocalizationProvider';
import usePersistedState from '../../common/utils/usePersistedState';
import { getIsAdmin } from '../../common/utils/selectors';
import { useEffectAsync } from '../../common/utils/reactHelper';
import logout from '../../common/utils/logout';
import ReportTemplate from './components/ReportTemplate';
import { errorsActions } from '../../store';

const typeReport = 'smsReport';

const columnsArray = [
  ['deviceId', 'reportDevice', 1],
  ['group', 'reportGroup', 1],
  ['userId', 'reportSender', 0],
  ['smsTime', 'reportSmsTime', 1],
  ['message', 'reportMessage', 0],
  ['type', 'reportTypeMessage', 0],
  ['success', 'reportNote', 0],
];

const typesSorting = {
  deviceId: (a, b) => getSortedArray(a.deviceId, b.deviceId),
  deviceIdReverse: (a, b) => getSortedArray(a.deviceId, b.deviceId, true),
  group: (a, b) => getSortedArray(a.groupId, b.groupId),
  groupReverse: (a, b) => getSortedArray(a.groupId, b.groupId, true),
  smsTime: (a, b) => getSortedArray(a.smsTime, b.smsTime),
  smsTimeReverse: (a, b) => getSortedArray(a.smsTime, b.smsTime, true),
};

const SmsReportPage = () => {
  const t = useTranslation();
  const [offColumns, setOffColumns] = usePersistedState(typeReport, []);
  const groups = useSelector((state) => state.groups.items);
  const history = useHistory();
  const dispatch = useDispatch();
  const isAdmin = useSelector(getIsAdmin);
  const [items, setItems] = useState([]);
  const [users, setUsers] = useState([]);
  const [devicesList, setDevicesList] = useState([]);
  const [devicesObject, setDevicesObject] = useState({});

  const [progress, setProgress] = useState(0);
  const [progressDevices, setProgressDevices] = useState(false);
  const [page, setPage] = useState(0);
  const [periodChosen, setPeriodChosen] = useState(false);
  const [countProgress, setCountProgress] = useState(0);
  const [progressReport, setProgressReport] = useState(0);
  const reportAbortController = useRef();

  const columnsArrayFiltered = columnsArray.filter((column) => !offColumns.includes(column[0]));

  const handleSubmit = (deviceId, from, to, headers) => {
    const requestName = uuidv4();
    reportAbortController.current = new AbortController();
    const { signal } = reportAbortController.current;
    const apiCall = async () => {
      try {
        const query = new URLSearchParams({
          from, to,
        });
        deviceId.forEach((it) => query.append('deviceId', it));
        columnsArrayFiltered.forEach((it) => query.append('column', it[0]));
        setProgress(1);
        const response = await fetch(`/api/reports/sms-history?${query.toString()}`, { headers, signal });
        if (response.ok) {
          const contentType = response.headers.get('content-type');
          if (contentType) {
            if (contentType === 'application/json') {
              setPage(0);
              const result = [];
              await collectReport(response, result, setProgressReport);
              setItems(result);
            } else {
              window.location.assign(window.URL.createObjectURL(await response.blob()));
            }
          }
        } else if (response.status === 401) {
          logout(history, dispatch);
        }
        setProgress(0);
      } catch (error) {
        if (error.name !== 'AbortError') {
          dispatch(errorsActions.push(error.message));
        } else {
          await abortRequest(requestName);
        }
        setProgress(0);
      }
    };
    apiCall();
  };

  const formatValue = (item, key, devices) => {
    switch (key) {
      case 'userId':
        return users.length && item[key] ? users.find((v) => v.id === item[key]).name : '';
      case 'smsTime':
        return formatDate(item[key]);
      case 'deviceId':
        return devices[item[key]]?.name;
      case 'group':
        return groups[devices[item.deviceId].groupId]?.name || '';
      case 'type':
        return formatSmsType(item[key], t);
      case 'success':
        return item[key] ? '' : t('reportSmsNoSuccess');
      default:
        return item[key];
    }
  };

  useEffectAsync(async () => {
    if (isAdmin) {
      const response = await fetch('/api/users');
      if (response.ok) {
        setUsers(await response.json());
      } else if (response.status === 401) {
        logout(history, dispatch);
      }
    }
  }, []);

  useEffect(() => {
    if (periodChosen) {
      const abortController = new AbortController();
      const { signal } = abortController;

      const apiCall = async () => {
        const requestName = uuidv4();
        try {
          setProgressDevices(true);
          const response = await fetch(`/api/devices/stream?requestName=${requestName}`, { signal });
          if (response.ok) {
            const result = [];
            await collectReport(response, result, setCountProgress);
            setDevicesList(result);

            const resultObj = {};
            result.forEach((item) => resultObj[item.id] = item);
            setDevicesObject(resultObj);
          } else if (response.status === 401) {
            if (response.status === 401) {
              logout(history, dispatch);
            }
          }
          setProgressDevices(false);
        } catch (error) {
          if (error.name !== 'AbortError') {
            dispatch(errorsActions.push(error.message));
          } else {
            await abortRequest(requestName);
          }
          setProgressDevices(false);
        }
      };
      apiCall();
      return () => {
        abortController.abort(); // Cancel the request if component unmounts
      };
    }
    return null;
  }, [periodChosen]);

  useEffect(() => () => {
    if (reportAbortController.current) {
      reportAbortController.current.abort(); // Cancel the request
    }
  }, []);

  return (
    <ReportTemplate
      items={items}
      progress={progress}
      progressDevices={progressDevices}
      columnsArray={columnsArray}
      handleSubmit={handleSubmit}
      typeSortingDefault="smsTime"
      formatValue={formatValue}
      page={page}
      setPage={setPage}
      periodChosen={periodChosen}
      setPeriodChosen={setPeriodChosen}
      typesSorting={typesSorting}
      typeReport={typeReport}
      offColumns={offColumns}
      setOffColumns={setOffColumns}
      columnsArrayFiltered={columnsArrayFiltered}
      breadcrumbs={['reportTitle', 'reportSms']}
      devicesList={devicesList}
      devicesObject={devicesObject}
      countProgress={countProgress}
      progressReport={progressReport}
    />
  );
};

export default SmsReportPage;
