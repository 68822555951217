import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {
  Grid, Button, TextField, Typography, Link, Snackbar, makeStyles,
} from '@material-ui/core';
import StartPage from './StartPage';
import { useTranslation } from '../../common/components/LocalizationProvider';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: theme.spacing(3),
    fontWeight: 500,
    marginLeft: theme.spacing(2),
    textTransform: 'uppercase',
  },
  link: {
    fontSize: theme.spacing(3),
    fontWeight: 500,
    marginTop: theme.spacing(0.5),
    cursor: 'pointer',
  },
}));

const RegisterForm = () => {
  const classes = useStyles();
  const history = useHistory();
  const t = useTranslation();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmationTrouble, setConfirmationTrouble] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleSubmit = async () => {
    if (password !== confirmPassword) {
      setConfirmationTrouble(true);
    } else {
      const response = await fetch('/api/users', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          name, email, login, password,
        }),
      });
      if (response.ok) {
        setSnackbarOpen(true);
      }
    }
  };

  return (
    <StartPage>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        onClose={() => history.push('/login')}
        autoHideDuration={6000}
        message={t('loginCreated')}
      />
      <Grid container direction="column" spacing={2}>
        <Grid container item>
          <Grid item>
            <Typography className={classes.link} color="primary">
              <Link onClick={() => history.push('/login')}>
                <ArrowBackIcon />
              </Link>
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography className={classes.title} color="primary">
              {t('loginRegister')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <TextField
            required
            fullWidth
            label={t('sharedName')}
            name="name"
            value={name}
            autoComplete="name"
            autoFocus
            onChange={(event) => setName(event.target.value)}
            variant="filled"
          />
        </Grid>
        <Grid item>
          <TextField
            required
            fullWidth
            type="text"
            label={t('userLogin')}
            name="login"
            value={login}
            autoComplete="login"
            onChange={(event) => setLogin(event.target.value)}
            variant="filled"
          />
        </Grid>
        <Grid item>
          <TextField
            fullWidth
            type="email"
            label={t('userEmail')}
            name="email"
            value={email}
            autoComplete="email"
            onChange={(event) => setEmail(event.target.value)}
            variant="filled"
          />
        </Grid>
        <Grid item>
          <TextField
            required
            fullWidth
            label={t('userPassword')}
            name="password"
            value={password}
            error={confirmationTrouble}
            type="password"
            autoComplete="current-password"
            onChange={(event) => {
              setPassword(event.target.value);
              if (confirmationTrouble) {
                setConfirmationTrouble(false);
              }
            }}
            variant="filled"
          />
        </Grid>
        <Grid item>
          <TextField
            fullWidth
            type="password"
            autoComplete="current-password"
            required
            error={confirmationTrouble}
            helperText={confirmationTrouble && t('userConfirmationTrouble')}
            onChange={(event) => {
              setConfirmPassword(event.target.value);
              if (confirmationTrouble) {
                setConfirmationTrouble(false);
              }
            }}
            label={t('userConfirmPassword')}
            variant="filled"
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSubmit}
            disabled={!name || !login || !password || !confirmPassword}
            fullWidth
          >
            {t('loginRegister')}
          </Button>
        </Grid>
      </Grid>
    </StartPage>
  );
};

export default RegisterForm;
