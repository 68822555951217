import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import MarkerMovingModeOn from './MarkerMovingModeOn';
import TrackingModeOn from './TrackingModeOn';
import NewMessages from './NewMessages';
import UpdatingNeedableButton from './UpdatingNeedableButton';
import EventNotifications from './EventNotifications';

const useStyles = makeStyles((theme) => ({
  flexWrapper: {
    position: 'fixed',
    top: theme.spacing(1),
    right: theme.spacing(13),
    display: 'flex',
    gap: theme.spacing(1),
  },
  flexWrapperMoved: {
    right: theme.spacing(62),
  },
}));

const NotificationButtons = ({
  moveableMarker, setMoveableMarker, newSms, needMoveMap, notificationsDataVisible, setNotificationsDataVisible,
}) => {
  const classes = useStyles();
  const socketOpened = useSelector((state) => state.session.socketOpened);
  const deviceSelected = useSelector((state) => state.devices.selectedId);
  const events = useSelector((state) => state.events.items);

  return (
    <>
      <div className={`${classes.flexWrapper} ${needMoveMap && classes.flexWrapperMoved}`}>
        {moveableMarker && (
          <MarkerMovingModeOn moveableMarker={moveableMarker} setMoveableMarker={setMoveableMarker} needMoveMap={needMoveMap} />
        )}
        {!!newSms.length && (
          <NewMessages newSms={newSms} />
        )}
        {!socketOpened && (
          <UpdatingNeedableButton />
        )}

        {deviceSelected && (
          <TrackingModeOn deviceSelected={deviceSelected} />
        )}
        {(!!events.length || notificationsDataVisible) && (
          <EventNotifications events={events} notificationsDataVisible={notificationsDataVisible} setNotificationsDataVisible={setNotificationsDataVisible} />
        )}
      </div>
    </>
  );
};

export default NotificationButtons;
