import React from 'react';
import HomeIcon from '@material-ui/icons/Home';
import { useHistory } from 'react-router-dom';
import ListIcon from '@material-ui/icons/ViewList';
import {
  AppBar, Toolbar, Typography, IconButton, Tooltip, makeStyles,
} from '@material-ui/core';
import { useTranslation } from './LocalizationProvider';

const useStyles = makeStyles(() => ({
  buttonBack: {
    marginLeft: 'auto',
  },
}));

const Navbar = ({ setOpenDrawer, title }) => {
  const classes = useStyles();
  const history = useHistory();
  const t = useTranslation();

  return (
    <AppBar position="fixed" color="inherit">
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={() => setOpenDrawer(true)}
        >
          <ListIcon />
        </IconButton>
        <Typography variant="h6" noWrap>
          {title}
        </Typography>
        <Tooltip title={t('backHome')}>
          <IconButton className={classes.buttonBack} onClick={() => history.push('/')}>
            <HomeIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
