/* eslint-disable no-bitwise */
import handleFetchGeoJson from './handleFetchGeoJson';
import { getBeforeId } from './pathFuncs';

export default async function addGeojsonLayer(
  map,
  id,
  onClusterClick,
  onFeatureClick,
  onMouseEnter,
  onMouseLeave,
  extraMap,
  moveEndListenersRef,
  signal,
  decrementGeoJsonCount,
  incrementGeoJsonCount,
  dispatch,
  needToShow,
  onCluster,
  onAutoRefresh,
) {
  const baseId = `extraMap-Geojson-${id}`;

  map.addSource(id, {
    type: 'geojson',
    data: { type: 'FeatureCollection', features: [] },
  });

  map.addLayer({
    id: `${baseId}-clusters`,
    type: 'circle',
    source: id,
    filter: [
      'all',
      ['has', 'point_count'],
      ['==', '$type', 'Point'],
    ],
    paint: {
      'circle-color': '#000000',
      'circle-blur': 0.1,
      'circle-radius': [
        'step',
        ['get', 'point_count'],
        15,
        10,
        20,
        100,
        25,
        1000,
        30,
        10000,
        35,
      ],
    },
    layout: {
      visibility: 'none',
    },
  }, getBeforeId('extraLayers'));

  // Слой числового отображения кластеров
  map.addLayer({
    id: `${baseId}-cluster-count`,
    type: 'symbol',
    source: id,
    filter: [
      'all',
      ['has', 'point_count'],
      ['==', '$type', 'Point'],
    ],
    layout: {
      visibility: 'none',
      'text-field': '{point_count_abbreviated}',
      'text-font': ['Roboto Regular'],
      'text-size': [
        'step',
        ['get', 'point_count'],
        15,
        10,
        16,
        100,
        18,
        1000,
        20,
        10000,
        25,
      ],
    },
  }, getBeforeId('extraLayers'));

  map.addLayer(
    {
      id: `${baseId}-heatMap`,
      type: 'heatmap',
      source: id,
      maxzoom: 22,
      paint: {
        'heatmap-intensity': 2,
        'heatmap-color': [
          'interpolate',
          ['linear'],
          ['heatmap-density'],
          0,
          'rgba(0, 0, 255, 0)',
          0.1,
          '#0000FF', // blue
          0.3,
          '#00FFFF', // cyan
          0.5,
          '#00ff00', // lime
          0.7,
          '#ffff00', // yellow
          1,
          '#ff0000', // red
        ],
        'heatmap-radius': 20,
        'heatmap-opacity': 0.6,
      },
      layout: {
        visibility: 'none',
      },
    },
    getBeforeId('extraLayers'),
  );

  map.addLayer({
    id: `${baseId}-circle`,
    type: 'circle',
    source: id,
    filter: [
      'all',
      ['!has', 'point_count'],
      ['==', '$type', 'Point'],
    ],
    paint: {
      'circle-radius': ['coalesce', ['get', 'radius'], 3],
      'circle-color': '#000000',
      'circle-opacity': ['coalesce', ['get', 'opacity'], 1],
      'circle-stroke-width': ['coalesce', ['get', 'strokeWidth'], 1],
      'circle-stroke-color': ['coalesce', ['get', 'strokeColor'], '#000000'],
      'circle-stroke-opacity': ['coalesce', ['get', 'opacity'], 1],
    },
    layout: {
      visibility: 'none',
    },
  }, getBeforeId('extraLayers'));

  map.addLayer({
    id: `${baseId}-line`,
    source: id,
    type: 'line',
    filter: [
      'all',
      ['==', '$type', 'LineString'],
      ['!has', 'type'],
    ],
    paint: {
      'line-color': '#000000',
      'line-width': ['coalesce', ['get', 'width'], 2],
      'line-opacity': ['coalesce', ['get', 'opacity'], 1],
    },
    layout: {
      visibility: 'none',
    },
  }, getBeforeId('extraLayers'));

  map.addLayer({
    id: `${baseId}-line-dashed`,
    source: id,
    type: 'line',
    filter: [
      'all',
      ['==', '$type', 'LineString'],
      ['==', 'type', 'dashed'],
    ],
    paint: {
      'line-color': '#000000',
      'line-width': ['coalesce', ['get', 'width'], 2],
      'line-opacity': ['coalesce', ['get', 'opacity'], 1],
      'line-dasharray': [4, 2],
    },
    layout: {
      visibility: 'none',
    },
  }, getBeforeId('extraLayers'));

  map.addLayer({
    id: `${baseId}-polygon`,
    source: id,
    type: 'fill',
    filter: [
      'all',
      ['==', '$type', 'Polygon'],
      ['!has', 'altitude'],
      ['!has', 'height'],
    ],
    paint: {
      'fill-color': '#000000',
      'fill-opacity': ['coalesce', ['get', 'opacity'], 0.5],
    },
    layout: {
      visibility: 'none',
    },
  }, getBeforeId('extraLayers'));

  map.addLayer({
    id: `${baseId}-polygon3D`,
    source: id,
    type: 'fill-extrusion',
    filter: [
      'all',
      ['==', '$type', 'Polygon'],
      ['has', 'altitude'],
      ['has', 'height'],
    ],
    paint: {
      'fill-extrusion-color': '#000000',
      'fill-extrusion-height': ['get', 'height'],
      'fill-extrusion-base': ['get', 'altitude'],
      'fill-extrusion-opacity': 0.5,
    },
    layout: {
      visibility: 'none',
    },
  }, getBeforeId('extraLayers'));

  map.addLayer({
    id: `${baseId}-text`,
    type: 'symbol',
    source: id,
    filter: [
      'all',
      ['has', 'title'],
    ],
    layout: {
      'text-field': ['get', 'title'],
      'text-size': ['coalesce', ['get', 'titleSize'], 12],
      'text-allow-overlap': false,
      'text-anchor': 'bottom',
      'text-offset': [0, -2],
      'text-font': ['Roboto Medium'],
      visibility: 'none',
    },
    paint: {
      'text-color': ['coalesce', ['get', 'titleColor'], '#000000'],
      'text-opacity': ['coalesce', ['get', 'titleOpacity'], 1],
    },
  }, getBeforeId('extraLayers'));

  map.on('click', `${baseId}-clusters`, onClusterClick);
  map.on('click', `${baseId}-circle`, onFeatureClick);
  map.on('click', `${baseId}-line`, onFeatureClick);
  map.on('click', `${baseId}-line-dashed`, onFeatureClick);
  map.on('click', `${baseId}-polygon`, onFeatureClick);
  map.on('click', `${baseId}-polygon3D`, onFeatureClick);

  map.on('mouseenter', `${baseId}-circle`, onMouseEnter);
  map.on('mouseleave', `${baseId}-circle`, onMouseLeave);
  map.on('mouseenter', `${baseId}-clusters`, onMouseEnter);
  map.on('mouseleave', `${baseId}-clusters`, onMouseLeave);

  map.on('mouseenter', `${baseId}-line`, onMouseEnter);
  map.on('mouseleave', `${baseId}-line`, onMouseLeave);
  map.on('mouseenter', `${baseId}-line-dashed`, onMouseEnter);
  map.on('mouseleave', `${baseId}-line-dashed`, onMouseLeave);
  map.on('mouseenter', `${baseId}-polygon`, onMouseEnter);
  map.on('mouseleave', `${baseId}-polygon`, onMouseLeave);
  map.on('mouseenter', `${baseId}-polygon3D`, onMouseEnter);
  map.on('mouseleave', `${baseId}-polygon3D`, onMouseLeave);

  const url = extraMap.params.tiles[0];
  let autoRefresh = false;
  if ((onAutoRefresh ?? extraMap.params.autoRefresh) && extraMap.params.refreshInterval) {
    autoRefresh = extraMap.params.refreshInterval;
  }

  if (needToShow) {
    handleFetchGeoJson(map, url, autoRefresh, id, signal, decrementGeoJsonCount, incrementGeoJsonCount, dispatch);
  }
}
