import 'typeface-roboto';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from './store';
import ErrorHandler from './common/components/ErrorHandler';
import WarningHandler from './common/components/WarningHandler';

ReactDOM.render((
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
    <ErrorHandler />
    <WarningHandler />
  </Provider>
), document.getElementById('root'));

serviceWorker.register();
