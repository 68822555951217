import {
  memo, useCallback, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core';
import { map } from './Map';
import logout from '../common/utils/logout';
import {
  createLayers, createRadarLayers, getPositionExtraProperties, getPositionProperties, getStopProperties,
} from './funcs/positionSource';
import { errorsActions, positionsActions, devicesActions } from '../store';
import { useEffectAsync } from '../common/utils/reactHelper';
import { createRadarFeature } from './funcs/pathFuncs';
import { getExtraCoordinatesBSData, getExtraPositionProperties } from './funcs/propertiesExtra';
import { useTranslation } from '../common/components/LocalizationProvider';
import generateArrowPolygon from './funcs/generateArrowPolygon';
import validateItem from '../common/components/PositionData/validateItem';

const PositionsMap = ({
  positions, onClusters, nextPosition, stateSyncPositions, setMoveableDevice, setNewMarkerCoordinates, devices, closedDevices,
  setCountPosition, positionsSimilar, data, setNextPosition, text, noClick, setPositionsBS, onTower, moveableDevice,
  stateSyncDevices, stateSyncClosedDevices, user,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();
  const t = useTranslation();

  const groups = useSelector((state) => state.groups.items);
  const categories = useSelector((state) => state.devices.categories);
  const devicesParams = useSelector((state) => state.devices.itemsParams);
  const stateSyncItemsParams = useSelector((state) => state.devices.stateSyncItemsParams);
  const bscoderIsActive = useSelector((state) => state.bs.isActive);
  const replayItems = useSelector((state) => state.replay.items);
  const isTailOn = useSelector((state) => state.tail.tail);
  const trackingModeOnSelect = useSelector((state) => state.session?.user?.attributes?.trackingModeOnSelect);

  const [alarmFeatures, setAlaramFeatures] = useState({});

  const currentAnimation = useRef(0);
  const noClickRef = useRef(noClick);
  const ref = useRef(devices);
  const tower = useRef(onTower);

  const id = data?.name;
  const idRadar = `${data?.name}-radar`;
  const idAlarm = `${data?.name}-alarm`;
  const clustersId = `${id}-clusters`;

  const changeDevicePlace = async (e) => {
    if (devices[moveableDevice.deviceId].positionId !== 0) {
      await fetch('/api/positions/move', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          deviceId: moveableDevice.deviceId, latitude: e.lngLat.lat, longitude: e.lngLat.lng,
        }),
      });
    }
    const url = `/api/devices/${moveableDevice.deviceId}`;
    const response = await fetch(url);
    if (response.ok) {
      const deviceData = await response.json();
      deviceData.attributes.latitude = e.lngLat.lat;
      deviceData.attributes.longitude = e.lngLat.lng;

      const secondResponse = await fetch(url, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(validateItem(deviceData)),
      });
      if (secondResponse.ok) {
        dispatch(positionsActions.unselectPosition());
      }
    }
    setMoveableDevice(null);
  };

  const updateCoordinatesText = (e) => {
    setNewMarkerCoordinates(e.lngLat);
  };

  useEffect(() => {
    noClickRef.current = noClick;
  }, [noClick]);

  useEffect(() => {
    tower.current = onTower;
  }, [onTower]);

  useEffect(() => {
    ref.current = devices;
  }, [stateSyncDevices]);

  const getTextStatus = (device) => {
    if (data.type === 'current') {
      return devicesParams[device.id]?.iconText ?? categories[device.category]?.iconText ?? text;
    }
    return text;
  };

  const getProperties = (position) => {
    const device = ref.current[position.deviceId];
    if (device) {
      if (data.name === 'stop') {
        return getStopProperties(position, device, data, getTextStatus(device), groups);
      }
      return getPositionProperties(position, device, user, t, data, getTextStatus(device), positionsSimilar);
    }
    return console.error('Device not found');
  };

  useEffectAsync(async () => {
    if (nextPosition) {
      const properties = getProperties(nextPosition);
      try {
        const extraProperties = await getExtraPositionProperties(properties, data, groups, ref.current);
        dispatch(positionsActions.changeTrackingMode(!(Object.keys(replayItems).length) && trackingModeOnSelect));
        dispatch(devicesActions.select({ id: extraProperties.deviceId }));
        dispatch(positionsActions.selectPosition({ ...properties, ...extraProperties }));
        dispatch(positionsActions.needToShowDataPanel(true));
      } catch (e) {
        console.warn(e);
      }
    }
  }, [nextPosition]);

  const onMarkerClick = useCallback(async (event) => {
    if (!noClickRef.current) {
      setNextPosition(null);
      setPositionsBS([]);

      const feature = event.features[0];
      const coordinates = feature.geometry.coordinates.slice();

      while (Math.abs(event.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += event.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      if (setCountPosition) {
        setCountPosition(0);
      }

      try {
        if (feature.properties.id === undefined) {
          const position = positions[feature.properties.deviceId];
          const extraProperties = getPositionExtraProperties(position, devices[position.deviceId], data, groups);

          dispatch(positionsActions.changeTrackingMode(!(Object.keys(replayItems).length) && trackingModeOnSelect));
          dispatch(devicesActions.select({ id: extraProperties.deviceId }));
          dispatch(positionsActions.selectPosition({ ...feature.properties, ...extraProperties }));
          dispatch(positionsActions.needToShowDataPanel(true));
          return;
        }

        const extraProperties = await getExtraPositionProperties(feature.properties, data, groups, ref.current);
        dispatch(positionsActions.changeTrackingMode(!(Object.keys(replayItems).length) && trackingModeOnSelect));
        dispatch(devicesActions.select({ id: extraProperties.deviceId }));
        dispatch(positionsActions.selectPosition({ ...feature.properties, ...extraProperties }));
        dispatch(positionsActions.needToShowDataPanel(true));

        if (bscoderIsActive && tower.current && extraProperties.bs) {
          await getExtraCoordinatesBSData(
            extraProperties.lacCids,
            (text) => setPositionsBS(text),
            (error) => {
              console.warn(error);
              logout(history, dispatch);
            },
          );
        }
      } catch (e) {
        dispatch(errorsActions.push('Невозможно получить данные о позиции. Попробуйте позже'));
        console.warn(e);
      }
    }
  }, [history, positions]);

  const onClusterClick = (event) => {
    const features = map.queryRenderedFeatures(event.point, {
      layers: [clustersId],
    });
    const clusterId = features[0].properties.cluster_id;

    map.getSource(id).getClusterExpansionZoom(clusterId, (error, zoom) => {
      if (!error) {
        map.easeTo({
          center: features[0].geometry.coordinates,
          zoom,
        });
      }
    });
  };

  useEffect(
    () => createLayers(id, clustersId, data, onClusterClick, onMarkerClick, onClusters),
    [onClusters, text],
  );

  useEffect(
    () => createRadarLayers(idRadar, data),
    [],
  );

  useEffect(
    () => createRadarLayers(idAlarm, data),
    [],
  );

  const getArrayByType = (arr) => {
    if (arr.length) {
      if (data.name === 'startPoint') {
        return arr.slice(0, 1);
      }
      if (data.name === 'finishPoint') {
        return arr.slice(-1);
      }
    }
    return arr;
  };

  const getSectorColor = (categoryParams) => {
    switch (categoryParams.workMode) {
      case 'detection':
        return theme.palette.tracks.replay0;
      case 'suppression':
        return theme.palette.tracks.replay1;
      case 'idle':
      case 'unknown':
      default:
        return '#ababab';
    }
  };

  const getSectorOpacity = (categoryParams) => {
    switch (categoryParams.workMode) {
      case 'unknown':
        return 0;
      default:
        return 0.25;
    }
  };

  function getFullCoordinates(coordinates, url) {
    return new Promise((resolve, reject) => {
      if (coordinates.length === 2) {
        const img = new Image();
        img.onload = () => {
          const ratio = img.width / img.height;
          const reverseCoordinates = [coordinates[1], coordinates[0]];
          const [x, y] = reverseCoordinates;

          const topLeft = [x - (0.01 * ratio), y + 0.005];
          const topRight = [x + (0.01 * ratio), y + 0.005];
          const bottomLeft = [x - (0.01 * ratio), y - 0.005];
          const bottomRight = [x + (0.01 * ratio), y - 0.005];

          resolve([topLeft, topRight, bottomRight, bottomLeft]);
        };
        img.onerror = reject;
        img.src = url;
      } else {
        const reverseCoordinates = [];
        for (let i = 0; i < coordinates.length; i++) {
          reverseCoordinates.push([coordinates[i][1], coordinates[i][0]]);
        }
        resolve(reverseCoordinates);
      }
    });
  }

  const pushFeatures = (position, clearFeatures, radarFeatures, aFeatures, features3D) => {
    if (position.attributes?.categoryParams?.images) {
      const { images } = position.attributes.categoryParams;

      images.forEach((image) => {
        const url = `/api/media/${position.deviceId}/positions/${position.id}/${image.name}`;

        if ((map.getSource(`positionImage-${position.id}-${image.name}`)) === undefined) {
          map.addSource(`positionImage-${position.id}-${image.name}`, {
            type: 'image',
            url,
            coordinates: [
              [0.00001, 0.00001],
              [0.00002, 0.00002],
              [0.00003, 0.00003],
              [0.00004, 0.00004],
            ],
          });

          map.addLayer({
            id: `positionImage-${position.id}-${image.name}`,
            type: 'raster',
            source: `positionImage-${position.id}-${image.name}`,
          }, 'current');

          getFullCoordinates(image.coordinates, url)
            .then((coordinates) => {
              map.getSource(`positionImage-${position.id}-${image.name}`).setCoordinates(coordinates);
            });
        }
      });
    }

    clearFeatures.push({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [position.longitude, position.latitude],
      },
      properties: getProperties(position),
    });

    if (devices[position.deviceId].attributes.showIn3D && (Object.keys(replayItems).length > 0 || isTailOn) && (data.name === 'startPoint' || data.name === 'finishPoint' || data.name === 'current')) {
      const arrowPolygonGeoJSON = generateArrowPolygon([position.longitude, position.latitude], position.course, 0.05);
      features3D.push({
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [arrowPolygonGeoJSON],
        },
        properties: {
          color: devices[position.deviceId].attributes.color || '#074eb8',
          altitude: position.altitude + 18,
          minAltitude: position.altitude + 11,
        },
      });
    }

    if (data.name === 'current') {
      const device = devices[position.deviceId];
      if (device.status === 'online') {
        const { categoryParams } = position.attributes;
        const categoryParamDevice = device.attributes.categoryParams;
        switch (device.category) {
          case 'radar':
            switch (device.model) {
              default:
                switch (categoryParams?.connectionStatus) {
                  case 'connected':
                    if (categoryParamDevice?.radius && categoryParamDevice?.showRadarArea) {
                      radarFeatures.push(createRadarFeature(
                        position.longitude,
                        position.latitude,
                        position.altitude,
                        device.attributes.showIn3D,
                        categoryParamDevice.radius,
                        categoryParamDevice.azimuth,
                        categoryParamDevice.diagram,
                        categoryParamDevice.radarColor,
                        theme,
                      ));
                    }
                    break;
                  default:
                    break;
                }
                break;
            }
            break;
          case 'anti_uav':
            switch (device.model) {
              case 'strizh3':
                switch (categoryParams?.connectionStatus) {
                  case 'connected': {
                    const feature = createRadarFeature(
                      position.longitude,
                      position.latitude,
                      position.altitude,
                      device.attributes.showIn3D,
                      categoryParamDevice.radius0,
                      0,
                      360,
                      getSectorColor(categoryParams),
                      theme,
                      'sector',
                      getSectorOpacity(categoryParams),
                    );
                    radarFeatures.push(feature);
                    switch (categoryParams.workMode) {
                      case 'detection':
                      case 'suppression':
                        feature.properties.effect = 'flow';
                        if (aFeatures[position.deviceId]) {
                          aFeatures[position.deviceId].push(feature);
                        } else {
                          aFeatures[position.deviceId] = [feature];
                        }
                        break;
                      default:
                        break;
                    }
                    switch (categoryParams.sectorState0) {
                      case 'signals_detected':
                        feature.properties.effect = 'alarm';
                        if (aFeatures[position.deviceId]) {
                          aFeatures[position.deviceId].push(feature);
                        } else {
                          aFeatures[position.deviceId] = [feature];
                        }
                        break;
                      default:
                        break;
                    }
                    [...Array(6).keys()].forEach((num) => {
                      const feature = createRadarFeature(
                        position.longitude,
                        position.latitude,
                        position.altitude + 10,
                        device.attributes.showIn3D,
                        categoryParamDevice.radius1 + 10,
                        Number(categoryParamDevice.azimuth1 || 0) + 60 * num,
                        360 / 6,
                        getSectorColor(categoryParams),
                        theme,
                        'sector',
                        getSectorOpacity(categoryParams),
                      );
                      radarFeatures.push(feature);
                      switch (categoryParams[`sectorState${num + 1}`]) {
                        case 'signals_detected':
                          feature.properties.effect = 'alarm';
                          if (aFeatures[position.deviceId]) {
                            aFeatures[position.deviceId].push(feature);
                          } else {
                            aFeatures[position.deviceId] = [feature];
                          }
                          break;
                        default:
                          break;
                      }
                    });
                    break;
                  }
                  default:
                    break;
                }
                break;
              default:
                break;
            }
            break;
          default:
            break;
        }
      }
    }
  };

  const sourceSetData = () => {
    map.getSource(idAlarm).setData({
      type: 'FeatureCollection',
      features: Object.values(alarmFeatures).reduce((a, v) => ([...a, ...v]), []),
    });
  };

  const animate = () => {
    Object.entries(alarmFeatures).forEach(([key, features]) => {
      features.forEach((feature, index) => {
        switch (feature.properties.effect) {
          case 'flow':
            feature.properties.opacity = feature.properties.alarm ? feature.properties.opacity + 0.005 : feature.properties.opacity - 0.005;
            alarmFeatures[key][index] = feature;
            if (feature.properties.opacity >= 0.9 || feature.properties.opacity <= 0) {
              feature.properties.alarm = !feature.properties.alarm;
            }
            break;
          case 'alarm':
            feature.properties.timer += 0.05;
            if (feature.properties.timer >= 1) {
              // feature.properties.opacity = feature.properties.alarm ? 0.05 : 0.9;
              feature.properties.radarColor = feature.properties.alarm ? theme.palette.common.gray : theme.palette.common.yellow;
              feature.properties.alarm = !feature.properties.alarm;
              feature.properties.timer = 0;
            }
            alarmFeatures[key][index] = feature;
            break;
          default:
            break;
        }
      });
    });
    if (!map.getSource(idAlarm)) {
      cancelAnimationFrame(currentAnimation.current);
      return;
    }
    sourceSetData();
    currentAnimation.current = requestAnimationFrame(() => animate());
  };

  useEffect(() => {
    const clearFeatures = [];
    const radarFeatures = [];
    const features3D = [];
    const aFeatures = {};
    Object.keys(positions).forEach((it) => {
      if (devices.hasOwnProperty(it) && !(closedDevices && closedDevices[it])) {
        if (typeof positions[it] === 'object') {
          if (Array.isArray(positions[it])) {
            const arr = getArrayByType(positions[it]);
            if (arr.length) {
              // шаг для фильтрации большого числа точек
              let step = 1;
              if (arr.length > 5000 && !onClusters) {
                step = Math.round(arr.length / 5000);
              }
              arr.forEach((position, index) => {
                if (index % step === 0 && position) {
                  pushFeatures(position, clearFeatures, radarFeatures, aFeatures, features3D);
                }
              });
            }
          } else {
            pushFeatures(positions[it], clearFeatures, radarFeatures, aFeatures, features3D);
          }
        }
      }
    });

    if ((data.name === 'startPoint' || data.name === 'finishPoint' || data.name === 'current')) {
      map.getSource(`${id}-3D`).setData({
        type: 'FeatureCollection',
        features: features3D,
      });
    }
    map.getSource(id).setData({
      type: 'FeatureCollection',
      features: clearFeatures,
    });
    if (data.name === 'current') {
      map.getSource(idRadar).setData({
        type: 'FeatureCollection',
        features: radarFeatures,
      });
    }

    setAlaramFeatures(aFeatures);
  }, [stateSyncClosedDevices, categories, stateSyncItemsParams, stateSyncPositions ?? positions, onClusters, stateSyncDevices, text, map.getSource(id)]);

  useEffect(() => {
    if (data.name === 'current') {
      sourceSetData();
      cancelAnimationFrame(currentAnimation.current);
      if (Object.keys(alarmFeatures).length !== 0) {
        animate();
      }
    }
  }, [alarmFeatures]);

  useEffect(() => {
    if (moveableDevice) {
      map.on('click', changeDevicePlace);
      map.on('mousemove', updateCoordinatesText);
    }
    return () => {
      if (moveableDevice) {
        map.off('click', changeDevicePlace);
        map.off('mousemove', updateCoordinatesText);
      }
    };
  }, [moveableDevice]);

  return null;
};

export default memo(PositionsMap);

PositionsMap.propTypes = {
  positions: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  closedDevices: PropTypes.object,
  onClusters: PropTypes.number,
  nextPosition: PropTypes.object,
  setCountPosition: PropTypes.func,
  positionsSimilar: PropTypes.object,
  setNextPosition: PropTypes.func,
  text: PropTypes.number,
  noClick: PropTypes.bool,
  setPositionsBS: PropTypes.func,
  onTower: PropTypes.number,
  stateSyncPositions: PropTypes.number,
};
