import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControlLabel, Typography, makeStyles,
} from '@material-ui/core';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import SelectField from '../../../common/form/SelectField';
import SelectDeviceIcon from '../components/SelectDeviceIcon';

const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
}));

const AutoregistartionBlock = ({ item, setItem }) => {
  const t = useTranslation();
  const classes = useStyles();

  const onChangeIcon = (icon) => {
    setItem(
      { ...item, attributes: { ...item.attributes, registerUnknownDefaultIcon: icon } },
    );
  };

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="subtitle1">
          {t('autoregistrationTitle')}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        <FormControlLabel
          control={(
            <Checkbox
              checked={!!item.attributes.registerUnknown}
              onChange={(event) => setItem(
                { ...item, attributes: { ...item.attributes, registerUnknown: event.target.checked } },
              )}
            />
          )}
          label={t('registerUnknownTitle')}
        />
        <SelectField
          margin="normal"
          value={item.attributes.registerUnknownDefaultGroupId || 0}
          onChange={(event) => setItem(
            { ...item, attributes: { ...item.attributes, registerUnknownDefaultGroupId: event.target.value } },
          )}
          endpoint="/api/groups?all=true"
          label={t('groupParent')}
          variant="filled"
          disabled={!item.attributes.registerUnknown}
          required
          error={item.attributes.registerUnknown && !item.attributes.registerUnknownDefaultGroupId}
        />
        <SelectDeviceIcon
          currentIcon={item.attributes.registerUnknownDefaultIcon}
          onChange={onChangeIcon}
          disabled={!item.attributes.registerUnknown}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default AutoregistartionBlock;
