import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Accordion, AccordionDetails, AccordionSummary, TextField, Typography, makeStyles,
} from '@material-ui/core';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import { getAttr, setAttr } from '../../../common/utils/formatter';

const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
}));

const TelegramBlock = ({ item, setItem }) => {
  const t = useTranslation();
  const classes = useStyles();

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="subtitle1">
          {t('serverTelegram')}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        <TextField
          margin="normal"
          value={getAttr(item, 'notificator.telegram.key')}
          onChange={(event) => setAttr(setItem, 'notificator.telegram.key', event.target.value)}
          label={t('attributeTelegramKey')}
          variant="filled"
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default TelegramBlock;
