import React from 'react';
import PageLayout from '../../common/components/PageLayout';
import ReportsMenu from './components/ReportsMenu';

function ReportsLayout() {
  return (
    <PageLayout menu={<ReportsMenu />} breadcrumbs={['reportTitle']} />
  );
}

export default ReportsLayout;
