/* eslint-disable jsx-a11y/label-has-associated-control */
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IconButton, Tooltip } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { useTheme } from '@material-ui/core/styles';
import { bbox } from '@turf/turf';
import { map } from './Map';
import { geofenceToFeature, geometryToArea } from './mapUtil';
import { useTranslation } from '../common/components/LocalizationProvider';
import logout from '../common/utils/logout';
import mapboxTheme from '../common/theme/mapboxTheme';
import { errorsActions, warningsActions } from '../store';

const draw = new MapboxDraw({
  displayControlsDefault: false,
  controls: {
    polygon: true,
    trash: true,
  },
  userProperties: true,
  styles: [...mapboxTheme, {
    id: 'gl-draw-title',
    type: 'symbol',
    filter: ['all'],
    layout: {
      'text-field': '{user_name}',
      'text-font': ['Roboto Regular'],
      'text-size': 12,
    },
    paint: {
      'text-halo-color': 'white',
      'text-halo-width': 1,
    },
  },
  {
    id: 'gl-draw-polygon-fill-inactive',
    type: 'fill',
    filter: ['all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Polygon'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'fill-color': ['get', 'user_color'],
      'fill-outline-color': ['get', 'user_color'],
      'fill-opacity': 0.1,
    },
  },
  {
    id: 'gl-draw-polygon-stroke-inactive',
    type: 'line',
    filter: ['all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Polygon'],
      ['!=', 'mode', 'static'],
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': ['get', 'user_color'],
      'line-width': 2,
    },
  },
  ],
});

const GeofenceEditMap = ({ setSaveMode, refreshGeofences }) => {
  const geofences = useSelector((state) => Object.values(state.geofences.items));
  const stateSyncGeofences = useSelector((state) => state.geofences.stateSyncGeofences);
  const currentGeofences = useRef(geofences || []);
  const t = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();

  const handleGeoJSONUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        try {
          const geojson = JSON.parse(e.target.result);
          if (geojson && geojson.features) {
            if (geojson.features.length > 1) {
              dispatch(warningsActions.push(t('geofenceWarnLength')));
            }
            const feature = geojson.features[0];
            if (feature.geometry.type !== 'Polygon') {
              dispatch(errorsActions.push(t('geofenceErrNotPolygon')));
              return;
            }
            if (!feature.properties) {
              feature.properties = {};
            }
            if (!feature.properties.color) {
              feature.properties.color = theme.palette.clusters.blue;
            }
            event.target.value = '';
            const drawId = draw.add(feature);

            const newItem = {
              name: file.name.slice(0, -8),
              area: geometryToArea(feature.geometry),
              attributes: {
                color: feature.properties.color,
              },
            };

            draw.delete(drawId);

            const response = await fetch('/api/geofences', {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(newItem),
            });
            if (response.ok) {
              const item = await response.json();
              setSaveMode(item.id);
              refreshGeofences();
              if (feature.geometry && feature.geometry.coordinates) {
                const bounds = bbox(feature.geometry);

                if (bounds) {
                  map.fitBounds(bounds, {
                    maxZoom: 15,
                    padding: {
                      top: 250, bottom: 250, left: 25, right: 25,
                    },
                  });
                }
              } if (feature.geometry && feature.geometry.coordinates) {
                const bounds = bbox(feature.geometry);

                if (bounds) {
                  map.fitBounds(bounds, {
                    maxZoom: 15,
                    padding: {
                      top: 250, bottom: 250, left: 25, right: 25,
                    },
                  });
                }
              }
            } else if (response.status === 401) {
              logout(history, dispatch);
            } else {
              const err = await response.text();
              if (/Data too long for column 'area'*/.test(err)) {
                dispatch(errorsActions.push(t('geofenceErrTooLong')));
                return;
              }
            }
          } else {
            console.error('Invalid GeoJSON format');
          }
        } catch (error) {
          console.error('Failed to parse GeoJSON:', error);
        }
      };
      reader.readAsText(file);
    }
  };

  const create = async (event) => {
    const feature = event.features[0];
    const newItem = {
      name: t('defaultGeofenceName'),
      area: geometryToArea(feature.geometry),
      color: feature.properties.color,
    };
    draw.delete(feature.id);
    const response = await fetch('/api/geofences', { // вынести в отедельную ф-цию
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(newItem),
    });
    if (response.ok) {
      const item = await response.json();
      setSaveMode(item.id);
      refreshGeofences();
    } else if (response.status === 401) {
      logout(history, dispatch);
    }
  };

  const update = async (event) => {
    const feature = event.features[0];
    const item = currentGeofences.current.find((i) => i.id === feature?.id);
    if (item) {
      const updatedItem = {
        ...item,
        area: geometryToArea(feature.geometry),
      };
      const response = await fetch(`/api/geofences/${feature.id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(updatedItem),
      });
      if (response.ok) {
        refreshGeofences();
      } else if (response.status === 401) {
        logout(history, dispatch);
      }
    }
  };

  const deleteG = async (event) => {
    const feature = event.features[0];
    const response = await fetch(`/api/geofences/${feature.id}`, { method: 'DELETE' });
    if (response.ok) {
      refreshGeofences();
    } else if (response.status === 401) {
      logout(history, dispatch);
    }
  };

  useEffect(() => currentGeofences.current = geofences, [stateSyncGeofences]);

  useEffect(() => {
    refreshGeofences();

    map.addControl(draw, 'top-right');

    document.querySelector('.mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_polygon')
      .setAttribute('title', t('createGeofenceTitle'));
    document.querySelector('.mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_trash')
      .setAttribute('title', t('deleteGeofenceTitle'));

    map.on('draw.create', create);

    map.on('draw.delete', deleteG);

    map.on('draw.update', update);

    return () => {
      map.off('draw.create', create);

      map.off('draw.delete', deleteG);

      map.off('draw.update', update);
      map.removeControl(draw);
    };
  }, []);

  useEffect(() => {
    draw.deleteAll();
    geofences.forEach((geofence) => {
      const feature = geofenceToFeature(geofence);
      draw.add(feature);
    });
  }, [stateSyncGeofences]);

  return (
    <div
      style={{
        position: 'absolute',
        top: 414,
        right: 10,
        borderRadius: 10,
        boxSizing: 'border-box',
        backgroundColor: '#ffffff',
      }}
    >
      <Tooltip title={t('geofenceUploadFile')}>
        <IconButton
          variant="contained"
          color="primary"
          component="label"
          style={{
            width: 38,
            height: 38,
            borderRadius: 10,
          }}
        >
          <input
            id="uploadFile"
            type="file"
            accept=".geojson"
            onChange={handleGeoJSONUpload}
            hidden
          />
          <CloudUploadIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default GeofenceEditMap;
