import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControlLabel, TextField, Typography, makeStyles,
} from '@material-ui/core';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import { isValid, getAttr, setAttr } from '../../../common/utils/formatter';

const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
}));

const SmtpBlock = ({ item, setItem }) => {
  const t = useTranslation();
  const classes = useStyles();

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="subtitle1">
          {t('serverSmtp')}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        <TextField
          margin="normal"
          value={getAttr(item, 'mail.smtp.host')}
          onChange={(event) => setAttr(setItem, 'mail.smtp.host', event.target.value)}
          label={t('attributeMailSmtpHost')}
          variant="filled"
        />
        <TextField
          margin="normal"
          value={getAttr(item, 'mail.smtp.port')}
          onChange={(event) => setAttr(setItem, 'mail.smtp.port', event.target.value)}
          label={t('attributeMailSmtpPort')}
          variant="filled"
          error={!isValid('mail.smtp.port', getAttr(item, 'mail.smtp.port'))}
        />
        <FormControlLabel
          control={(
            <Checkbox
              checked={getAttr(item, 'mail.smtp.ssl.enable')}
              onChange={(event) => setAttr(setItem, 'mail.smtp.ssl.enable', event.target.checked)}
            />
          )}
          label={t('attributeMailSmtpSslEnable')}
        />
        <TextField
          margin="normal"
          value={getAttr(item, 'mail.smtp.from')}
          onChange={(event) => setAttr(setItem, 'mail.smtp.from', event.target.value)}
          label={t('attributeMailSmtpFrom')}
          variant="filled"
        />
        <FormControlLabel
          control={(
            <Checkbox
              checked={getAttr(item, 'mail.smtp.auth')}
              onChange={(event) => setAttr(setItem, 'mail.smtp.auth', event.target.checked)}
            />
          )}
          label={t('attributeMailSmtpAuth')}
        />
        <TextField
          margin="normal"
          value={getAttr(item, 'mail.smtp.username')}
          onChange={(event) => setAttr(setItem, 'mail.smtp.username', event.target.value)}
          label={t('attributeMailSmtpUsername')}
          variant="filled"
        />
        <TextField
          margin="normal"
          value={getAttr(item, 'mail.smtp.password')}
          onChange={(event) => setAttr(setItem, 'mail.smtp.password', event.target.value)}
          label={t('attributeMailSmtpPassword')}
          variant="filled"
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default SmtpBlock;
