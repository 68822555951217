import React, { useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import HelpIcon from '@material-ui/icons/HelpOutline';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import {
  Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox, Dialog, DialogActions, DialogTitle, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Modal, Select, TextField, Tooltip, Typography, makeStyles,
} from '@material-ui/core';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import CustomColorPicker from '../../../common/components/CustomColorPicker';
import theme from '../../../common/theme';
import ExtraMapsHelp from './ExtraMapsHelp';

const useStyles = makeStyles((theme) => ({
  details: { flexDirection: 'column' },
  addTile: { width: '100%', backgroundColor: '#9cd26d' },
  modalContent: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    borderRadius: 8,
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  helpButton: {
    position: 'absolute',
    right: theme.spacing(5),
    top: theme.spacing(1),
  },
  iconButtons: {
    display: 'flex',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    '& > *': {
      flexBasis: '33%',
    },
    marginTop: theme.spacing(2),
  },
}));

const ExtraMapsBlock = ({ item, setItem }) => {
  const t = useTranslation();
  const classes = useStyles();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [currentMapIndex, setCurrentMapIndex] = useState(null);
  const [tempMap, setTempMap] = useState(null);
  const [openHelp, setOpenHelp] = useState(false);

  const updateExtraMaps = (extraMaps) => setItem({ ...item, attributes: { ...item.attributes, extraMaps } });

  const handleExtraMapsAdd = () => {
    const extraMaps = [...(JSON.parse(JSON.stringify(item.attributes.extraMaps)) ?? [])];
    const extraMapsIds = extraMaps.map((map) => map.id);
    let newMapId = 1;
    while (extraMapsIds.includes(newMapId)) newMapId++;
    extraMaps.push({
      id: newMapId,
      name: `${t('serverLayer')} ${newMapId}`,
      params: { tiles: [''], tileSize: 256 },
    });
    updateExtraMaps(extraMaps);
  };

  const handleDeleteMap = () => {
    const extraMaps = JSON.parse(JSON.stringify(item.attributes.extraMaps));
    extraMaps.splice(currentMapIndex, 1);
    updateExtraMaps(extraMaps);
    setOpenDeleteModal(false);
  };

  const handleOpenEditModal = (index) => {
    setCurrentMapIndex(index);
    setTempMap(JSON.parse(JSON.stringify(item.attributes.extraMaps[index])));
    setOpenEditModal(true);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setCurrentMapIndex(null);
    setTempMap(null);
  };

  const handleFieldChange = (field, value) => {
    setTempMap((prevMap) => ({
      ...prevMap,
      [field]: value,
    }));
  };

  const handleParamChange = (field, value) => {
    setTempMap((prevMap) => ({
      ...prevMap,
      params: {
        ...prevMap.params,
        [field]: value,
      },
    }));
  };

  const handleSaveChanges = () => {
    const extraMaps = JSON.parse(JSON.stringify(item.attributes.extraMaps));
    extraMaps[currentMapIndex] = tempMap;
    updateExtraMaps(extraMaps);
    handleCloseEditModal();
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const extraMaps = [...item.attributes.extraMaps];
    const [movedItem] = extraMaps.splice(result.source.index, 1);
    extraMaps.splice(result.destination.index, 0, movedItem);
    updateExtraMaps(extraMaps);
  };

  return (
    <>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="subtitle1">{t('serverExtraLayers')}</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="extraMaps">
              {(provided) => (
                <Box {...provided.droppableProps} ref={provided.innerRef} style={{ width: '100%' }}>
                  {(item.attributes.extraMaps ?? []).map((map, index) => (
                    <Draggable key={map.id} draggableId={`${map.id}`} index={index}>
                      {(provided) => (
                        <Box
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            ...provided.draggableProps.style,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            border: '1px solid #ccc',
                            borderRadius: '5px',
                            padding: 8,
                            marginBottom: 8,
                            backgroundColor: '#ffffff',
                          }}
                        >
                          <DragIndicatorIcon />
                          <Typography>{map.name}</Typography>
                          <Box className={classes.iconButtons}>
                            <Tooltip title={t('sharedEdit')}>
                              <IconButton onClick={() => handleOpenEditModal(index)}>
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title={t('sharedRemove')}>
                              <IconButton onClick={() => { setCurrentMapIndex(index); setOpenDeleteModal(true); }}>
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Box>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </DragDropContext>
          <Button variant="outlined" className={classes.addTile} onClick={handleExtraMapsAdd}>
            {t('serverTileAdd')}
          </Button>
        </AccordionDetails>
      </Accordion>

      {tempMap && (
        <Modal open={openEditModal} onClose={handleCloseEditModal}>
          <Box className={classes.modalContent}>
            <IconButton className={classes.closeButton} onClick={handleCloseEditModal}>
              <CloseIcon />
            </IconButton>
            <Tooltip title={t('sharedHelp')}>
              <IconButton className={classes.helpButton} onClick={() => { setOpenHelp(true); }}>
                <HelpIcon />
              </IconButton>
            </Tooltip>

            <Typography variant="h6">{t('sharedEdit')}</Typography>

            <TextField
              margin="normal"
              label={t('serverLayerName')}
              variant="filled"
              fullWidth
              value={tempMap.name}
              onChange={(e) => handleFieldChange('name', e.target.value)}
            />

            <FormControl variant="filled" fullWidth margin="normal">
              <InputLabel>{t('serverTypeName')}</InputLabel>
              <Select
                value={tempMap.params.type || 'XYZ'}
                onChange={(e) => handleParamChange('type', e.target.value)}
              >
                <MenuItem value="XYZ">{t('serverTypeXYZ')}</MenuItem>
                <MenuItem value="TMS">{t('serverTypeTMS')}</MenuItem>
                <MenuItem value="GeoJson">{t('serverTypeGeoJson')}</MenuItem>
              </Select>
            </FormControl>

            <TextField
              margin="normal"
              label="URL"
              variant="filled"
              fullWidth
              value={tempMap.params.tiles[0]}
              onChange={(e) => handleParamChange('tiles', [e.target.value])}
            />

            {tempMap.params.type === 'GeoJson' && (
              <>
                <CustomColorPicker
                  color={tempMap.params.color || theme.palette.clusters.red}
                  setColor={(e) => handleParamChange('color', e.toLowerCase())}
                  presetColors={theme.palette.clusters}
                  label={t('extraMapsColor')}
                />

                <FormControlLabel
                  control={(
                    <Checkbox
                      disabled={tempMap.params.onCluster ?? true}
                      checked={tempMap.params.heatMap}
                      onChange={(e) => handleParamChange('heatMap', e.target.checked)}
                    />
                  )}
                  label={t('panelHeatmap')}
                />

                <FormControlLabel
                  control={(
                    <Checkbox
                      disabled={tempMap.params.heatMap}
                      checked={tempMap.params.onCluster ?? true}
                      onChange={(e) => handleParamChange('onCluster', e.target.checked)}
                    />
                  )}
                  label={t('panelClustersPositions')}
                />

                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={tempMap.params.autoRefresh ?? false}
                      onChange={(e) => handleParamChange('autoRefresh', e.target.checked)}
                    />
                  )}
                  label={t('extraMapsAutoUpdate')}
                />
                {tempMap.params.autoRefresh && (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                      margin="normal"
                      label={t('extraMapsRefreshInterval')}
                      variant="filled"
                      type="number"
                      fullWidth
                      inputProps={{ min: 1 }}
                      value={tempMap.params.refreshInterval}
                      onChange={(e) => handleParamChange('refreshInterval', Math.max(1, parseInt(e.target.value, 10)))}
                    />
                    <Typography style={{ marginLeft: 8 }}>{t('globalTimeMinuteFew')}</Typography>
                  </div>
                )}
              </>
            )}

            <Button fullWidth variant="contained" color="primary" onClick={handleSaveChanges} style={{ marginTop: 20 }}>
              {t('sharedApply')}
            </Button>
          </Box>
        </Modal>
      )}

      <Dialog open={openDeleteModal} onClose={() => setOpenDeleteModal(false)}>
        <DialogTitle style={{ paddingBottom: '0' }}>{t('sharedRemoveConfirm')}</DialogTitle>
        <DialogActions className={classes.buttons}>
          <Button onClick={() => setOpenDeleteModal(false)} color="primary" variant="outlined">
            {t('sharedNo')}
          </Button>
          <Button onClick={handleDeleteMap} color="primary" variant="contained">
            {t('sharedYes')}
          </Button>
        </DialogActions>
      </Dialog>

      <ExtraMapsHelp openHelp={openHelp} setOpenHelp={setOpenHelp} />
    </>
  );
};

export default ExtraMapsBlock;
