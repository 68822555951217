import moment from 'moment';
import { prefixString } from './stringUtils';
import deviceCategories from '../static/deviceCategories';
import lifetimes from '../static/lifetimes';

export const formatBoolean = (value, t) => (value ? t('sharedYes') : t('sharedNo'));

export const formatSmsType = (value, t) => {
  switch (value) {
    case 'inbox':
      return t('smsInbox');
    case 'outbox':
      return t('smsOutbox');
    default:
      return '';
  }
};

export const formatNumber = (value, precision = 1) => Number(value.toFixed(precision));

export const formatDate = (value, format = 'DD.MM.YYYY HH:mm:ss', light) => {
  if (light) {
    return moment(value).format(moment(value).year() < moment().year() ? format : 'DD.MM, HH:mm');
  }
  return moment(value).format(format);
};

export const getTitle = (deviceName, date, arg = 'current') => {
  const format = moment(date).year() < moment().year() ? 'DD.MM.YYYY, HH:mm' : 'DD.MM, HH:mm';
  if (arg === 'current') {
    return `${deviceName}\n${moment(date).format(format)}`;
  }
  if (arg === 'marker') {
    return '';
  }
  if (arg === 'geoJson') {
    return deviceName || '';
  }
  return moment(date).format(format);
};

export const toTitleCase = (value) => {
  try {
    const newValue = value[0].toUpperCase() + value.slice(1);
    return newValue;
  } catch (error) {
    console.log(error);
  }
  return value;
};

export const formatTimeoutToMs = (duration, title) => {
  switch (title) {
    case 'days':
    case 'day':
      return duration * 24 * 60 * 60 * 1000;
    case 'hours':
    case 'hour':
      return duration * 60 * 60 * 1000;
    case 'minutes':
    case 'minute':
      return duration * 60 * 1000;
    default:
      return duration * 1000;
  }
};

export const formatMsToTime = (duration, t) => {
  const days = duration / (1000 * 60 * 60 * 24);
  if (days >= 1) {
    if (days > 999) {
      return '∞';
    }
    return `${Math.round(days)}${t('sharedDayAbbreviation')}`;
  }
  const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
  const minutes = Math.round((duration / (1000 * 60)) % 60);
  if (hours <= 0) {
    return `${minutes < 0 ? 0 : minutes}${t('sharedMinuteAbbreviation')}`;
  }
  return `${hours}${t('sharedHourAbbreviation')} ${minutes < 0 ? 0 : minutes}${t('sharedMinuteAbbreviation')}`;
};

export const formatMsToDaysText = (duration, t) => {
  const days = Math.round(duration / (1000 * 60 * 60 * 24));
  const ten = days % 100;
  if (![11, 12, 13, 14].includes(Math.abs(ten))) {
    const r = Math.abs(ten % 10);
    if (r === 1) {
      return [days, `${days} ${t('sharedOneDayAbbreviation')}`];
    }
    if ([2, 3, 4].includes(r)) {
      return [days, `${days} ${t('sharedFewDaysAbbreviation')}`];
    }
  }
  return [days, `${days} ${t('sharedManyDaysAbbreviation')}`];
};

export const formatSpeed = (value, unit, t) => {
  switch (unit) {
    case 'kmh':
      return `${(value * 1.852).toFixed(2)} ${t('sharedKmh')}`;
    case 'mps':
    case 'ms':
      return `${(value * 0.514444).toFixed(2)} ${t('sharedMps')}`;
    case 'mph':
      return `${(value * 1.15078).toFixed(2)} ${t('sharedMph')}`;
    case 'kn':
    default:
      return `${(value * 1).toFixed(2)} ${t('sharedKn')}`;
  }
};

export const formatCoordinate = (key, value, unit) => {
  let hemisphere;
  let degrees;
  let minutes;
  let seconds;

  if (key === 'latitude') {
    hemisphere = value >= 0 ? 'N' : 'S';
  } else {
    hemisphere = value >= 0 ? 'E' : 'W';
  }

  switch (unit) {
    case 'ddm':
      value = Math.abs(value);
      degrees = Math.floor(value);
      minutes = (value - degrees) * 60;
      return `${degrees}°${minutes.toFixed(5)}'${hemisphere}`;
    case 'dms':
      value = Math.abs(value);
      degrees = Math.floor(value);
      minutes = Math.floor((value - degrees) * 60);
      seconds = Math.round((value - degrees - minutes / 60) * 3600);
      return `${degrees}°${minutes}'${seconds}"${hemisphere}`;
    default:
      return `${Number(value).toFixed(5)}`;
  }
};

export const deformatCoordinate = (value, unit) => {
  switch (unit) {
    case 'dms':
    case 'ddm': {
      let sign = 1;
      if (value.endsWith('S') || value.endsWith('W')) {
        sign = -1;
      }
      const parts = value.split('°');
      return (Number(parts[1].split("'")[0] / 60) + Number(parts[0])).toFixed(5) * sign;
    }
    default:
      return `${Number(value).toFixed(5)}`;
  }
};

export const isValidCoordinate = (key, value, unit) => {
  switch (unit) {
    case 'ddm':
      if (key === 'latitude') {
        return String(value).match(new RegExp(String.raw`^-?(([0-9])|([1-8][0-9])|(90))°([0-9]*\.?[0-9]*)'(N|S)?$`, 'g'));
      }
      return String(value).match(new RegExp(String.raw`^-?(([0-9])|([1-9][0-9])|(1[1-7][0-9])|180)°([0-9]*\.?[0-9]*)'(E|W)?$`, 'g'));
    case 'dms':
      if (key === 'latitude') {
        return String(value).match(new RegExp(String.raw`^-?(([0-9])|([1-8][0-9])|(90))°([0-9]*)'([0-9]*\.?[0-9]*)"(N|S)?$`, 'g'));
      }
      return String(value).match(new RegExp(String.raw`^-?(([0-9])|([1-9][0-9])|(1[1-7][0-9])|180)°([0-9]*)'([0-9]*\.?[0-9]*)"(E|W)?$`, 'g'));
    default:
      return !Number.isNaN(Number(value));
  }
};

export const getExampleCoordinate = (key, unit) => {
  switch (unit) {
    case 'ddm':
      if (key === 'latitude') {
        return "85°14'N или 85°14'S";
      }
      return "110°14'E или 110°14'W";
    case 'dms':
      if (key === 'latitude') {
        return '85°14\'24.073"N или 85°14\'24.073"S';
      }
      return '110°14\'24.073"E или 110°14\'24.073"W';
    default:
      if (key === 'latitude') {
        return '85.53455';
      }
      return '110.43432';
  }
};

export const formatPosition = (value, key, t, unit, coordinateFormat) => {
  if (value != null && typeof value === 'object') {
    value = value[key];
  }
  switch (key) {
    case 'time':
    case 'fixTime':
    case 'deviceTime':
    case 'serverTime':
    case 'startTime':
    case 'endTime':
      return moment(value).format('DD.MM.YYYY HH:mm:ss');
    case 'eventTime':
      return moment(value).format('LLL');
    case 'latitude':
    case 'longitude':
      return formatCoordinate(key, Number(value).toFixed(5), coordinateFormat);
    case 'coordinates': {
      const coordinates = value.split(', ');
      if (coordinates.length === 2) {
        return `${formatCoordinate('latitude', Number(coordinates[0]).toFixed(5), coordinateFormat)}, ${formatCoordinate('longitude', Number(coordinates[1]).toFixed(5), coordinateFormat)}`;
      }
      return '';
    }
    case 'speed':
      return formatSpeed(value, unit, t);
    case 'course':
      if (Number(value) === -1) {
        return '';
      }
      return `${Number(value).toFixed(0)}°`;
    case 'azimuth':
    case 'diagram':
      return `${Number(value).toFixed(0)}°`;
    case 'accuracy':
      return `${Number(value).toFixed(1)} ${t('meters')}`;
    case 'radius':
      return `${value} ${t('meters')}`;
    case 'deviceCategory':
      return t(`deviceCategory${toTitleCase(value)}`);
    case 'status':
      if (value === 'online') {
        return t('deviceStatusOnline');
      } if (value === 'offline') {
        return t('deviceStatusOffline');
      } if (value === 'unknown') {
        return t('deviceStatusUnknown');
      }
      return value;
    case 'batteryLevel':
      return value && `${Math.floor(value)}%`;
    case 'duration':
      return formatMsToTime(value, t);
    case 'workMode':
    case 'controlMode':
    case 'connectionStatus':
      return t(`${key}${toTitleCase(value)}`);
    case 'sectorState0':
    case 'sectorState1':
    case 'sectorState2':
    case 'sectorState3':
    case 'sectorState4':
    case 'sectorState5':
    case 'sectorState6':
      return t(`sectorState${toTitleCase(value)}`);
    case 'sourceForAutoMode':
      return t(`sourceForAutoMode${toTitleCase(value)}`);
    case 'category':
      return t(`deviceCategories${toTitleCase(value)}`);
    default:
      if (typeof value === 'number') {
        return formatNumber(value);
      } if (typeof value === 'boolean') {
        return formatBoolean(value, t);
      }
      return value;
  }
};

export const formatPeriod = (similarPositions) => `${formatPosition(similarPositions[0].serverTime, 'time')}
         - ${formatPosition(similarPositions[similarPositions.length - 1].serverTime, 'time')}`;

export const formatDistance = (value, unit, t) => {
  switch (unit) {
    case 'mi':
      return `${(value * 0.000621371).toFixed(2)} ${t('sharedMi')}`;
    case 'nmi':
      return `${(value * 0.000539957).toFixed(2)} ${t('sharedNmi')}`;
    case 'km':
    default:
      return `${(value * 0.001).toFixed(2)} ${t('sharedKm')}`;
  }
};

export const formatVolume = (value, unit, t) => {
  switch (unit) {
    case 'impGal':
      return `${(value / 4.546).toFixed(2)} ${t('sharedGallonAbbreviation')}`;
    case 'usGal':
      return `${(value / 3.785).toFixed(2)} ${t('sharedGallonAbbreviation')}`;
    case 'ltr':
    default:
      return `${(value / 1).toFixed(2)} ${t('sharedLiterAbbreviation')}`;
  }
};

export const formatHours = (value) => moment.duration(value).humanize();

export const getStatusColor = (status) => {
  switch (status) {
    case 'online':
      return 'green';
    case 'offline':
      return 'red';
    case 'unknown':
    default:
      return 'gray';
  }
};

export const getBatteryStatus = (batteryLevel) => {
  if (batteryLevel >= 70) {
    return 'green';
  }
  if (batteryLevel > 30) {
    return 'orange';
  }
  return 'red';
};

export const getSortedArray = (a, b, reverse) => {
  if (reverse) {
    if (a > b) {
      return -1;
    }
    if (a < b) {
      return 1;
    }
    return 0;
  }
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
};

export const formatPhone = (value, item, setItem) => {
  if (value.length === 1) {
    if (item.phone === '+7' && value === '+') {
      setItem({ ...item, phone: '' });
      return;
    }
    if (value === '7' || value === '8' || value === '+') {
      value = '+7';
    } else if (value.match(/[0-9]/)) {
      value = `+7${value}`;
    } else {
      value = '';
    }
    setItem({ ...item, phone: value });
  } else if ((value && value.length <= 12 && value[value.length - 1].match(/[0-9+]/)) || value.length === 0) {
    setItem({ ...item, phone: value });
  }
};

export const formatUrl = (value) => (value ? value.replace(/\/$/, '') : '');

export const checkUrl = (value) => {
  if (value) {
    const isValid = value.search(/https*:\/\/[0-9a-zA-Zа-яА-Я.\-_:/]+/);
    return isValid !== -1;
  }
  return true;
};

export const getPeriodName = (val, t) => {
  const periodArray = val.split('_');
  return `${periodArray[0]} ${t(`shared${periodArray[1].toUpperCase()}`)}`;
};

export const definePlural = (sources, number) => {
  let index;

  if (number % 10 === 1 && number % 100 !== 11) {
    index = 0; // many
  } else if (number % 10 >= 2 && number % 10 <= 4 && (number % 100 < 10 || number % 100 >= 20)) {
    index = 1; // few
  } else {
    index = 2; // one
  }

  return sources[index] || '';
};

/**
 * Определяет и возвращает имя свойства во множ. или един. числе для получения перевода из конфига (ru.json, en.json).
 * @param {*} time имя времени, например minute для подстановки поиска в конфиге (globalTimeMinute).
 * @param {*} number число, по которому определять какой в каком числе вернуть имя свойства (globalTimeMinute или globalTimeMinuteFew и т.д.).
 * @returns Возвращает имя свойства из конфига, доступные вариации: GlobalTime_, GlobalTime_Many, GlobalTime_Few, где _ имя времени.
 */
export const getGlobalTimePluralName = (time, number) => {
  try {
    const transformedName = toTitleCase(time);

    return definePlural(
      [`globalTime${transformedName}`, `globalTime${transformedName}Many`, `globalTime${transformedName}Few`],
      number,
    );
  } catch (error) {
    console.warn('Не удалось получить имя глобального имени во множественном числе.', error);
    return '';
  }
};

export const formatAlarm = (value, t) => (value ? t(prefixString('alarm', value)) : '');

export const formatNotificationTitle = (t, notification, includeId) => {
  let title = notification.description || t(prefixString('event', notification.type));
  if (notification.type === 'alarm') {
    const alarmString = notification.attributes.alarms;
    if (alarmString) {
      const alarms = alarmString.split(',');
      if (alarms.length > 1) {
        title += ` (${alarms.length})`;
      } else {
        title += ` ${formatAlarm(alarms[0], t)}`;
      }
    }
  }
  if (includeId) {
    title += ` [${notification.id}]`;
  }
  return title;
};

export const getCategory = (device, defaultCategory = 'device') => {
  const category = device?.category;
  return deviceCategories[category] ? category : defaultCategory;
};

export const getModel = (device, defaultCategory = 'default') => device?.model || defaultCategory;

export const getExtraMapId = (id) => `extraMap-${id}`;

export const getCategoryParam = (item, field) => {
  const { categoryParams } = item.attributes;
  return categoryParams ? categoryParams[field] : '';
};

export const getFrom = (period) => {
  /**
   * Получает значение состояние значения From для трека
   */
  const periodArray = period.split('_');
  const selectedFrom = moment().subtract(Number(periodArray[0]), periodArray[1]);
  return selectedFrom;
};

export const getDeviceIds = (devices) => new Set(Object.keys(devices));

const filterByTime = (lastUpdate, timeout) => moment(lastUpdate) > moment().subtract(timeout.value, timeout.time);

export const filterCurrent = (device, server) => {
  if (device) {
    if (device.temporary) {
      const temporaryLifetime = server?.attributes.temporaryLifetime ?? lifetimes.temporary;
      if (temporaryLifetime.value === 0) {
        return true;
      }
      return filterByTime(device.lastUpdate, temporaryLifetime);
    }
    const deviceCategory = getCategory(device);
    const categoryLifetime = server?.attributes.categoryLifetime ?? {};
    const timeout = categoryLifetime[deviceCategory] ?? lifetimes.categories[deviceCategory];
    if (timeout.value === 0) {
      return true;
    }
    return filterByTime(device.lastUpdate, timeout);
  }
  return false;
};

export const collectReport = async (response, result, setProgress, matchCase = /,{"id"/) => {
  const reader = response.body.getReader();
  let frog = '';
  let count = 0;
  while (true) {
    // eslint-disable-next-line no-await-in-loop
    const { value, done } = await reader.read();
    if (done) {
      break;
    }
    const decoder = new TextDecoder();
    const chulk = decoder.decode(value);
    if (count === 0) {
      frog = chulk;
      count += 1;
      continue;
    }
    const match = matchCase.exec(chulk);
    if (match) {
      frog += chulk.slice(0, match.index);
      frog += ']';
      result.push(...JSON.parse(frog));
      frog = '[';
      frog += chulk.slice(match.index + 1);
    } else {
      frog += chulk;
    }
    count += 1;
    if (count % 50 === 0 && setProgress) {
      setProgress(result.length);
    }
  }
  result.push(...JSON.parse(frog));
};

export const abortRequest = async (requestName) => {
  await fetch('/api/abort', {
    method: 'DELETE',
    body: JSON.stringify({ requestName }),
    headers: { 'Content-Type': 'application/json' },
  });
};

export const getAttr = (item, name) => item.attributes[name];

export const setAttr = (setItem, name, val) => setItem((item) => ({ ...item, attributes: { ...item.attributes, [name]: val } }));

const isValidatedInt = (value) => {
  const numValue = Number(value);
  if (Number.isInteger(numValue)) {
    return true;
  }
  return false;
};

const isValidatedNum = (value) => {
  const numValue = Number(value);
  if (Number.isNaN(numValue)) {
    return false;
  }
  return true;
};

const isValidatedLongitude = (value) => {
  const numValue = Number(value);
  if (isValidatedNum(numValue) && numValue >= -180 && numValue <= 180) {
    return true;
  }
  return false;
};

const isValidatedLatitude = (value) => {
  const numValue = Number(value);
  if (isValidatedNum(numValue) && numValue >= -90 && numValue <= 90) {
    return true;
  }
  return false;
};

const isValidatedZoom = (value) => {
  const numValue = Number(value);
  if (isValidatedInt(numValue) && numValue >= 0 && numValue <= 18) {
    return true;
  }
  return false;
};

export const isValid = (name, value) => {
  switch (name) {
    case 'status.timeout':
      return isValidatedNum(value ?? 0);
    case 'longitude':
      return isValidatedLongitude(value);
    case 'latitude':
      return isValidatedLatitude(value);
    case 'zoom':
      return isValidatedZoom(value);
    case 'mail.smtp.port':
      if (!value || (value && !Number.isNaN(value) && Number(value) >= 0 && Number(value) <= 65535)) {
        return true;
      }
      return false;
    default:
      return true;
  }
};
