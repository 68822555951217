import React, { useState } from 'react';
import 'video.js/dist/video-js.css';
import { makeStyles } from '@material-ui/core';
import { Fullscreen, FullscreenExit } from '@material-ui/icons';
import VideoJsMPEGController from './VideoJsMPEGController';

const useStyles = makeStyles(() => ({
  controlPanelBackground: {
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0.50)',
    color: 'white',
    padding: '0 5px',
    left: 0,
    right: 0,
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  topControlPanel: {
    top: 0,
  },
  bottomControlPanel: {
    bottom: 0,
    justifyContent: 'flex-end',
  },
  pointerCursor: {
    cursor: 'pointer',
  },
  container: {
    position: 'relative',
    width: '100%',
  },
}));

const ControlsPanel = ({
  url, label,
  isFullScreen, onFullScreen,
}) => {
  const classes = useStyles();

  const [isMouseEnter, setIsMouseEnter] = useState(false);

  const isAvailableVideo = isMouseEnter;

  const handleMouseEnter = () => setIsMouseEnter(true);
  const handleMouseLeave = () => setIsMouseEnter(false);

  return (
    <div
      className={classes.container}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {isAvailableVideo && (
        <div className={`${classes.controlPanelBackground} ${classes.topControlPanel}`}>{label}</div>
      )}

      {isAvailableVideo && (
        <div className={`${classes.controlPanelBackground} ${classes.bottomControlPanel}`}>
          {isFullScreen ? (
            <FullscreenExit className={classes.pointerCursor} onClick={onFullScreen} />
          ) : (
            <Fullscreen className={classes.pointerCursor} onClick={onFullScreen} />
          )}
        </div>
      )}

      <VideoJsMPEGController url={url} />
    </div>
  );
};

export default ControlsPanel;
