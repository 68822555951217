import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ListItem,
  List,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import icons from '../../../common/static/icons';
import { toTitleCase } from '../../../common/utils/formatter';

export default function ChangeIconDialog({
  openChangeDialog,
  setOpenChangeDialog,
  setIcons,
  handleIconUpdate,
}) {
  const t = useTranslation();
  const [expanded, setExpanded] = useState([]);

  const handleCloseChangeDialog = () => {
    setOpenChangeDialog(false);
  };

  const handleAccordionToggle = (category) => {
    setExpanded((prevExpanded) => {
      if (prevExpanded.includes(category)) {
        return prevExpanded.filter((item) => item !== category);
      }
      return [...prevExpanded, category];
    });
  };

  const handleChangeIcon = (category, icon) => {
    setIcons((prevState) => ({
      ...prevState,
      [openChangeDialog]: {
        ...prevState[openChangeDialog],
        icon: `${category}_${icon}`,
      },
    }));
    handleIconUpdate(openChangeDialog, `${category}_${icon}`);
    setOpenChangeDialog(false);
  };

  return (
    <Dialog open={!!openChangeDialog} onClose={handleCloseChangeDialog} fullWidth maxWidth="xs">
      <DialogTitle>
        {t('sharedChange')}
        <IconButton
          aria-label="close"
          onClick={handleCloseChangeDialog}
          style={{ position: 'absolute', top: '10px', right: '10px' }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>

        <>
          {Object.keys(icons).map((category) => (
            <Accordion
              key={category}
              expanded={expanded.includes(category)}
              onChange={() => handleAccordionToggle(category)}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography component="span">{t(`iconsCategory${toTitleCase(category)}`)}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {Object.keys(icons[category]).map((icon) => (
                    <ListItem
                      key={icon}
                      onClick={() => handleChangeIcon(category, icon)}
                      style={{
                        cursor: 'pointer',
                        padding: 8,
                        margin: 4,
                        borderRadius: 4,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: 52,
                        height: 52,
                      }}
                      title={`${category}_${icon}`}
                    >
                      <img
                        src={`/images/icon/device/${category}_${icon}.svg`}
                        alt={`${category}_${icon}`}
                        style={{ width: '100%', height: '100%' }}
                      />
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          ))}
        </>

      </DialogContent>
    </Dialog>
  );
}
